import TypedCardElementPeer from './TypedCardElementPeer';
import {
    Column,
    SizeUnit,
    SizeAndUnit,
    ActionSet,
    ColumnSet
} from 'adaptivecards';
import { Versions } from '../Shared';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import ContainerPeer from './ContainerPeer';
import CardDesignerSurface from '../CardDesignerSurface';
import DesignerPeer from './DesignerPeer';
import SubPropertySheetEntry from '../PropertyEditor/SubPropertySheetEntry';
import AdaptiveCardPeer from './AdaptiveCardPeer';
import ActionPeer from './ActionPeer';
import { findElementTypeRegistration } from '../../inventory/InventoryUtil';
import ColumnWidthPropertyEditor from '../PropertyEditor/ColumnWidthPropertyEditor';
import SizeAndUnitPropertyEditor from '../PropertyEditor/SizeAndUnitPropertyEditor';
import CardElementPeer from './CardElementPeer';

export default class ColumnPeer extends TypedCardElementPeer<Column> {
    private static readonly pixelWidthProperty = new SizeAndUnitPropertyEditor(
        Versions.v1_1,
        'width',
        'Width in pixels',
        SizeUnit.Pixel,
        'Define the width for this column in pixels here. Please note that this may cause the layout to break when it scales from large screens to mobile screens. '
    );

    private static readonly weightProperty = new SizeAndUnitPropertyEditor(
        Versions.v1_0,
        'width',
        'Width in Ratio',
        SizeUnit.Weight,
        'Define the width for this column in a ratio here. For example, If you want this column to be half width of the other column, specify its width as 1 and the width of the second column as 2. '
    );

    static readonly widthProperty = new ColumnWidthPropertyEditor(
        Versions.v1_0,
        'width',
        'Width',
        [
            { targetVersion: Versions.v1_0, name: 'Automatic', value: 'auto' },
            { targetVersion: Versions.v1_0, name: 'Stretch', value: 'stretch' },
            {
                targetVersion: Versions.v1_0,
                name: 'Weighted',
                value: 'weighted'
            },
            { targetVersion: Versions.v1_1, name: 'Pixels', value: 'pixels' }
        ],
        'Define the width for the column either as fit (auto or stretch) or in a ratio between the columns. ',
        true
    );
    protected isContainer(): boolean {
        return true;
    }

    protected internalGetTreeItemText(): string {
        if (this.cardElement.width instanceof SizeAndUnit) {
            switch (this.cardElement.width.unit) {
                case SizeUnit.Weight:
                    return 'Weight: ' + this.cardElement.width.physicalSize;
                default:
                    return this.cardElement.width.physicalSize + ' pixels';
            }
        } else {
            switch (this.cardElement.width) {
                case 'stretch':
                    return 'Stretch';
                case 'auto':
                    return 'Automatic';
                default:
                    return '';
            }
        }
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            CardElementPeer.idProperty,
            CardElementPeer.isVisibleProperty,
            ContainerPeer.styleProperty,
            ColumnPeer.widthProperty
        );

        if (this.cardElement.width instanceof SizeAndUnit) {
            if (this.cardElement.width.unit === SizeUnit.Pixel) {
                propertySheet.add(
                    PropertySheetCategory.DefaultCategory,
                    ColumnPeer.pixelWidthProperty
                );
            } else {
                propertySheet.add(
                    PropertySheetCategory.DefaultCategory,
                    ColumnPeer.weightProperty
                );
            }
        }

        propertySheet.add(
            defaultCategory,
            AdaptiveCardPeer.paddingProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty,
            CardElementPeer.horizontalAlignmentProperty,
            ContainerPeer.verticalContentAlignmentProperty,
            ContainerPeer.backgroundImageProperty,
            ContainerPeer.selectActionProperty
        );

        if (this.cardElement.selectAction) {
            let selectActionPeer = CardDesignerSurface.actionPeerRegistry.createPeerInstance(
                this.designerSurface,
                null,
                this.cardElement.selectAction
            );
            selectActionPeer.onChanged = (
                sender: DesignerPeer,
                updatePropertySheet: boolean
            ) => {
                this.changed(updatePropertySheet);
            };

            let subPropertySheet = new PropertySheet();
            selectActionPeer.populatePropertySheet(
                subPropertySheet,
                PropertySheetCategory.SelectionAction
            );

            propertySheet.add(
                PropertySheetCategory.SelectionAction,
                new SubPropertySheetEntry(
                    Versions.v1_2,
                    this.cardElement.selectAction,
                    subPropertySheet
                )
            );
        }
    }

    getPeerToInsert(peer: DesignerPeer): DesignerPeer {
        let insertPeer: DesignerPeer = peer;
        if (peer instanceof ActionPeer) {
            const typeRegistration = findElementTypeRegistration('ActionSet');
            const actionSet: ActionSet = typeRegistration.createInstance() as ActionSet;
            actionSet.addAction(peer.action);
            insertPeer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                actionSet
            );
        } else if (peer instanceof ColumnPeer) {
            const typeRegistration = findElementTypeRegistration('ColumnSet');
            const columnSet: ColumnSet = typeRegistration.createInstance() as ColumnSet;
            columnSet.addColumn(peer.cardElement);
            insertPeer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                columnSet
            );
        }
        return insertPeer;
    }

    insertItemAfter(peer: DesignerPeer, refPeer: DesignerPeer) {
        const item = peer.getCardObject() as any;
        const refItem = refPeer ? (refPeer.getCardObject() as any) : null;
        this.cardElement.insertItemAfter(item, refItem);
    }

    insertItemBefore(peer: DesignerPeer, refPeer: DesignerPeer) {
        const item = peer.getCardObject() as any;
        const refItem = refPeer ? (refPeer.getCardObject() as any) : null;
        this.cardElement.insertItemBefore(item, refItem);
    }
}
