import PropertySheetEntry from './PropertySheetEntry';
import {
    keyValuePair,
    SubPadding,
    Padding
} from '../DesignerPeers/DesignerPeersUtils';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';
import { TargetVersion } from '../Shared';
import { PaddingDefinition, Spacing } from 'adaptivecards';

export default class PaddingPropertyEditor extends PropertySheetEntry {
    private generateKeyValuePairs(enumType: {
        [s: number]: string;
    }): keyValuePair {
        let entries: keyValuePair = {};
        for (let key in enumType) {
            let v = parseInt(key, 10);

            if (!isNaN(v)) {
                entries[enumType[key]] = key;
            }
        }
        return entries;
    }

    private generateSubPadding(
        context: PropertySheetContext,
        topPaddingChange,
        bottomPaddingChange,
        leftPaddingChange,
        rightPaddingChange
    ): HTMLElement {
        let container: HTMLElement = document.createElement('div');

        let defaultPadding: PaddingDefinition =
            context.target[this.propertyName];

        let topPaddingLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
            'Top'
        );
        let topPaddingInput: HTMLElement = new GeneratePropertyFields().generateDropDown(
            this.generateKeyValuePairs(SubPadding),
            topPaddingChange,
            defaultPadding.top.toString(),
            'Top padding'
        );
        container.appendChild(topPaddingLabel);
        container.appendChild(topPaddingInput);

        let bottomPaddingLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
            'Bottom'
        );
        let bottomPaddingInput: HTMLElement = new GeneratePropertyFields().generateDropDown(
            this.generateKeyValuePairs(SubPadding),
            bottomPaddingChange,
            defaultPadding.bottom.toString(),
            'Bottom padding'
        );
        container.appendChild(bottomPaddingLabel);
        container.appendChild(bottomPaddingInput);

        let leftPaddingLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
            'Left'
        );
        let leftPaddingInput: HTMLElement = new GeneratePropertyFields().generateDropDown(
            this.generateKeyValuePairs(SubPadding),
            leftPaddingChange,
            defaultPadding.left.toString(),
            'Left Padding'
        );
        container.appendChild(leftPaddingLabel);
        container.appendChild(leftPaddingInput);

        let rightPaddingLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
            'Right'
        );
        let rightPaddingInput: HTMLElement = new GeneratePropertyFields().generateDropDown(
            this.generateKeyValuePairs(SubPadding),
            rightPaddingChange,
            defaultPadding.right.toString(),
            'Right padding'
        );
        container.appendChild(rightPaddingLabel);
        container.appendChild(rightPaddingInput);

        return container;
    }

    private createInput(
        context: PropertySheetContext,
        onChangeCallback
    ): HTMLElement {
        let entries: keyValuePair = {};
        for (let key in this.enumType) {
            let v = parseInt(key, 10);

            if (!isNaN(v)) {
                entries[this.enumType[key]] = key;
            }
        }

        let defaultValue = '0';
        if (context.target[this.propertyName] !== null) {
            let topPadding = context.target[this.propertyName].top;
            let bottomPadding = context.target[this.propertyName].bottom;
            let leftPadding = context.target[this.propertyName].left;
            let rightPadding = context.target[this.propertyName].right;
            if (
                topPadding === bottomPadding &&
                topPadding === leftPadding &&
                topPadding === rightPadding
            ) {
                defaultValue = topPadding.toString();
            } else {
                defaultValue = Padding.Custom.toString();
            }
        } else {
            context.target[this.propertyName] = new PaddingDefinition(
                Spacing.None,
                Spacing.None,
                Spacing.None,
                Spacing.None
            );
        }
        let input: HTMLElement = new GeneratePropertyFields().generateDropDown(
            entries,
            onChangeCallback,
            defaultValue,
            'Padding'
        );
        return input;
    }

    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');
        let subContainerPadding: HTMLElement = document.createElement('div');
        subContainerPadding.style.paddingLeft = '16px';

        const topPaddingChange = (value: string) => {
            context.target[this.propertyName].top = parseInt(value, 10);
            context.peer.changed(this.causesPropertySheetRefresh);
        };
        const bottomPaddingChange = (value: string) => {
            context.target[this.propertyName].bottom = parseInt(value, 10);
            context.peer.changed(this.causesPropertySheetRefresh);
        };
        const leftPaddingChange = (value: string) => {
            context.target[this.propertyName].left = parseInt(value, 10);
            context.peer.changed(this.causesPropertySheetRefresh);
        };
        const rightPaddingChange = (value: string) => {
            context.target[this.propertyName].right = parseInt(value, 10);
            context.peer.changed(this.causesPropertySheetRefresh);
        };

        let labelDiv: HTMLElement = document.createElement('div');
        labelDiv.style.display = 'flex';
        let label: HTMLElement = new GeneratePropertyFields().generateLabels(
            this.label
        );
        let tooltipElement: HTMLElement = new GeneratePropertyFields().generateTooltips(
            this.tooltipContent
        );
        tooltipElement.style.cssFloat = 'right';

        const onChangeCallback = (value: string) => {
            if (value === Padding.Custom.toString()) {
                // for Custom Padding
                subContainerPadding.classList.remove('acd-hidden');
                subContainerPadding.appendChild(
                    this.generateSubPadding(
                        context,
                        topPaddingChange,
                        bottomPaddingChange,
                        leftPaddingChange,
                        rightPaddingChange
                    )
                );
                container.appendChild(subContainerPadding);
            } else {
                subContainerPadding.classList.add('acd-hidden');
                let paddingValues = parseInt(value, 10);
                context.target[this.propertyName] = new PaddingDefinition(
                    paddingValues,
                    paddingValues,
                    paddingValues,
                    paddingValues
                );
                context.peer.changed(this.causesPropertySheetRefresh);
                container.appendChild(subContainerPadding);
            }
        };

        let input = this.createInput(context, onChangeCallback);
        label.style.width = '100%';
        labelDiv.appendChild(label);
        labelDiv.appendChild(tooltipElement);
        container.classList.add('property');
        container.appendChild(labelDiv);
        container.appendChild(input);

        let topPadding = context.target[this.propertyName].top;
        let bottomPadding = context.target[this.propertyName].bottom;
        let leftPadding = context.target[this.propertyName].left;
        let rightPadding = context.target[this.propertyName].right;
        if (
            topPadding === bottomPadding &&
            topPadding === leftPadding &&
            topPadding === rightPadding
        ) {
            subContainerPadding.classList.add('acd-hidden');
            container.appendChild(subContainerPadding);
        } else {
            subContainerPadding.appendChild(
                this.generateSubPadding(
                    context,
                    topPaddingChange,
                    bottomPaddingChange,
                    leftPaddingChange,
                    rightPaddingChange
                )
            );
            subContainerPadding.classList.remove('acd-hidden');
            container.appendChild(subContainerPadding);
        }

        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly enumType: { [s: number]: string },
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(targetVersion);
    }
}
