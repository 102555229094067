import { Action } from 'adaptivecards';
import ActionPeer from './ActionPeer';

export default abstract class TypedActionPeer<
    TAction extends Action
> extends ActionPeer {
    get action(): TAction {
        return this._action as TAction;
    }
}
