import { CardElement } from 'adaptivecards';
import DesignerPeerInplaceEditor from './DesignerPeerInplaceEditor';

export default abstract class CardElementPeerInplaceEditor<
    TCardElement extends CardElement
> extends DesignerPeerInplaceEditor {
    readonly cardElement: TCardElement;

    constructor(cardElement: TCardElement) {
        super();

        this.cardElement = cardElement;
    }
}
