import TypedCardElementPeer from './TypedCardElementPeer';
import { TextBlock, TextSize, TextWeight, TextColor } from 'adaptivecards';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';
import EnumPropertyEditor from '../PropertyEditor/EnumPropertyEditor';
import DesignerPeerInplaceEditor from './DesignerPeerInplaceEditor';
import TextBlockPeerInplaceEditor from './TextBlockPeerInplaceEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CardElementPeer from './CardElementPeer';

export default class TextBlockPeer extends TypedCardElementPeer<TextBlock> {
    static readonly textProperty = new StringPropertyEditor(
        Versions.v1_0,
        'text',
        'Text Content',
        'Insert your text here. ',
        true
    );
    static readonly wrapProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'wrap',
        'Wrap Text',
        "Turning on this property will wrap the text to the next line when there isn't enough space. If turned off, your text might get truncated. "
    );
    static readonly sizeProperty = new EnumPropertyEditor(
        Versions.v1_0,
        'size',
        'Font Size',
        TextSize,
        'Choose the font size for your Text Block here.'
    );
    static readonly weightProperty = new EnumPropertyEditor(
        Versions.v1_0,
        'weight',
        'Font Weight',
        TextWeight,
        'Choose the font weight for your Text Block here.'
    );
    static readonly colorProperty = new EnumPropertyEditor(
        Versions.v1_0,
        'color',
        'Font Color',
        TextColor,
        'Choose the font colour for your Text Block here.'
    );
    static readonly subtleProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'isSubtle',
        'Subtle',
        'Turning on this property will make the text look subtle/lighter than default text.',
        false
    );
    protected createInplaceEditor(): DesignerPeerInplaceEditor {
        return new TextBlockPeerInplaceEditor(this.cardElement);
    }

    protected internalGetTreeItemText(): string {
        return this.cardElement.text;
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            CardElementPeer.idProperty,
            TextBlockPeer.textProperty,
            CardElementPeer.spacingProperty,
            TextBlockPeer.wrapProperty,
            TextBlockPeer.sizeProperty,
            TextBlockPeer.weightProperty,
            TextBlockPeer.colorProperty,
            TextBlockPeer.subtleProperty,
            CardElementPeer.horizontalAlignmentProperty
        );
    }

    getToolTip(): string {
        return 'Double click to edit';
    }

    initializeCardElement() {
        this.cardElement.wrap = true;
        if (!this.cardElement.text) {
            this.cardElement.text =
                'This is a text block. Insert your text here.';
        }
    }
}
