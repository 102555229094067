import CardObjectPeer from './CardObjectPeer';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import DesignerPeer from './DesignerPeer';
import { Versions } from '../Shared';
import { Action, CardObject } from 'adaptivecards';
import { Rect } from '../Miscellaneous';
import CardDesignerSurface from '../CardDesignerSurface';
import DesignerPeerRegistrationBase from '../DesignerPeerRegistrationBase';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';

export default class ActionPeer extends CardObjectPeer {
    static readonly titleProperty = new StringPropertyEditor(
        Versions.v1_0,
        'title',
        'Text String',
        'Insert the text string for the action button here. Try to use short and precise verbs such as "Approve" or "Dismiss" rather than words like "OK" or "Maybe".'
    );

    static readonly isPrimaryProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'isPrimary',
        'Primary Button',
        'Turning this property on makes this action button a primary button. Use this property for only one action in a card, preferably the CTA button.'
    );

    protected _action: Action;

    protected click(e: MouseEvent) {
        super.click(e);

        this.action.renderedElement.click();
    }

    protected internalRemove(): boolean {
        return this.action.remove();
    }

    constructor(
        parent: DesignerPeer,
        designerSurface: CardDesignerSurface,
        registration: DesignerPeerRegistrationBase,
        action: Action
    ) {
        super(parent, designerSurface, registration);

        this._action = action;
    }

    protected internalGetTreeItemText(): string {
        if (this.action.title && this.action.title !== '') {
            return this.action.title;
        } else {
            return super.internalGetTreeItemText();
        }
    }

    renderDragVisual(): HTMLElement {
        const element = this._action.renderedElement.cloneNode(
            true
        ) as HTMLElement;
        const box = this._action.renderedElement.getBoundingClientRect();
        element.style.width = box.width + 'px';
        element.style.height = box.height + 'px';
        const visual = document.createElement('div');
        const overlay = document.createElement('div');
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.position = 'absolute';
        visual.appendChild(overlay);
        visual.appendChild(element);
        return visual;
    }

    getCardObject(): CardObject {
        return this.action;
    }

    getBoundingRect(): Rect {
        let designSurfaceOffset = this.designerSurface.getDesignerSurfaceOffset();
        let actionBoundingRect = this.action.renderedElement.getBoundingClientRect();

        return new Rect(
            actionBoundingRect.top - designSurfaceOffset.y,
            actionBoundingRect.right - designSurfaceOffset.x,
            actionBoundingRect.bottom - designSurfaceOffset.y,
            actionBoundingRect.left - designSurfaceOffset.x
        );
    }

    getCardObjectBoundingRect(): Rect {
        let actionBoundingRect = this.action.renderedElement.getBoundingClientRect();

        return new Rect(
            actionBoundingRect.top,
            actionBoundingRect.right,
            actionBoundingRect.bottom,
            actionBoundingRect.left
        );
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        super.populatePropertySheet(propertySheet, defaultCategory);

        propertySheet.add(
            defaultCategory,
            ActionPeer.idProperty,
            ActionPeer.titleProperty,
            ActionPeer.isPrimaryProperty
        );
    }

    getItemIndex(): number {
        return this.parent.getChildIndex(this);
    }

    get action(): Action {
        return this._action;
    }
}
