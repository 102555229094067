import TypedActionPeer from './TypedActionPeer';
import { ToggleVisibilityAction } from 'adaptivecards';
import ArrayPropertyEditor from '../PropertyEditor/ArrayPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import DescriptionPropertyEditor from '../PropertyEditor/DescriptionPropertyEditor';

export default class ToggleVisibilityActionPeer extends TypedActionPeer<
    ToggleVisibilityAction
> {
    static readonly targetElementsProperty = new ArrayPropertyEditor(
        '*',
        'targetElements',
        'Insert the ID of elements whose visibility will be toggled with this button.',
        'Choose whether this action will display / hide / simply toggle the visibility of the target element when clicked.',
        false
    );
    static readonly description: string =
        'This Action toggles the visibility of certain other elements in the card. For example "View More" can be used to reveal more content which may have initially hidden to the length of the paragraph. You can assign IDs of any target elements in the card to toggle their visibility with this button. ';
    static readonly descriptionProperty = new DescriptionPropertyEditor(
        '*',
        ToggleVisibilityActionPeer.description
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            PropertySheetCategory.DefaultCategory,
            ToggleVisibilityActionPeer.descriptionProperty
        );
        super.populatePropertySheet(propertySheet, defaultCategory);

        propertySheet.add(
            defaultCategory,
            ToggleVisibilityActionPeer.targetElementsProperty
        );
    }
}
