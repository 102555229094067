import PropertySheetEntry from './PropertySheetEntry';
import { TargetVersion } from '../Shared';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';

export default class ListImagePropertyEditor extends PropertySheetEntry {
    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');

        let separator: HTMLElement = document.createElement('div');
        separator.classList.add('separatorPropertyEditor');
        container.appendChild(separator);

        let collection = context.target[this.propertyName];
        if (collection) {
            for (let i = 0; i < collection.length; i++) {
                let urlDiv: HTMLElement = document.createElement('div');
                urlDiv.classList.add('titleDivNameValuePairPropertyEditor');

                let removeLabel: HTMLElement = document.createElement('label');
                let removeButton: HTMLElement = document.createElement(
                    'button'
                );

                removeButton.setAttribute('aria-label', 'Remove');
                removeLabel.classList.add(
                    'iconRemoveNameValuePairPropertyEditor'
                );
                removeButton.onclick = sender => {
                    let changedPeer = context.peer.getChildAt(i);
                    context.peer.designerSurface.setSelectedPeer(changedPeer);
                    context.peer.designerSurface.removeSelected();
                    context.peer.changed(true);
                };
                let removeSpan: HTMLElement = document.createElement('span');
                removeSpan.classList.add('acd-icon');
                removeSpan.classList.add('garbageIconPropertyEditor');
                removeLabel.appendChild(removeButton);
                removeLabel.appendChild(removeSpan);

                const onURLChangeCallback = (value: string) => {
                    context.target[this.propertyName]['url'] = value;
                    collection[i].url = value;
                    let changedPeer = context.peer.getChildAt(i);
                    changedPeer.getCardObject()['url'] = value;
                    changedPeer.changed(false);
                    context.peer.changed(false);
                };
                let defaultURL = collection[i].url || '';
                let urlLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
                    this.urlLabel
                );
                let tooltipURL: HTMLElement = new GeneratePropertyFields().generateTooltips(
                    this.urlTooltipContent
                );
                let urlTooltipDiv: HTMLElement = document.createElement('div');
                urlTooltipDiv.appendChild(urlLabel);
                urlTooltipDiv.appendChild(tooltipURL);
                urlTooltipDiv.classList.add('labelNameValuePairPropertyEditor');

                let urlField: HTMLElement = new GeneratePropertyFields().generateTextFields(
                    false,
                    this.urlLabel,
                    defaultURL,
                    onURLChangeCallback
                );
                let urlContainer: HTMLElement = document.createElement('div');
                urlContainer.classList.add('property');
                urlDiv.appendChild(urlTooltipDiv);
                urlDiv.appendChild(removeLabel);
                urlContainer.appendChild(urlDiv);
                urlContainer.appendChild(urlField);

                const onAltTextChangeCallback = (value: string) => {
                    context.target[this.propertyName]['altText'] = value;
                    collection[i].altText = value;
                    let changedPeer = context.peer.getChildAt(i);
                    changedPeer.getCardObject()['altText'] = value;
                    changedPeer.changed(false);
                    context.peer.changed(false);
                };
                let defaultAltText = collection[i].altText || '';
                let altTextLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
                    this.altTextLabel
                );
                let tooltipAltText: HTMLElement = new GeneratePropertyFields().generateTooltips(
                    this.altTextTooltipContent
                );
                let altTextTooltipDiv: HTMLElement = document.createElement(
                    'div'
                );
                altTextTooltipDiv.appendChild(altTextLabel);
                altTextTooltipDiv.appendChild(tooltipAltText);
                altTextTooltipDiv.classList.add(
                    'labelNameValuePairPropertyEditor'
                );
                let altTextField: HTMLElement = new GeneratePropertyFields().generateTextFields(
                    false,
                    this.altTextLabel,
                    defaultAltText,
                    onAltTextChangeCallback
                );
                let altTextContainer: HTMLElement = document.createElement(
                    'div'
                );
                altTextContainer.classList.add('property');
                altTextContainer.appendChild(altTextTooltipDiv);
                altTextContainer.appendChild(altTextField);

                container.appendChild(urlContainer);
                container.appendChild(altTextContainer);
                let separator: HTMLElement = document.createElement('div');
                separator.classList.add('separatorPropertyEditor');
                container.appendChild(separator);
            }
        }

        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly name: string,
        readonly value: string,
        readonly urlLabel: string,
        readonly altTextLabel: string,
        readonly urlTooltipContent: string,
        readonly altTextTooltipContent: string
    ) {
        super(targetVersion);
    }
}
