import { observable, computed } from 'mobx';

import CardDesigner from '../ACDesigner/CardDesigner';

export enum VIEW {
    DESIGN = 0,
    PREVIEW = 1,
    LOAD_SAMPLE = 2,
    ACv2_DESIGN = 3,
}

export class AppStore {
    private static storeInstance: AppStore;

    @observable private _currentView: VIEW = VIEW.LOAD_SAMPLE;

    private _previousView: VIEW = null;

    @observable public designer: CardDesigner | undefined;

    public currentCard: string = null;

    public loadCard: boolean = false;

    private constructor() {}

    public static getStoreInstance(): AppStore {
        if (!AppStore.storeInstance) {
            AppStore.storeInstance = new AppStore();
        }
        return AppStore.storeInstance;
    }

    public getCardJSON() {
        if (this.designer === undefined) {
            return null;
        }
        return this.designer.card.toJSON();
    }

    public getCardJsonACv2() {
        if (this.designer === undefined) {
            return null;
        }

        return JSON.parse(this.designer.getCurrentCardEditorPayload());
    }

    @computed
    get currentView(): VIEW {
        return this._currentView;
    }

    set currentView(newView: VIEW) {
        this._previousView = this._currentView;
        this._currentView = newView;
    }

    get previousView(): VIEW {
        return this._previousView;
    }

    @computed
    get isACv2DesignView(): boolean {
        return this.currentView === VIEW.ACv2_DESIGN;
    }
}
