import DesignerPeer from './DesignerPeers/DesignerPeer';
import CardDesignerSurface from './CardDesignerSurface';
import DesignerPeerRegistrationBase from './DesignerPeerRegistrationBase';
import {
    Action,
    HttpAction,
    OpenUrlAction,
    ShowCardAction,
    ToggleVisibilityAction
} from 'adaptivecards';
import ActionPeer from './DesignerPeers/ActionPeer';
import DesignerPeerRegistry from './DesignerPeerRegistry';
import HttpActionPeer from './DesignerPeers/HttpActionPeer';
import DesignerPeerCategory from './DesignerPeerCategory';
import OpenUrlActionPeer from './DesignerPeers/OpenUrlActionPeer';
import ShowCardActionPeer from './DesignerPeers/ShowCardActionPeer';
import ToggleVisibilityActionPeer from './DesignerPeers/ToggleVisibilityActionPeer';

type ActionType = { new (): Action };

type ActionPeerType = {
    new (
        parent: DesignerPeer,
        designerSurface: CardDesignerSurface,
        registration: DesignerPeerRegistrationBase,
        action: Action
    ): ActionPeer;
};

export default class ActionPeerRegistry extends DesignerPeerRegistry<
    ActionType,
    ActionPeerType
> {
    reset() {
        this.clear();

        this.registerPeer(
            HttpAction,
            HttpActionPeer as any,
            DesignerPeerCategory.Actions,
            'acd-icon-actionHttp'
        );
        this.registerPeer(
            OpenUrlAction,
            OpenUrlActionPeer as any,
            DesignerPeerCategory.Actions,
            'acd-icon-actionOpenUrl'
        );
        this.registerPeer(
            ShowCardAction,
            ShowCardActionPeer as any,
            DesignerPeerCategory.Actions,
            'acd-icon-actionShowCard'
        );
        this.registerPeer(
            ToggleVisibilityAction,
            ToggleVisibilityActionPeer as any,
            DesignerPeerCategory.Actions,
            'acd-icon-actionToggleVisibility'
        );
    }

    createPeerInstance(
        designerSurface: CardDesignerSurface,
        parent: DesignerPeer,
        action: Action
    ): ActionPeer {
        var registrationInfo = this.findTypeRegistration(
            (action as any).constructor
        );

        var peer = registrationInfo
            ? new registrationInfo.peerType(
                  parent,
                  designerSurface,
                  registrationInfo,
                  action
              )
            : new ActionPeer(
                  parent,
                  designerSurface,
                  this.defaultRegistration,
                  action
              );

        return peer;
    }
}
