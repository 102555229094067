import React from 'react';
import { inject } from 'mobx-react';

import { ToolsMap, ToolCategory } from './DesignerToolsUtils';
import { DesignerToolsDropdown } from './DesignerToolsDropdown';
import { AppStore } from '../../stores/AppStore';

import './DesignerTools.css';
import { keyBoardToClickEventMapper } from '../../utilities/Utils';
import { AppStoreProps } from '../../stores/StoreProps';

type ToolsState = {
    selectedCategory: ToolCategory;
};

@inject('appStore')
export class DesignerTools extends React.Component<AppStoreProps, ToolsState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: null,
        };
    }

    public componentDidMount() {
        window.addEventListener('click', this.handleClickOnWindow);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOnWindow);
    }

    private updateSelectedCategory = (targetCategory: ToolCategory) => {
        this.setState({
            selectedCategory:
                this.state.selectedCategory === targetCategory
                    ? null
                    : targetCategory,
        });
    };

    private handleClickOnWindow = (event: MouseEvent) => {
        if (
            (event.target as Element).closest(
                '#designerToolsDropdownContent',
            ) === null &&
            (event.target as Element).closest('#designerToolsCategories') ===
                null
        ) {
            this.setState({
                selectedCategory: null,
            });
        }
    };

    public render() {
        const items = [];
        let toolsCount = 0;
        for (const i in ToolsMap) {
            ++toolsCount;
            if (toolsCount !== 1) {
                items.push(<ToolSpitter key={i + 'Splitter'} />);
            }
            items.push(
                <DesignerToolsCategory
                    key={i}
                    type={i}
                    isSelected={i === this.state.selectedCategory}
                    clickDownCallback={this.updateSelectedCategory}
                    appStore={this.props.appStore}
                />,
            );
        }
        // Toggle JSON Editor
        return (
            <div
                className="designerToolsWrapper"
                id="designerToolsWrapper"
                role="menu"
                style={{
                    pointerEvents: this.props.appStore.isACv2DesignView
                        ? 'none'
                        : 'inherit',
                    opacity: this.props.appStore.isACv2DesignView ? 0.7 : null,
                }}
            >
                <div
                    id="designerToolsCategories"
                    className="designerToolsCategories"
                >
                    {items}
                </div>
                {this.state.selectedCategory && (
                    <DesignerToolsDropdown
                        selectedCategory={this.state.selectedCategory}
                        stores={{ appStore: this.props.appStore }}
                    />
                )}
            </div>
        );
    }
}

type CategoryProps = {
    type: string;
    isSelected: boolean;
    clickDownCallback: (category: ToolCategory) => void;
    appStore: AppStore;
};

class DesignerToolsCategory extends React.Component<CategoryProps> {
    private handleClick = (event) => {
        this.props.clickDownCallback(event.currentTarget.id);
    };

    public render() {
        let className = 'toolsCategory';
        if (this.props.isSelected) {
            className += ' selected';
        }
        return (
            <div
                id={this.props.type}
                className={className}
                onClick={this.handleClick}
                onKeyDown={keyBoardToClickEventMapper(this.handleClick)}
                tabIndex={this.props.appStore.isACv2DesignView ? -1 : 0}
            >
                <span
                    className={'acd-icon ' + ToolsMap[this.props.type].iconName}
                ></span>
                <div style={{ color: '#323130' }}>
                    {ToolsMap[this.props.type].name}
                </div>
            </div>
        );
    }
}

function ToolSpitter() {
    return <span className="toolSplitter"></span>;
}
