import React from 'react';
import './FeedbackForm.css';
import { observer } from 'mobx-react';
import { GRAPH_URL } from '../utilities/constants';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { NotificationStore } from '../stores/NotificationStore';
import { MessageBarType } from '@fluentui/react';
import { AWTLogManager, AWTEventPriority } from '@aria/webjs-sdk';
import { FEEDBACK } from '../utilities/LoggingEventNames';
import { keyBoardToClickEventMapper } from '../utilities/Utils';
import { AuthStoreProps } from '../stores/StoreProps';

interface CategoryProps {
    setCategory(item: string): void;
}

export class Category extends React.Component<CategoryProps, {}> {
    public render() {
        return (
            <div className="inputGroup">
                <div className="label">Categorize your feedback*</div>
                <Dropdown
                    onChange={this.onChange}
                    placeholder="Select an option"
                    className="dropdownFeedback"
                    id="dropdownFeedbackId"
                    options={[
                        { key: 'Bug Report', text: 'Bug Report' },
                        { key: 'Feature Request', text: 'Feature Request' },
                        { key: 'Compliment', text: 'Compliment' },
                    ]}
                    styles={{ dropdown: { width: 370, height: 28 } }}
                    ariaLabel="Categorize your feedback"
                    aria-required="true"
                    tabIndex={0}
                />
            </div>
        );
    }

    private onChange = (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption,
    ): void => {
        this.props.setCategory(item.text);
    };
}

interface RatingProps {
    setRating(rating: number): void;
}
export class Rating extends React.Component<RatingProps, {}> {
    private rating: number = 0;
    private changeRating(rating) {
        this.rating = rating;
        this.props.setRating(this.rating);
    }

    public render() {
        let stars = [];
        for (let index = 1; index <= 5; index++) {
            if (index <= this.rating)
                stars.push(
                    <div
                        className="innerStars"
                        onClick={this.changeRating.bind(this, index)}
                        key={index.toString()}
                        tabIndex={0}
                        onKeyDown={keyBoardToClickEventMapper(
                            this.changeRating.bind(this, index),
                        )}
                        aria-label={`How would you rate your experience with this new tool?`}
                        aria-required="true"
                        title={`Rating ${index}`}
                    >
                        <span className="amd-icon amdIconCheckedStar"></span>
                    </div>,
                );
            else
                stars.push(
                    <div
                        className="innerStars"
                        onClick={this.changeRating.bind(this, index)}
                        key={index.toString()}
                        tabIndex={0}
                        onKeyDown={keyBoardToClickEventMapper(
                            this.changeRating.bind(this, index),
                        )}
                        aria-label={`How would you rate your experience with this new tool?`}
                        aria-required="true"
                        title={`Rating ${index}`}
                    >
                        <span className="amd-icon amdIconUncheckedStar"></span>
                    </div>,
                );
        }
        return (
            <div>
                <div className="label">
                    How would you rate your experience with this new tool?*
                </div>
                <div className="outerStars">{stars}</div>
            </div>
        );
    }
}

interface DetailedFeedbackProps {
    setComments(comments: string): void;
}

export class DetailedFeedback extends React.Component<
    DetailedFeedbackProps,
    {}
> {
    updateComments(evt) {
        this.props.setComments(evt.target.value);
    }

    public render() {
        return (
            <div className="inputGroup">
                <div className="label" aria-label="Detailed Feedback">
                    Tell us what you think*
                </div>
                <textarea
                    className="comment"
                    id="commentId"
                    onChange={(evt) => {
                        this.updateComments(evt);
                    }}
                    aria-label="Tell us what you think"
                    aria-required="true"
                />
            </div>
        );
    }
}

interface UploadImageProps {
    setFile?(fileContent: string, fileName: string): void;
}

export class UploadImage extends React.Component<UploadImageProps, {}> {
    encodeImageFileAsURL(element) {
        let filesSelected = element.target.files[0];
        if (filesSelected) {
            let fileSize: number = parseInt(filesSelected.size) / 1024 / 1024;
            if (fileSize > 4) {
                document.querySelector('.fileList').textContent =
                    'Select file less than 4MB';
                document.getElementById('fileList').style.color = '#e4252b';
            } else {
                let fileToLoad = filesSelected;
                let fileReader = new FileReader();
                let srcData = undefined;
                fileReader.onload = () => {
                    srcData = fileReader.result; // <--- data: base64
                    let fileName: string = filesSelected.name.toString();
                    fileName =
                        fileName.length > 18
                            ? fileName.slice(0, 15) + '...'
                            : fileName;
                    document.querySelector('.fileList').textContent = fileName;
                    document.getElementById('fileList').style.color = '#323130';
                    document
                        .getElementById('removeFile')
                        .classList.remove('removeFile');
                    this.props.setFile(srcData.split(',')[1], fileName);
                };
                fileReader.readAsDataURL(fileToLoad);
            }
        }
    }

    removeFile(evt) {
        this.props.setFile(undefined, undefined);
        document.querySelector('.fileList').textContent = '';
        document.getElementById('removeFile').classList.add('removeFile');
        (document.getElementById('fileUpload') as HTMLInputElement).value = '';
    }

    private onUploadLabelClick = (e) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.target.click();
            e.preventDefault();
        }
    };

    public render() {
        return (
            <div className="inputGroup">
                <div className="label">Upload an image</div>
                <div style={{ position: 'relative' }} aria-expanded="true">
                    <input
                        type="file"
                        accept="image/*"
                        id="fileUpload"
                        onInput={(evt) => this.encodeImageFileAsURL(evt)}
                    />
                    <label
                        className="uploadImage"
                        htmlFor="fileUpload"
                        tabIndex={0}
                        onKeyDown={this.onUploadLabelClick}
                        aria-label="Browse"
                        aria-expanded="true"
                        title="Upload an Image"
                        role="button"
                    >
                        Browse
                    </label>
                    <label className="fileList" id="fileList"></label>
                    <i
                        className="removeFile"
                        id="removeFile"
                        tabIndex={0}
                        onClick={(evt) => {
                            this.removeFile(evt);
                        }}
                        onKeyDown={keyBoardToClickEventMapper(
                            this.removeFile.bind(this),
                        )}
                    >
                        <span className="amd-icon amdIconRemove"></span>
                    </i>
                </div>
            </div>
        );
    }
}

type FeedbackFormProps = {
    closeFeedback?(value: boolean): void;
} & AuthStoreProps;

interface FeedbackFormState {
    feedbackUIOpen: boolean;
    category: string;
    comments: string;
    rating: number;
    fileContent: string;
    fileName: string;
}

@observer
export class FeedbackForm extends React.Component<
    FeedbackFormProps,
    FeedbackFormState
> {
    div: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = {
            feedbackUIOpen: true,
            category: '',
            comments: '',
            rating: 0,
            fileContent: undefined,
            fileName: undefined,
        };
    }

    setCategory = (category) => {
        this.setState({ category: category });
    };

    setRating = (rating) => {
        this.setState({ rating: rating });
    };

    setComment = (comments) => {
        this.setState({ comments: comments.trim() });
    };

    setFile = (fileContent, fileName) => {
        this.setState({
            fileContent: fileContent,
            fileName: fileName,
        });
    };

    public async sendEmail() {
        const notificationStore = NotificationStore.getStoreInstance();
        try {
            const accessToken = await this.props.authStore.getAccessToken([
                'Mail.Send',
            ]);

            const mail = {
                Subject: this.state.category + ' : AM Designer Tool Feedback',
                ToRecipients: [
                    {
                        EmailAddress: {
                            Address: 'onboardoam@microsoft.com',
                        },
                    },
                ],
                Body: {
                    Content:
                        'Experience: ' +
                        this.state.rating.toString() +
                        '/5.\n' +
                        this.state.comments +
                        '\n',
                    ContentType: 'text',
                },
                attachments: [],
            };
            const attachment = {
                '@odata.type': '#microsoft.graph.fileAttachment',
                name: this.state.fileName,
                contentType: 'image',
                contentBytes: this.state.fileContent,
            };

            if (this.state.fileContent !== undefined)
                mail.attachments.push(attachment);

            const response = await fetch(`${GRAPH_URL}/me/sendMail`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    Message: mail,
                    SaveToSentItems: false,
                }),
            });
            if (!response.ok) {
                throw response;
            }
            notificationStore.showMessageBar({
                message: 'Your Feedback was sent',
                messageType: MessageBarType.success,
            });
            AWTLogManager.getLogger().logEvent({
                name: FEEDBACK,
                priority: AWTEventPriority.Normal,
                properties: {
                    category: this.state.category,
                    rating: this.state.rating,
                },
            });
        } catch (error) {
            notificationStore.showMessageBar({
                message: 'Unable to send the feedback',
                messageType: MessageBarType.error,
            });
        }
    }

    private handleSubmit() {
        this.sendEmail();
        this.props.closeFeedback(false);
        this.setState({
            feedbackUIOpen: false,
        });
    }

    private handleClose = () => {
        this.props.closeFeedback(false);
        this.setState({
            feedbackUIOpen: false,
        });
    };

    private handleCloseOnEnter = (e) => {
        if (e.key === ' ' || e.key === 'Enter') {
            this.props.closeFeedback(false);
            this.setState({
                feedbackUIOpen: false,
            });
        }
    };

    private handleCloseOnESC = (e) => {
        if (e.key === 'Escape') {
            this.props.closeFeedback(false);
            this.setState({
                feedbackUIOpen: false,
            });
        }
    };

    private sendEmailEnabled(): boolean {
        return (
            this.props.authStore.isLoggedIn &&
            this.state.rating !== 0 &&
            this.state.category !== '' &&
            this.state.comments !== ''
        );
    }

    componentDidMount(): void {
        this.div.focus();
    }

    public render() {
        return (
            <div className="feedbackOverlay">
                <div
                    className="feedbackOverlayFrame"
                    id="feedbackFrameId"
                    ref={(c) => {
                        this.div = c;
                    }}
                    onKeyDown={this.handleCloseOnESC}
                    tabIndex={0}
                >
                    <div>
                        <div
                            className="feedbackTitle"
                            role="heading"
                            aria-level={2}
                        >
                            Feedback
                        </div>
                        <div
                            className="closeFeedback"
                            onPointerDown={this.handleClose}
                            tabIndex={0}
                            onKeyDown={this.handleCloseOnEnter}
                            aria-label="Close"
                            title="Close"
                            role="button"
                        >
                            <span className="amd-icon amdIconClose"></span>
                        </div>
                    </div>

                    <div className="feedbackHelp">
                        When you submit this feedback, an email message will be
                        sent to the Outlook AM team. We may reach out to you for
                        further information.
                    </div>

                    <Category setCategory={this.setCategory} />

                    <DetailedFeedback setComments={this.setComment} />

                    <UploadImage setFile={this.setFile} />

                    <Rating setRating={this.setRating} />

                    <div className="submitDiv">
                        {this.sendEmailEnabled() ? (
                            <div
                                className="submitButton"
                                onClick={() => {
                                    this.handleSubmit();
                                }}
                                tabIndex={0}
                                onKeyDown={keyBoardToClickEventMapper(() =>
                                    this.handleSubmit(),
                                )}
                                role="button"
                            >
                                <div title="Submit Feedback" className="submit">
                                    Submit
                                </div>
                            </div>
                        ) : (
                            <div className="submitButtonDisabled">
                                <div className="submitDisabled">Submit</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
