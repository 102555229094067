import TypedActionPeer from './TypedActionPeer';
import { ShowCardAction } from 'adaptivecards';
import DescriptionPropertyEditor from '../PropertyEditor/DescriptionPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import { addChildCommand } from './DesignerPeersUtils';

export default class ShowCardActionPeer extends TypedActionPeer<
    ShowCardAction
> {
    static readonly description: string =
        'This Action button opens a new section in the card called a "showcard" which in turn can have inserted content. For example this action can be called "Add a comment" and when the user clicks on this action, a showcard opens up allowing users to type in a comment and submit it. Use this action for progressive disclosure/ to reveal contextual controls or data when needed. The Showcard container is a part of this action button and the contents can inserted within the container as needed. Please note that the Showcard opens up as a section below the action button on the Desktop where as it opens a full screen experience on mobile.';
    static readonly descriptionProperty = new DescriptionPropertyEditor(
        '*',
        ShowCardActionPeer.description
    );

    protected getToolTip(): string {
        return 'Double click to open/close';
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            PropertySheetCategory.DefaultCategory,
            ShowCardActionPeer.descriptionProperty
        );
        super.populatePropertySheet(propertySheet, defaultCategory);
    }

    protected addCommands(commands: Array<addChildCommand>) {
        let command: addChildCommand = {
            name: '',
            subCommands: [],
            execute() {}
        };
        command.name = 'View contents';
        command.execute = () => {
            this.getCardObject()['_actionCollection'].expandShowCardAction(
                this.getCardObject(),
                true
            );
            if (this.getChildCount() > 0) {
                this.designerSurface.setSelectedPeer(this.getChildAt(0));
            }
        };
        commands.push(command);
    }
}
