import TypedCardElementPeer from './TypedCardElementPeer';
import { RichTextBlock, TextRun } from 'adaptivecards';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CustomPropertySheetEntry from '../PropertyEditor/CustomPropertySheetEntry';
import PropertySheetContext from '../PropertyEditor/PropertySheetContext';

export default class RichTextBlockPeer extends TypedCardElementPeer<
    RichTextBlock
> {
    protected internalGetTreeItemText(): string {
        return this.cardElement.asString();
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        super.populatePropertySheet(propertySheet, defaultCategory);

        propertySheet.add(
            defaultCategory,
            new CustomPropertySheetEntry(
                '*',
                (context: PropertySheetContext) => {
                    let infoTextBlock: HTMLElement = document.createElement(
                        'p'
                    );
                    infoTextBlock.innerHTML =
                        'Use the **Card Payload Editor** to edit the text of this RichTextBlock element.';
                    infoTextBlock.setAttribute('wrap', 'true');
                    return infoTextBlock;
                }
            )
        );
    }

    initializeCardElement() {
        let textRun = new TextRun();
        textRun.text = 'New RichTextBlock';

        this.cardElement.addInline(textRun);
    }
}
