import { emptyCardPayload } from '../ACDesigner/Constants';

import blankCard from './assets/BlankCard.png';
import poll from './assets/Poll.png';
import subjectiveSurvey from './assets/SubjectiveSurvey.png';
import vacationApproval from './assets/VacationApproval.png';
import expenseApproval from './assets/ExpenseApproval.png';
import conversationNotification from './assets/ConversationNotification.png';
import multipleRequestApproval from './assets/MultipleRequestApproval.png';
import workflowNotification from './assets/WorkflowNotification.png';

export const CardSamples = {
    blankCard: {
        displayName: 'Blank Card',
        fileName: null,
        previewImagePath: blankCard
    },
    poll: {
        displayName: 'Poll',
        fileName: 'Poll',
        previewImagePath: poll
    },
    subjectiveSurvey: {
        displayName: 'Subjective Survey',
        fileName: 'SubjectiveSurvey',
        previewImagePath: subjectiveSurvey
    },
    vacationApproval: {
        displayName: 'Vacation Approval',
        fileName: 'VacationApproval',
        previewImagePath: vacationApproval
    },
    expenseApproval: {
        displayName: 'Expense Approval',
        fileName: 'ExpenseApproval',
        previewImagePath: expenseApproval
    },
    notification: {
        displayName: 'Conversation Notification',
        fileName: 'ConversationNotification',
        previewImagePath: conversationNotification
    },
    multipleRequestApproval: {
        displayName: 'Multiple Request Approval',
        fileName: 'MultipleRequestApproval',
        previewImagePath: multipleRequestApproval
    },
    workflowNotification: {
        displayName: 'Workflow Notification',
        fileName: 'WorkflowNotification',
        previewImagePath: workflowNotification
    },
    blankCardACv2: {
        displayName: 'Blank Card ACv2',
        fileName: 'BlankCardACv2',
        previewImagePath: null
    }
};

export const getSampleJson = async (fileName): Promise<Object> => {
    let json = JSON.parse(emptyCardPayload);
    try {
        if (fileName !== null) {
            const response = await fetch(`/samples/${fileName}.txt`);
            const text = await response.text();
            json = JSON.parse(text);
        }
    } catch (e) {
        // Unable to get Json
    } finally {
        return json;
    }
};
