import InputPeer from './InputPeer';
import CustomNumberInput from '../../components/ActionableMessages/CustomNumberInput';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import NumberPropertyEditor from '../PropertyEditor/NumberPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CardElementPeer from './CardElementPeer';

export default class NumberInputPeer extends InputPeer<CustomNumberInput> {
    static readonly placeholderProperty = new StringPropertyEditor(
        Versions.v1_0,
        'placeholder',
        'Hint Text',
        'Insert hint text that will be displayed as a nudge till the user enters any text. For example "enter a number".'
    );
    static readonly minProperty = new NumberPropertyEditor(
        Versions.v1_0,
        'min',
        'Minimum value',
        'Insert a minimum value that the user cannot enter a number lower than.'
    );
    static readonly maxProperty = new NumberPropertyEditor(
        Versions.v1_0,
        'max',
        'Maximum value',
        'Insert a maximum value that the user cannot enter a number greater than.'
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            InputPeer.idProperty,
            InputPeer.isVisibleProperty,
            NumberInputPeer.placeholderProperty,
            InputPeer.defaultValueProperty,
            NumberInputPeer.minProperty,
            NumberInputPeer.maxProperty,
            InputPeer.isRequiredProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty
        );
    }

    initializeCardElement() {
        super.initializeCardElement();

        this.cardElement.placeholder = 'Enter a number';
    }
}
