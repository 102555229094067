export const AMDESIGNER_STARTED = 'amdesigner_started';
export const DRAG_DROP = 'drag_drop';
export const JSON_EDITOR = 'JSON_Editor';
export const SEND_MAIL = 'send_mail';
export const FEEDBACK = 'feedback';
export const PREVIEW_LOADED = 'preview_loaded';
export const SEND_MAIL_SUCCESS = 'send_mail_success';
export const CARD_LOADED = 'card_loaded';
export const JSON_COPIED = 'json_copied';
export const SIGNIN_ERROR = 'signin_error';
