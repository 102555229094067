import { action, observable, computed } from 'mobx';
import { MessageBarType } from '@fluentui/react';

import { AuthStore } from './AuthStore';
import { AppStore } from './AppStore';
import { NotificationStore } from './NotificationStore';
import { GRAPH_URL, ORIGINATOR } from '../utilities/constants';
import { AWTLogManager, AWTEventPriority } from '@aria/webjs-sdk';
import { SEND_MAIL } from '../utilities/LoggingEventNames';

const sendMailScopes = ['Mail.Send'];
export class UserStore {
    private static storeInstance: UserStore;

    /**
     * Reference: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#unicode_strings
     */
    private toBinary(str: string) {
        const codeUnits = Uint16Array.from(
            { length: str.length },
            (_element, index) => str.charCodeAt(index),
        );
        const charCodes = new Uint8Array(codeUnits.buffer);

        let result = '';
        charCodes.forEach((char) => {
            result += String.fromCharCode(char);
        });
        return result;
    }

    private constructor() {}

    @observable public isSendingEmail = false;
    @observable public privacyLink =
        'https://privacy.microsoft.com/en-US/privacystatement';
    @observable public shouldShowPrivacyCallout = false;

    @computed
    get canSendMail() {
        const authStore = AuthStore.getStoreInstance();
        const appStore = AppStore.getStoreInstance();
        return (
            authStore.isLoggedIn &&
            authStore.userProfile &&
            appStore.designer !== undefined &&
            !this.isSendingEmail
        );
    }

    public static getStoreInstance(): UserStore {
        if (!UserStore.storeInstance) {
            UserStore.storeInstance = new UserStore();
        }
        return UserStore.storeInstance;
    }

    @action
    public async sendEmail() {
        const appStore = AppStore.getStoreInstance();
        const authStore = AuthStore.getStoreInstance();
        const notificationStore = NotificationStore.getStoreInstance();
        try {
            if (!this.canSendMail) {
                throw new Error('Cannot Send Mail');
            }

            let payload: any;
            if (appStore.isACv2DesignView) {
                payload = appStore.getCardJsonACv2();
            } else {
                payload = appStore.getCardJSON();
            }

            this.isSendingEmail = true;
            payload['@type'] = payload['@type'] || payload.type;
            payload['@context'] =
                payload['@context'] || 'http://schema.org/extensions';
            if (!payload['@type']) {
                return;
            }

            /**
             * Reference: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#unicode_strings
             */
            const stringPayload = JSON.stringify(payload, null, 4);
            const binaryPayload = this.toBinary(stringPayload);
            const base64Payload = window.btoa(binaryPayload);

            //Adding originator after the attachment payload is created
            if (!payload.originator) {
                payload.originator = ORIGINATOR;
            }

            const emailScriptType =
                payload['@type'].toLowerCase() === 'adaptivecard'
                    ? 'adaptivecard'
                    : 'ld';
            const accessToken = await authStore.getAccessToken(sendMailScopes);
            const mail = {
                Subject:
                    payload.cardName || 'Actionable Message Designer Test Card',
                ToRecipients: [
                    {
                        EmailAddress: {
                            Address: authStore.userEmail,
                        },
                    },
                ],
                Body: {
                    Content:
                        `<html>
                        <head>
                            <script type="application/${emailScriptType}+json">${JSON.stringify(
                                payload,
                            )}</script>
                        </head>
                        <body>` +
                        // tslint:disable-next-line:max-line-length
                        `<br>This message was sent from the <a target="_blank" href="https://amdesigner.azurewebsites.net/">Actionable Message Designer</a> tool. If the card doesn&#39;t appear, install <a target="_blank" href="https://store.office.com/app.aspx?assetid=WA104381686&productgroup=Outlook"/>Actionable Message Debugger Outlook add-in</a> to debug the issue.<br><br>
                            Note: JSON payload of the card attached with this message.<br>` +
                        `
                        </body>
                    </html>`,
                    ContentType: 'html',
                },
                attachments: [
                    {
                        '@odata.type': '#microsoft.graph.fileAttachment',
                        name: 'AMCardPayload.txt',
                        contentType: 'text/plain',
                        contentBytes: base64Payload,
                    },
                ],
            };

            const response = await fetch(`${GRAPH_URL}/me/sendMail`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    Message: mail,
                    SaveToSentItems: false,
                }),
            });
            if (!response.ok) {
                AWTLogManager.getLogger().logEvent({
                    name: SEND_MAIL,
                    priority: AWTEventPriority.Normal,
                    properties: {
                        status: 'Error',
                    },
                });
                throw response;
            }
            notificationStore.showMessageBar({
                message: 'The card was successfully sent',
                messageType: MessageBarType.success,
            });
            AWTLogManager.getLogger().logEvent({
                name: SEND_MAIL,
                priority: AWTEventPriority.Normal,
                properties: {
                    status: 'Success',
                },
            });
        } catch (error) {
            notificationStore.showMessageBar({
                message: 'Unable to send the card',
                messageType: MessageBarType.error,
            });
        } finally {
            this.isSendingEmail = false;
        }
    }
}
