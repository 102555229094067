import React from 'react';
import { inject, observer } from 'mobx-react';
import { initializeIcons } from '@fluentui/react';

import { AppStore, VIEW } from './stores/AppStore';
import { TopBar } from './components/topbar/TopBar';
import { DesignerToolBar } from './components/design/DesignerToolBar';
import Designer from './components/design/Designer';
import Notification from './components/Notification';
import Preview from './components/preview/Preview';
import LoadSample from './components/sample/LoadSample';
import { AWTLogManager, AWTEventPriority } from '@aria/webjs-sdk';

import './App.css';
import './Font.css';
import './Icon.css';
import { INGESTION_KEY } from './utilities/constants';
import { AMDESIGNER_STARTED } from './utilities/LoggingEventNames';
import { AppStoreProps } from './stores/StoreProps';
import { NotificationStore } from './stores/NotificationStore';
import { AuthStore } from './stores/AuthStore';
import { UserStore } from './stores/UserStore';
import { PrivacyFooter } from './components/PrivacyFooter';

@inject('appStore')
@observer
export default class App extends React.Component<AppStoreProps> {
    private handleRightClick = (event: Event) => {
        event.preventDefault();
    };

    private blockBeforeUnload = (event: Event) => {
        if (
            this.props.appStore.designer &&
            this.props.appStore.designer.canUndo
        ) {
            event.preventDefault();
            event.returnValue = false;
        }
    };

    constructor(props) {
        super(props);
        // Register icons and pull the fonts from the default SharePoint cdn
        initializeIcons();
    }

    public componentDidMount() {
        window.addEventListener('contextmenu', this.handleRightClick);
        window.addEventListener('beforeunload', this.blockBeforeUnload);
    }

    public componentWillUnmount() {
        window.removeEventListener('contextmenu', this.handleRightClick);
        window.removeEventListener('beforeunload', this.blockBeforeUnload);
    }

    render() {
        AWTLogManager.initialize(INGESTION_KEY);
        AWTLogManager.getLogger().logSession(0, {
            name: AMDESIGNER_STARTED,
            priority: AWTEventPriority.High,
        });
        let view = null;
        switch (this.props.appStore.currentView) {
            case VIEW.ACv2_DESIGN:
            case VIEW.DESIGN:
                view = <DesignView />;
                break;

            case VIEW.PREVIEW:
                view = <PreviewView />;
                break;

            case VIEW.LOAD_SAMPLE:
                view = <LoadSampleView />;
                break;
        }
        return view;
    }
}

const DesignView = () => {
    return (
        <div id="app">
            <TopBar
                appStore={AppStore.getStoreInstance()}
                authStore={AuthStore.getStoreInstance()}
            />
            <DesignerToolBar appStore={AppStore.getStoreInstance()} />
            <Designer appStore={AppStore.getStoreInstance()} />
            <Notification
                notificationStore={NotificationStore.getStoreInstance()}
            />
            <PrivacyFooter />
        </div>
    );
};

const PreviewView = () => {
    return (
        <div id="app">
            <TopBar
                appStore={AppStore.getStoreInstance()}
                authStore={AuthStore.getStoreInstance()}
            />
            <Preview
                appStore={AppStore.getStoreInstance()}
                authStore={AuthStore.getStoreInstance()}
                userStore={UserStore.getStoreInstance()}
            />
            <Notification
                notificationStore={NotificationStore.getStoreInstance()}
            />
            <PrivacyFooter />
        </div>
    );
};

const LoadSampleView = () => {
    return (
        <div id="app">
            <TopBar
                appStore={AppStore.getStoreInstance()}
                authStore={AuthStore.getStoreInstance()}
            />
            <LoadSample />
            <Notification
                notificationStore={NotificationStore.getStoreInstance()}
            />
            <PrivacyFooter />
        </div>
    );
};
