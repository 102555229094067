import DesignerPeer from './DesignerPeers/DesignerPeer';
import CardDesignerSurface from './CardDesignerSurface';
import DesignerPeerRegistrationBase from './DesignerPeerRegistrationBase';
import CardElementPeer from './DesignerPeers/CardElementPeer';
import DesignerPeerRegistry from './DesignerPeerRegistry';
import AdaptiveCardPeer from './DesignerPeers/AdaptiveCardPeer';
import DesignerPeerCategory from './DesignerPeerCategory';
import ContainerPeer from './DesignerPeers/ContainerPeer';
import ColumnSetPeer from './DesignerPeers/ColumnSetPeer';
import ColumnPeer from './DesignerPeers/ColumnPeer';
import ImageSetPeer from './DesignerPeers/ImageSetPeer';
import FactSetPeer from './DesignerPeers/FactSetPeer';
import TextBlockPeer from './DesignerPeers/TextBlockPeer';
import RichTextBlockPeer from './DesignerPeers/RichTextBlockPeer';
import ImagePeer from './DesignerPeers/ImagePeer';
import ActionSetPeer from './DesignerPeers/ActionSetPeer';
import CustomTextInput from '../components/ActionableMessages/CustomTextInput';
import TextInputPeer from './DesignerPeers/TextInputPeer';
import CustomDateInput from '../components/ActionableMessages/CustomDateInput';
import DateInputPeer from './DesignerPeers/DateInputPeer';
import ToggleInputPeer from './DesignerPeers/ToggleInputPeer';
import CustomNumberInput from '../components/ActionableMessages/CustomNumberInput';
import NumberInputPeer from './DesignerPeers/NumberInputPeer';
import CustomChoiceSetInput from '../components/ActionableMessages/CustomChoiceSetInput';
import ChoiceSetInputPeer from './DesignerPeers/ChoiceSetInputPeer';
import {
    CardElement,
    ImageSet,
    Image,
    FactSet,
    RichTextBlock,
    ActionSet,
    Column,
    Container,
    AdaptiveCard,
    ColumnSet
} from 'adaptivecards';
import CustomToggleInput from '../components/ActionableMessages/CustomToggleInput';
import CustomTextBlock from '../components/ActionableMessages/CustomTextBlock';

type CardElementType = { new (): CardElement };

type CardElementPeerType = {
    new (
        parent: DesignerPeer,
        designerSurface: CardDesignerSurface,
        registration: DesignerPeerRegistrationBase,
        cardElement: CardElement
    ): CardElementPeer;
};

export default class CardElementPeerRegistry extends DesignerPeerRegistry<
    CardElementType,
    CardElementPeerType
> {
    reset() {
        this.clear();

        this.registerPeer(
            AdaptiveCard,
            AdaptiveCardPeer as any,
            DesignerPeerCategory.Containers,
            'acd-icon-adaptiveCard'
        );
        this.registerPeer(
            Container,
            ContainerPeer as any,
            DesignerPeerCategory.Containers,
            'acd-icon-container'
        );
        this.registerPeer(
            ColumnSet,
            ColumnSetPeer as any,
            DesignerPeerCategory.Containers,
            'acd-icon-columnSet'
        );
        this.registerPeer(
            Column,
            ColumnPeer as any,
            DesignerPeerCategory.Containers,
            'acd-icon-column'
        );
        this.registerPeer(
            ImageSet,
            ImageSetPeer as any,
            DesignerPeerCategory.Containers,
            'acd-icon-imageSet'
        );
        this.registerPeer(
            FactSet,
            FactSetPeer as any,
            DesignerPeerCategory.Containers,
            'acd-icon-factSet'
        );

        this.registerPeer(
            CustomTextBlock,
            TextBlockPeer as any,
            DesignerPeerCategory.Elements,
            'acd-icon-textBlock'
        );
        this.registerPeer(
            RichTextBlock,
            RichTextBlockPeer as any,
            DesignerPeerCategory.Elements,
            'acd-icon-richTextBlock'
        );
        this.registerPeer(
            Image,
            ImagePeer as any,
            DesignerPeerCategory.Elements,
            'acd-icon-image'
        );
        this.registerPeer(
            ActionSet,
            ActionSetPeer as any,
            DesignerPeerCategory.Elements,
            'acd-icon-actionSet'
        );

        this.registerPeer(
            CustomTextInput,
            TextInputPeer as any,
            DesignerPeerCategory.Inputs,
            'acd-icon-inputText'
        );
        this.registerPeer(
            CustomDateInput,
            DateInputPeer as any,
            DesignerPeerCategory.Inputs,
            'acd-icon-inputDate'
        );
        this.registerPeer(
            CustomToggleInput,
            ToggleInputPeer as any,
            DesignerPeerCategory.Inputs,
            'acd-icon-inputToggle'
        );
        this.registerPeer(
            CustomNumberInput,
            NumberInputPeer as any,
            DesignerPeerCategory.Inputs,
            'acd-icon-inputNumber'
        );
        this.registerPeer(
            CustomChoiceSetInput,
            ChoiceSetInputPeer as any,
            DesignerPeerCategory.Inputs,
            'acd-icon-inputChoiceSet'
        );
    }

    createPeerInstance(
        designerSurface: CardDesignerSurface,
        parent: DesignerPeer,
        cardElement: CardElement
    ): CardElementPeer {
        var registrationInfo = this.findTypeRegistration(
            (cardElement as any).constructor
        );

        var peer = registrationInfo
            ? new registrationInfo.peerType(
                  parent,
                  designerSurface,
                  registrationInfo,
                  cardElement
              )
            : new CardElementPeer(
                  parent,
                  designerSurface,
                  this.defaultRegistration,
                  cardElement
              );

        return peer;
    }
}
