import PropertySheetEntry from './PropertySheetEntry';
import PropertySheetContext from './PropertySheetContext';
import { isVersionLessOrEqual } from '../Shared';

export default class PropertySheetCategory {
    static readonly DefaultCategory = '__defaultCategory';
    static readonly LayoutCategory = 'Layout';
    static readonly StyleCategory = 'Style';
    static readonly SelectionAction = 'Selection action';
    static readonly InlineAction = 'Inline action';

    private _entries: PropertySheetEntry[] = [];

    constructor(readonly name: string) {}

    render(container: HTMLElement, context: PropertySheetContext) {
        let entriesToRender: PropertySheetEntry[] = [];

        for (let entry of this._entries) {
            if (
                isVersionLessOrEqual(entry.targetVersion, context.targetVersion)
            ) {
                entriesToRender.push(entry);
            }
        }

        if (entriesToRender.length > 0) {
            for (let entry of entriesToRender) {
                if (
                    isVersionLessOrEqual(
                        entry.targetVersion,
                        context.targetVersion
                    )
                ) {
                    container.appendChild(entry.render(context));
                }
            }
        }
    }

    add(...entries: PropertySheetEntry[]) {
        this._entries = this._entries.concat(entries);
    }

    remove(...entries: PropertySheetEntry[]) {
        for (let entry of entries) {
            let index: number;

            do {
                let index = this._entries.indexOf(entry);

                if (index >= 0) {
                    this._entries.splice(index, 1);
                }
            } while (index >= 0);
        }
    }

    getEntryAt(index: number): PropertySheetEntry {
        return this._entries[index];
    }

    get length(): number {
        return this._entries.length;
    }
}
