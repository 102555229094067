import InputPeer from './InputPeer';
import CustomChoiceSetInput from '../../components/ActionableMessages/CustomChoiceSetInput';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import { Choice } from 'adaptivecards';
import NameValuePairPropertyEditor from '../PropertyEditor/NameValuePairPropertyEditor';
import { generateNameValue } from './DesignerPeersUtils';
import CardElementPeer from './CardElementPeer';

export default class ChoiceSetInputPeer extends InputPeer<
    CustomChoiceSetInput
> {
    static readonly placeholderProperty = new StringPropertyEditor(
        Versions.v1_0,
        'placeholder',
        'Placeholder',
        'Insert hint text that will be displayed as a nudge till the user enters any text. For example "Give a reason" or "enter your email address".'
    );
    static readonly isMultiselectProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'isMultiSelect',
        'Allow multiple choices',
        'Turning this property on will allow users to make multiple selections.'
    );
    static readonly isCompactProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'isCompact',
        'Make this a drop down list',
        'Turning this property on will insert the choices into a drop down. ',
        true
    );
    static readonly wrapProperty = new BooleanPropertyEditor(
        Versions.v1_2,
        'wrap',
        'Wrap Text',
        "Turning on this property will wrap the text to the next line when there isn't enough space. If turned off, your text might get truncated. "
    );
    static readonly choicesProperty = new NameValuePairPropertyEditor(
        Versions.v1_0,
        'choices',
        'title',
        'value',
        (name: string, value: string) => {
            return new Choice(name, value);
        },
        (nameValuePair: Array<{ name: string; value: string }>) => {
            let name: string = 'Choice';
            let value: string = 'Choice';
            return generateNameValue(nameValuePair, name, value);
        },
        'Insert the display name of the choice.',
        'Insert the value that associates with the choice.',
        'Title',
        'Value',
        'Enter choice title',
        'Enter choice value',
        'Add a new choice',
        'This ChoiceSet is empty'
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            InputPeer.idProperty,
            InputPeer.isVisibleProperty,
            ChoiceSetInputPeer.isCompactProperty
        );

        if (this.cardElement.isCompact) {
            propertySheet.add(
                defaultCategory,
                ChoiceSetInputPeer.placeholderProperty
            );
        }

        propertySheet.add(
            defaultCategory,
            ChoiceSetInputPeer.isMultiselectProperty,
            InputPeer.isRequiredProperty,
            ChoiceSetInputPeer.wrapProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty,
            ChoiceSetInputPeer.choicesProperty
        );
    }

    initializeCardElement() {
        this.cardElement.placeholder = 'Placeholder text';

        this.cardElement.choices.push(
            new Choice('Choice 1', 'Choice 1'),
            new Choice('Choice 2', 'Choice 2')
        );
    }
}
