import { getEnumValue, Spacing, PaddingDefinition } from 'adaptivecards';

export const getDefaultPadding = () => {
    return new PaddingDefinition();
};

const searchSpacing = function(value) {
    for (let key in Spacing) {
        if (Spacing[key] === value) return key;
    }
    return null;
};

export const getPaddingValue = function(
    padding: PaddingDefinition
): Object | string {
    if (padding === null) {
        return 'None';
    } else {
        if (
            padding.top === padding.bottom &&
            padding.top === padding.left &&
            padding.top === padding.right
        ) {
            return searchSpacing(padding.top);
        } else {
            return {
                top: searchSpacing(padding.top),
                bottom: searchSpacing(padding.bottom),
                left: searchSpacing(padding.left),
                right: searchSpacing(padding.right)
            };
        }
    }
};

export const parsedPadding = function(json: any): PaddingDefinition {
    let padding: PaddingDefinition;
    if (json.padding) {
        if (typeof json.padding === 'object') {
            let paddingTop: number = getEnumValue(
                Spacing,
                json.padding.top,
                Spacing.None
            );
            let paddingBottom: number = getEnumValue(
                Spacing,
                json.padding.bottom,
                Spacing.None
            );
            let paddingLeft: number = getEnumValue(
                Spacing,
                json.padding.left,
                Spacing.None
            );
            let paddingRight: number = getEnumValue(
                Spacing,
                json.padding.right,
                Spacing.None
            );
            padding = new PaddingDefinition(
                paddingTop,
                paddingRight,
                paddingBottom,
                paddingLeft
            );
        } else {
            let paddingValue: number = getEnumValue(
                Spacing,
                json.padding,
                Spacing.None
            );
            padding = new PaddingDefinition(
                paddingValue,
                paddingValue,
                paddingValue,
                paddingValue
            );
        }
    } else {
        padding = getDefaultPadding();
    }
    return padding;
};
