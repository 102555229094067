import { Image, ImageSet } from 'adaptivecards';

const indexOf = () => {
    ImageSet.prototype['indexOf'] = function(image: Image): number {
        return this['_images'].indexOf(image);
    };
};

const insertItemAt = function() {
    ImageSet.prototype['insertItemAt'] = function(
        image: Image,
        index: number,
        forceInsert: boolean
    ) {
        if (!image.parent || forceInsert) {
            if (index < 0 || index >= this['_images'].length) {
                this['_images'].push(image);
            } else {
                this['_images'].splice(index, 0, image);
            }
            image.setParent(this);
        } else {
            throw new Error(
                'The element already belongs to another container.'
            );
        }
    };
};

const insertItemBefore = () => {
    ImageSet.prototype['insertItemBefore'] = function(
        image: Image,
        insertBefore: Image
    ) {
        this['insertItemAt'](
            image,
            this['_images'].indexOf(insertBefore),
            false
        );
    };
};

const insertItemAfter = () => {
    ImageSet.prototype['insertItemAfter'] = function(
        item: Image,
        insertAfter: Image
    ) {
        this['insertItemAt'](
            item,
            this['_images'].indexOf(insertAfter) + 1,
            false
        );
    };
};

export const initialize = () => {
    indexOf();
    insertItemAt();
    insertItemBefore();
    insertItemAfter();
};
