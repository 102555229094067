import React from 'react';
import { inject, observer } from 'mobx-react';
import { ActionButton, IButtonStyles } from '@fluentui/react';

import { VIEW } from '../../stores/AppStore';
import CardRender from '../../dependencies/MobileCardRenderer';
import {
    anchorClicked,
    actionExecuted,
    parseElement,
} from '../../ACDesigner/HostContainer';
import { AdaptiveCard } from 'adaptivecards';

import mobileHeader from './assets/mobile_header.png';
import mobileSender from './assets/mobile_sender.png';
import mobileFooter from './assets/mobile_footer.svg';
import mobileMailHeader from './assets/mobile_mail_header.svg';
import mobileMailFooter from './assets/mobile_mail_footer.png';
import owaHeader from './assets/owa_header.svg';

import './Preview.css';
import './MobilePreview.css';
import { action } from 'mobx';
import { Send } from '../design/DesignerToolBarButtons';
import { AWTLogManager, AWTEventPriority } from '@aria/webjs-sdk';
import { PREVIEW_LOADED } from '../../utilities/LoggingEventNames';
import {
    AppStoreProps,
    AuthStoreProps,
    UserStoreProps,
} from '../../stores/StoreProps';

@inject('appStore', 'authStore', 'userStore')
@observer
export default class Preview extends React.Component<
    AppStoreProps & AuthStoreProps & UserStoreProps
> {
    private owaPreviewCard: HTMLDivElement | null;

    private previewElement = (
        <div className="preview" role="main">
            <div className="previewButtonsWrapper">
                <ExitButton appStore={this.props.appStore} />
                <Send
                    authStore={this.props.authStore}
                    userStore={this.props.userStore}
                />
            </div>
            <div className="previewContentWrapper" id="previewContentWrapper">
                <div className="mobilePreview previewContent">
                    <img
                        className="mobilePreviewHeader mobilePreviewImage"
                        src={mobileHeader}
                        alt="Mobile Preview Header"
                    />
                    <div id="mobileMailWrapper" className="mobileMailWrapper">
                        <img
                            className="mobilePreviewMailHeader mobilePreviewImage"
                            src={mobileMailHeader}
                            alt="Mobile Preview Mail Header"
                        />
                        <div className="mobilePreviewMailContent mobilePreviewBorderLR">
                            <div className="mobilePreviewMailSubject">
                                Subject Line
                            </div>
                            <img
                                className="mobilePreviewMailSender"
                                src={mobileSender}
                                alt="Mobile Preview Mail Sender"
                            />
                            <div
                                id="mobilePreviewCard"
                                className="mobilePreviewCard previewCardBorder"
                            ></div>
                        </div>
                        <img
                            className="mobilePreviewMailImage mobilePreviewBorderLR"
                            src={mobileMailFooter}
                            alt="Mobile Preview Mail Footer"
                        />
                    </div>
                    <div
                        id="mobileShowCardWrapper"
                        className="mobileShowCardWrapper"
                        style={{ display: 'none' }}
                    ></div>
                    <div id="mobileOverlay" className="mobileOverlay"></div>
                    <img
                        className="mobilePreviewFooter mobilePreviewImage mobilePreviewBorderLR"
                        src={mobileFooter}
                        alt="Mobile Preview Footer"
                    />
                </div>
                <div className="owaPreview previewContent">
                    <img
                        className="owaPreviewHeader"
                        src={owaHeader}
                        alt="OWA Preview Header"
                    />
                    <div
                        id="owaPreviewCard"
                        className="owaPreviewCard previewCardBorder"
                        ref={(div) => (this.owaPreviewCard = div)}
                    ></div>
                </div>
            </div>
        </div>
    );

    private renderMobilePreview(payload) {
        const context = {
            isShowCardJson: false,
            version: 1.0,
        };
        const renderer = new CardRender(
            '#mobilePreviewCard',
            context,
            '#mobileOverlay',
        );
        renderer['renderCard'](JSON.stringify(payload));
    }

    private renderDesktopPreview(payload) {
        const card = new AdaptiveCard();
        card.hostConfig =
            this.props.appStore.designer.hostContainer.getHostConfig();
        card.onAnchorClicked = anchorClicked;
        card.onParseElement = parseElement;
        card.onExecuteAction = actionExecuted;
        card.designMode = false;
        card.parse(payload);
        this.owaPreviewCard.appendChild(card.render());
    }

    private handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
        }
    };

    public componentDidMount() {
        document
            .getElementById('previewContentWrapper')
            .addEventListener('keydown', this.handleKeyDown);
        const payload = this.props.appStore.getCardJSON();
        const payloadString = JSON.stringify(payload);

        this.renderMobilePreview(JSON.parse(payloadString));
        this.renderDesktopPreview(JSON.parse(payloadString));

        AWTLogManager.getLogger().logEvent({
            name: PREVIEW_LOADED,
            priority: AWTEventPriority.Normal,
        });
    }

    public render() {
        return this.previewElement;
    }
}

@inject('appStore')
@observer
class ExitButton extends React.Component<AppStoreProps> {
    private buttonStyles: IButtonStyles = {
        root: {
            padding: '14px 8px',
            height: '48px',
        },
    };

    public render() {
        return (
            <ActionButton
                iconProps={{
                    iconName: 'ChromeClose',
                }}
                styles={this.buttonStyles}
                className={'exitButton'}
                onClick={action(() => {
                    this.props.appStore.currentView = VIEW.DESIGN;
                })}
                tabIndex={0}
            >
                Exit Preview
            </ActionButton>
        );
    }
}
