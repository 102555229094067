import { TargetVersion, isVersionLessOrEqual } from '../Shared';
import SingleInputPropertyEditor from './SingleInputPropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import { keyValuePair, notSet } from '../DesignerPeers/DesignerPeersUtils';
import GeneratePropertyFields from './GeneratePropertyFields';

interface IVersionedChoice {
    targetVersion: TargetVersion;
    name: string;
    value: string;
}

export default class ChoicePropertyEditor extends SingleInputPropertyEditor {
    protected createInput(
        context: PropertySheetContext,
        eventCallback
    ): HTMLElement {
        let entries: keyValuePair = {};
        for (let choice of this.choices) {
            if (
                isVersionLessOrEqual(
                    choice.targetVersion,
                    context.targetVersion
                )
            ) {
                entries[choice.name] = choice.value;
            }
        }
        let defaultValue = '0';
        if (this.getPropertyValue(context))
            defaultValue = this.getPropertyValue(context).toString();
        let input: HTMLElement = new GeneratePropertyFields().generateDropDown(
            entries,
            eventCallback,
            defaultValue,
            this.label
        );
        (input as HTMLInputElement).placeholder = notSet;
        return input;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly choices: IVersionedChoice[],
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(
            targetVersion,
            propertyName,
            label,
            tooltipContent,
            causesPropertySheetRefresh
        );
    }
}
