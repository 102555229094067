import TypedCardElementPeer from './TypedCardElementPeer';
import { FactSet, Fact } from 'adaptivecards';
import { Versions } from '../Shared';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CardElementPeer from './CardElementPeer';
import NameValuePairPropertyEditor from '../PropertyEditor/NameValuePairPropertyEditor';
import { generateNameValue } from './DesignerPeersUtils';

export default class FactSetPeer extends TypedCardElementPeer<FactSet> {
    static readonly factsProperty = new NameValuePairPropertyEditor(
        Versions.v1_0,
        'facts',
        'name',
        'value',
        (name: string, value: string) => {
            return new Fact(name, value);
        },
        (nameValuePair: Array<{ name: string; value: string }>) => {
            let name: string = 'Fact Title';
            let value: string = 'Fact Value';
            return generateNameValue(nameValuePair, name, value);
        },
        'Insert the label or description for the content here. ',
        'Insert the content for the relevant description here. ',
        'Title',
        'Value',
        'Enter fact title',
        'Enter fact value',
        'Add a fact set',
        'This FactSet is empty.'
    );

    protected internalGetTreeItemText(): string {
        if (this.cardElement.facts.length === 0) {
            return 'No fact';
        }

        let allNames = this.cardElement.facts.map((value, index, array) => {
            return value.name;
        });

        return allNames.join(', ');
    }

    initializeCardElement() {
        super.initializeCardElement();
        const fact1 = new Fact('Title', 'Value');
        const fact2 = new Fact('Title', 'Value');
        this.cardElement.facts.push(fact1, fact2);
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            CardElementPeer.idProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty,
            FactSetPeer.factsProperty
        );
    }
}
