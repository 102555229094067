import React from 'react';
import { TextField } from '@fluentui/react';

interface TextFieldState {
    value: string;
}

interface TextFieldProps {
    placeholder: string;
    defaultValue: string;
    onChangeCallback(value: string): void;
    multiline: boolean;
}

export default class CustomTextField extends React.Component<
    TextFieldProps,
    TextFieldState
> {
    constructor(props) {
        super(props);
        this.state = { value: this.props.defaultValue };
    }

    public render() {
        return !this.props.multiline ? (
            <TextField
                value={this.state.value}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
            />
        ) : (
            <TextField
                value={this.state.value}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
                multiline
                rows={3}
            />
        );
    }

    private onChange = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        this.setState({ value: newValue });
        this.props.onChangeCallback(newValue);
    };
}
