import { Container, setProperty, IValidationError } from 'adaptivecards';

import {
    getDefaultPadding,
    getPaddingValue as getPaddingValueUtil,
    parsedPadding
} from './CustomElementUtils';

const getEffectivePadding = function() {
    Container.prototype.getEffectivePadding = function() {
        const padding = this.padding;
        return padding ? padding : getDefaultPadding();
    };
};

const getPaddingValue = function(container: Container): Object | string {
    return getPaddingValueUtil(container.padding);
};

const toJSON = () => {
    const prototypeToJSON = Container.prototype.toJSON;
    Container.prototype.toJSON = function() {
        const result = prototypeToJSON.call(this);
        setProperty(result, 'padding', getPaddingValue(this));
        return result;
    };
};

const parse = () => {
    const prototypeParse = Container.prototype.parse;
    Container.prototype.parse = function(
        json: any,
        errors?: Array<IValidationError>
    ) {
        prototypeParse.call(this, json, errors);
        this.padding = parsedPadding(json);
    };
};

export const initialize = () => {
    getEffectivePadding();
    toJSON();
    parse();
};
