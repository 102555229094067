import {
    setProperty,
    IValidationError,
    getBoolValue,
    ToggleInput,
    getStringValue
} from 'adaptivecards';

export default class CustomToggleInput extends ToggleInput {
    private isRequired: boolean = false;
    private label: string | undefined;

    protected internalRender(): HTMLElement {
        let renderedElement: HTMLElement = super.internalRender();
        if (this.isRequired) {
            renderedElement.setAttribute('required', 'true');
        }
        if (renderedElement && this.label) {
            renderedElement.setAttribute('aria-label', this.label);
        }
        return renderedElement;
    }

    toJSON(): any {
        let result = super.toJSON();
        setProperty(result, 'isRequired', this.isRequired, false);
        setProperty(result, 'label', this.label);
        return result;
    }

    parse(json: any, errors?: Array<IValidationError>) {
        super.parse(json, errors);
        this.isRequired = getBoolValue(json['isRequired'], this.isRequired);
        this.label = getStringValue(json['label']);
    }
}
