import SingleInputPropertyEditor from './SingleInputPropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';
import { TargetVersion } from '../Shared';

export default class NumberPropertyEditor extends SingleInputPropertyEditor {
    protected setPropertyValue(context: PropertySheetContext, value: string) {
        try {
            context.target[this.propertyName] = parseFloat(value);
        } catch (e) {
            context.target[this.propertyName] = this.defaultValue;
        }
    }

    protected createInput(
        context: PropertySheetContext,
        eventCallback
    ): HTMLElement {
        let defaultValue: string;
        if (
            this.getPropertyValue(context) !== undefined &&
            this.getPropertyValue(context) !== null
        )
            defaultValue = this.getPropertyValue(context).toString();

        let input: HTMLElement = new GeneratePropertyFields().generateTextFields(
            false,
            this.label,
            defaultValue,
            eventCallback
        );
        return input;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly tooltipContent: string,
        readonly defaultValue: number | undefined = undefined,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(
            targetVersion,
            propertyName,
            label,
            tooltipContent,
            causesPropertySheetRefresh
        );
    }
}
