import { Version } from 'adaptivecards';

export class GlobalSettings {
    static previewFeaturesEnabled: boolean = false;
}

export type TargetVersion = Version | '*';

export class Versions {
    static readonly v1_0 = new Version(1, 0);
    static readonly v1_1 = new Version(1, 1);
    static readonly v1_2 = new Version(1, 2);
    static readonly latest = Versions.v1_2;
    static readonly vNext = new Version(1000, 0, 'vNext');
}

export var SupportedTargetVersions: Version[] = [
    Versions.v1_0,
    Versions.v1_1,
    Versions.v1_2,
    Versions.vNext
];

export function isVersionLessOrEqual(
    version: TargetVersion,
    targetVersion: TargetVersion
): boolean {
    if (version instanceof Version) {
        if (targetVersion instanceof Version) {
            return targetVersion.compareTo(version) >= 0;
        } else {
            // Target version is *
            return true;
        }
    } else {
        // Version is *
        return true;
    }
}
