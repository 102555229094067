import BaseInventoryItem from '../ACDesigner/BaseInventoryItem';
import {
    Action,
    ITypeRegistration,
    ActionSet,
    CardElement,
    HttpAction,
    OpenUrlAction
} from 'adaptivecards';
import CardDesignerSurface from '../ACDesigner/CardDesignerSurface';
import CardElementPeer from '../ACDesigner/DesignerPeers/CardElementPeer';

import {
    findActionTypeRegistration,
    findElementTypeRegistration
} from './InventoryUtil';

import { generateUUID } from '../utilities/Utils';

export default class ActionInventoryItem extends BaseInventoryItem {
    private parentTypeName: string = 'ActionSet';

    private parentTypeRegistration: ITypeRegistration<CardElement>;

    private httpActionURL: string = 'https://www.microsoft.com';

    private openUrlActionURL: string = 'https://amdesigner.azurewebsites.net';

    readonly typeName: string;

    readonly typeRegistration: ITypeRegistration<Action>;

    constructor(element: HTMLElement, typeName: string) {
        super(element);
        this.typeName = typeName;
        this.typeRegistration = findActionTypeRegistration(this.typeName);
        this.parentTypeRegistration = findElementTypeRegistration(
            this.parentTypeName
        );
    }

    createPeer(designer: CardDesignerSurface): CardElementPeer {
        const action: Action = this.typeRegistration.createInstance();
        action.id = generateUUID();
        action.title = this.typeName;

        if (action instanceof HttpAction) {
            action.url = this.httpActionURL;
        } else if (action instanceof OpenUrlAction) {
            action.url = this.openUrlActionURL;
        }

        const actionSet: ActionSet = this.parentTypeRegistration.createInstance() as ActionSet;
        actionSet.id = generateUUID();
        actionSet.addAction(action);

        const peer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
            designer,
            null,
            actionSet
        );
        peer.initializeCardElement();

        return peer;
    }
}
