import React from 'react';

import { ToolsMap, ToolCategory } from './DesignerToolsUtils';

import './DesignerToolsDropdown.css';
import { AppStoreProps } from '../../stores/StoreProps';

enum Side {
    LEFT,
    RIGHT,
}

type DropdownProps = {
    selectedCategory: ToolCategory;
    stores: AppStoreProps;
};

type DropdownState = {
    width: number;
    leftScrollHeaderVisibility: boolean;
    rightScrollHeaderVisibility: boolean;
};

export class DesignerToolsDropdown extends React.Component<
    DropdownProps,
    DropdownState
> {
    private contentRef: React.RefObject<HTMLDivElement> =
        React.createRef<HTMLDivElement>();

    private wrapperRef: React.RefObject<HTMLDivElement> =
        React.createRef<HTMLDivElement>();

    private updateScrollHeaderVisibility = () => {
        const scrollWidth: number = this.wrapperRef.current.scrollWidth;
        const visibleWidth: number = this.state.width;
        let leftHeaderVisible: boolean = false;
        let rightHeaderVisible: boolean = false;

        // Don't have to worry about the else part because the header values are initialized with false.
        if (visibleWidth < scrollWidth) {
            if (
                this.contentRef.current.scrollLeft !== 0 &&
                this.contentRef.current.scrollLeft < scrollWidth - visibleWidth
            ) {
                leftHeaderVisible = true;
                rightHeaderVisible = true;
            } else if (this.contentRef.current.scrollLeft === 0) {
                rightHeaderVisible = true;
            } else {
                leftHeaderVisible = true;
            }
        }

        if (
            this.state.leftScrollHeaderVisibility !== leftHeaderVisible ||
            this.state.rightScrollHeaderVisibility !== rightHeaderVisible
        ) {
            this.setState({
                leftScrollHeaderVisibility: leftHeaderVisible,
                rightScrollHeaderVisibility: rightHeaderVisible,
            });
        }
    };

    private scroll = (side: Side) => {
        const scrollBy = this.getScrollDelta(side);
        this.contentRef.current.scrollLeft += scrollBy;
    };

    private getScrollDelta = (side: Side) => {
        const scrollHeaderSize = 42;
        const defaultScrollBy = 30;
        const parentBBox = this.contentRef.current.getBoundingClientRect();
        let elements = Array.from(
            this.wrapperRef.current.querySelectorAll('.designerTool'),
        );
        let firstHiddenElementBBox;
        let scrollBy = 0;
        switch (side) {
            case Side.LEFT:
                const computedParentBBoxLeft =
                    parentBBox.left + scrollHeaderSize;
                for (const element of elements) {
                    const elementBBox = element.getBoundingClientRect();
                    if (elementBBox.left < computedParentBBoxLeft) {
                        firstHiddenElementBBox = elementBBox;
                    } else {
                        break;
                    }
                }
                if (typeof firstHiddenElementBBox === 'undefined') {
                    scrollBy = -defaultScrollBy;
                } else {
                    scrollBy = -(
                        this.state.width -
                        (firstHiddenElementBBox.right - parentBBox.left) -
                        scrollHeaderSize * 2
                    );
                }
                break;

            case Side.RIGHT:
                elements = elements.reverse();
                const computedParentBBoxRight =
                    parentBBox.right - scrollHeaderSize;
                for (const element of elements) {
                    const elementBBox = element.getBoundingClientRect();
                    if (elementBBox.right > computedParentBBoxRight) {
                        firstHiddenElementBBox = elementBBox;
                    } else {
                        break;
                    }
                }
                if (typeof firstHiddenElementBBox === 'undefined') {
                    scrollBy = defaultScrollBy;
                } else {
                    scrollBy =
                        this.state.width -
                        (parentBBox.right - firstHiddenElementBBox.left) -
                        scrollHeaderSize * 2;
                }
        }
        return scrollBy;
    };

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            leftScrollHeaderVisibility: false,
            rightScrollHeaderVisibility: false,
        };
        window.addEventListener('resize', this.updateDropdownWidth);
    }

    public componentDidMount() {
        this.contentRef.current.addEventListener(
            'scroll',
            this.updateScrollHeaderVisibility,
        );
        window.addEventListener('resize', this.updateDropdownWidth);

        this.updateDropdownWidth();
        this.updateScrollHeaderVisibility();

        this.props.stores.appStore.designer.isJsonEditorVisible = false;
        this.contentRef.current.scrollLeft = 0;
    }

    public componentWillUnmount() {
        this.contentRef.current.removeEventListener(
            'scroll',
            this.updateScrollHeaderVisibility,
        );
        window.removeEventListener('resize', this.updateDropdownWidth);
    }

    public componentDidUpdate() {
        this.updateScrollHeaderVisibility();
    }

    public updateDropdownWidth = () => {
        if (window.innerWidth <= 700) {
            this.setState({ width: window.innerWidth - 160 });
        } else {
            const editorWrapper = document.getElementById('editorWrapper');
            if (editorWrapper !== null) {
                this.setState({ width: editorWrapper.offsetWidth });
            }
        }
    };

    public render() {
        // Add vertical navigation
        return (
            <div
                id="designerToolsDropdownContent"
                className="designerToolsDropdownContent"
                style={{ width: this.state.width }}
                ref={this.contentRef}
            >
                {this.state.leftScrollHeaderVisibility && (
                    <div
                        onPointerDown={() => this.scroll(Side.LEFT)}
                        className="dropDownScrollHead left"
                    >
                        <span className="amd-icon amd-bold-icon amd-icon-chevronLeft"></span>
                    </div>
                )}
                <div
                    ref={this.wrapperRef}
                    className="designerToolsDropdownContentWrapper"
                >
                    <DesignerToolsDropdownItems
                        selectedCategory={this.props.selectedCategory}
                        stores={this.props.stores}
                    />
                </div>
                {this.state.rightScrollHeaderVisibility && (
                    <div
                        onPointerDown={() => this.scroll(Side.RIGHT)}
                        className="dropDownScrollHead right"
                    >
                        <span className="amd-icon amd-bold-icon amd-icon-chevronRight"></span>
                    </div>
                )}
            </div>
        );
    }
}

type DropdownItemsProps = {
    selectedCategory: ToolCategory;
    stores: AppStoreProps;
};

export class DesignerToolsDropdownItems extends React.Component<DropdownItemsProps> {
    public render() {
        const items = [];
        const categoryDetails = ToolsMap[this.props.selectedCategory];
        for (const i in categoryDetails.items) {
            const item = categoryDetails.items[i];
            const ariaLabel = `${item.name} element: drag and drop to insert the element to the canvas`;
            const id = i + 'Id';
            items.push(
                <div
                    ref={(ele) => {
                        if (ele !== null) {
                            const inventoryItem = new item.inventoryType(
                                ele,
                                item.type,
                            );
                            this.props.stores.appStore.designer.addInventoryItem(
                                inventoryItem,
                            );
                        }
                    }}
                    key={i}
                    id={id}
                    className="designerTool"
                    aria-label={ariaLabel}
                    tabIndex={0}
                    role="menuitem"
                >
                    <div className="toolIconName">
                        <div>{item.name}</div>
                        {item.subText ? (
                            <div className="toolIconSubText">
                                ({item.subText})
                            </div>
                        ) : null}
                    </div>
                    <img
                        className="toolIconImage"
                        style={{ width: item.width }}
                        src={item.url}
                        alt=""
                    ></img>
                </div>,
            );
        }
        return items;
    }
}
