import CardObjectPeer from './CardObjectPeer';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';
import {
    HorizontalAlignment,
    CardElement,
    CardElementContainer,
    CardObject,
    Container
} from 'adaptivecards';
import EnumPropertyEditor from '../PropertyEditor/EnumPropertyEditor';
import CardDesignerSurface from '../CardDesignerSurface';
import DesignerPeer from './DesignerPeer';
import DesignerPeerRegistrationBase from '../DesignerPeerRegistrationBase';
import { Rect } from '../Miscellaneous';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import { Spacing } from './DesignerPeersUtils';

export default class CardElementPeer extends CardObjectPeer {
    static readonly idProperty = new StringPropertyEditor(
        Versions.v1_0,
        'id',
        'Element ID',
        'Assign an ID to this element if you need to refer to this specific element in the card (for toggling visibility or other interactions).'
    );
    static readonly isVisibleProperty = new BooleanPropertyEditor(
        Versions.v1_2,
        'isVisible',
        'Initially visible',
        "Turning this property off will hide the element by default. This property can be used to define the element's default state if you want to toggle the visibility of the element. "
    );
    static readonly spacingProperty = new EnumPropertyEditor(
        Versions.v1_0,
        'spacing',
        'Spacing',
        Spacing,
        'Defines the Spacing above this element.'
    );
    static readonly separatorProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'separator',
        'Separator',
        'This will insert a separator above the element.'
    );
    static readonly horizontalAlignmentProperty = new EnumPropertyEditor(
        Versions.v1_0,
        'horizontalAlignment',
        'Horizontal alignment',
        HorizontalAlignment,
        'Defines the horizontal positioning of this element within its parent container.'
    );

    protected _cardElement: CardElement;

    protected insertElementAfter(newElement: CardElement) {
        if (this.cardElement.parent instanceof Container) {
            this.cardElement.parent.insertItemAfter(
                newElement,
                this.cardElement
            );

            var newPeer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                newElement
            );

            this.peerAdded(newPeer);
        }
    }

    protected internalRemove(): boolean {
        return this.cardElement.remove();
    }

    protected internalUpdateCssStyles() {
        super.internalUpdateCssStyles();

        if (this.cardElement.isVisible) {
            this.renderedElement.classList.remove('invisible');
        } else {
            this.renderedElement.classList.add('invisible');
        }
    }

    constructor(
        parent: DesignerPeer,
        designerSurface: CardDesignerSurface,
        registration: DesignerPeerRegistrationBase,
        cardElement: CardElement
    ) {
        super(parent, designerSurface, registration);

        this._cardElement = cardElement;

        if (cardElement instanceof CardElementContainer) {
            for (let i = 0; i < cardElement.getItemCount(); i++) {
                this.insertChild(
                    CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                        this.designerSurface,
                        this,
                        cardElement.getItemAt(i)
                    )
                );
            }
        }

        for (let i = 0; i < this.cardElement.getActionCount(); i++) {
            this.insertChild(
                CardDesignerSurface.actionPeerRegistry.createPeerInstance(
                    this.designerSurface,
                    this,
                    cardElement.getActionAt(i)
                )
            );
        }
    }

    renderDragVisual(): HTMLElement {
        const element = this._cardElement.renderedElement.cloneNode(
            true
        ) as HTMLElement;
        const box = this._cardElement.renderedElement.getBoundingClientRect();
        element.style.width = box.width + 'px';
        element.style.height = box.height + 'px';
        const visual = document.createElement('div');
        const overlay = document.createElement('div');
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.position = 'absolute';
        visual.appendChild(overlay);
        visual.appendChild(element);
        return visual;
    }

    getTreeItemText(): string {
        let text = super.getTreeItemText();

        if (this.cardElement.isVisible) {
            return text;
        } else {
            let result = 'Hidden';

            if (text) {
                result += ' - ' + text;
            }

            return result;
        }
    }

    getCardObject(): CardObject {
        return this.cardElement;
    }

    initializeCardElement() {
        // Do nothing in base implementation
    }

    getBoundingRect(): Rect {
        let designSurfaceOffset = this.designerSurface.getDesignerSurfaceOffset();
        let cardElementBoundingRect = this.cardElement.renderedElement.getBoundingClientRect();

        if (this.cardElement.hasVisibleSeparator) {
            let separatorBoundingRect = this.cardElement.separatorElement.getBoundingClientRect();

            return new Rect(
                Math.min(
                    separatorBoundingRect.top,
                    cardElementBoundingRect.top
                ) - designSurfaceOffset.y,
                Math.max(
                    separatorBoundingRect.right,
                    cardElementBoundingRect.right
                ) - designSurfaceOffset.x,
                Math.max(
                    separatorBoundingRect.bottom,
                    cardElementBoundingRect.bottom
                ) - designSurfaceOffset.y,
                Math.min(
                    separatorBoundingRect.left,
                    cardElementBoundingRect.left
                ) - designSurfaceOffset.x
            );
        } else {
            return new Rect(
                cardElementBoundingRect.top - designSurfaceOffset.y,
                cardElementBoundingRect.right - designSurfaceOffset.x,
                cardElementBoundingRect.bottom - designSurfaceOffset.y,
                cardElementBoundingRect.left - designSurfaceOffset.x
            );
        }
    }

    getCardObjectBoundingRect(): Rect {
        let cardElementBoundingRect = this.cardElement.renderedElement.getBoundingClientRect();

        return new Rect(
            cardElementBoundingRect.top,
            cardElementBoundingRect.right,
            cardElementBoundingRect.bottom,
            cardElementBoundingRect.left
        );
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        super.populatePropertySheet(propertySheet, defaultCategory);
    }

    getItemIndex(): number {
        return this.cardElement.index;
    }

    get cardElement(): CardElement {
        return this._cardElement;
    }
}
