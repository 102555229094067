import ElementInventoryItem from './ElementInventoryItem';
import CardDesignerSurface from '../ACDesigner/CardDesignerSurface';
import CardElementPeer from '../ACDesigner/DesignerPeers/CardElementPeer';
import { generateUUID } from '../utilities/Utils';
import {
    ImageSet,
    ITypeRegistration,
    CardElement,
    Image,
    Size
} from 'adaptivecards';
import {
    findElementTypeRegistration,
    PlaceholderImageUrl
} from './InventoryUtil';

export default class ImageSetInventoryItem extends ElementInventoryItem {
    private childTypeName: string = 'Image';

    private childTypeRegistration: ITypeRegistration<CardElement>;

    constructor(element: HTMLElement, typeName: string) {
        super(element, typeName);
        this.childTypeRegistration = findElementTypeRegistration(
            this.childTypeName
        );
    }

    createPeer(designer: CardDesignerSurface): CardElementPeer {
        const image1 = this.childTypeRegistration.createInstance() as Image;
        image1.id = generateUUID();
        image1.url = PlaceholderImageUrl;
        image1.size = Size.Medium;

        const image2 = this.childTypeRegistration.createInstance() as Image;
        image2.id = generateUUID();
        image2.url = PlaceholderImageUrl;
        image2.size = Size.Medium;

        const imageSet = this.typeRegistration.createInstance() as ImageSet;
        imageSet.id = generateUUID();
        imageSet.addImage(image1);
        imageSet.addImage(image2);
        const peer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
            designer,
            null,
            imageSet
        );
        peer.initializeCardElement();

        return peer;
    }
}
