import React from 'react';
import { Callout, DefaultButton, IconButton } from '@fluentui/react';
import './Guide.css';
import { AppStoreProps } from '../stores/StoreProps';

interface GuideState {
    loadSampleCalloutVisible: boolean;
    toolsCalloutVisible: boolean;
    jsonCalloutVisible: boolean;
    previewCalloutVisible: boolean;
}

interface GuideProps {
    stores: AppStoreProps;
    parentCallback?(isGuideOpen: boolean): void;
}

export class Guide extends React.Component<GuideProps, GuideState> {
    constructor(props) {
        super(props);
        this.state = {
            loadSampleCalloutVisible:
                this.props.stores.appStore.currentCard === 'blankCard',
            toolsCalloutVisible:
                this.props.stores.appStore.currentCard !== 'blankCard',
            jsonCalloutVisible: false,
            previewCalloutVisible: false,
        };
    }

    loadSampleGuide = (previousCallout: boolean, nextCallout: boolean) => {
        this.setState({
            loadSampleCalloutVisible: previousCallout,
            jsonCalloutVisible: nextCallout,
        });
        if (!previousCallout && !nextCallout) this.props.parentCallback(false);
    };

    loadToolsGuide = (previousCallout: boolean, nextCallout: boolean) => {
        this.setState({
            toolsCalloutVisible: previousCallout,
            jsonCalloutVisible: nextCallout,
        });
        if (!previousCallout && !nextCallout) this.props.parentCallback(false);
    };

    loadJsonGuide = (previousCallout: boolean, nextCallout: boolean) => {
        this.setState({
            jsonCalloutVisible: previousCallout,
            previewCalloutVisible: nextCallout,
        });
        if (!previousCallout && !nextCallout) this.props.parentCallback(false);
    };

    loadPreviewGuide = (previousCallout: boolean) => {
        this.setState({
            previewCalloutVisible: previousCallout,
        });
        if (!previousCallout) this.props.parentCallback(false);
    };

    public render() {
        return this.state.loadSampleCalloutVisible ? (
            <LoadSampleGuide
                isCalloutVisible={this.state.loadSampleCalloutVisible}
                parentCallback={this.loadSampleGuide}
            />
        ) : this.state.toolsCalloutVisible ? (
            <ToolsGuide
                isCalloutVisible={this.state.toolsCalloutVisible}
                parentCallback={this.loadToolsGuide}
            />
        ) : this.state.jsonCalloutVisible ? (
            <JsonGuide
                isCalloutVisible={this.state.jsonCalloutVisible}
                parentCallback={this.loadJsonGuide}
            />
        ) : this.state.previewCalloutVisible ? (
            <PreviewGuide
                isCalloutVisible={this.state.previewCalloutVisible}
                parentCallback={this.loadPreviewGuide}
            />
        ) : null;
    }
}

interface CloseIconProps {
    onClickHandler(): void;
}

function CloseIcon(props: CloseIconProps) {
    const closeClassName = 'acd-icon guide-callout-close-button';
    return (
        <IconButton
            onClick={props.onClickHandler}
            iconProps={{ iconName: 'ChromeClose' }}
            style={{
                float: 'right',
                cursor: 'pointer',
                margin: '8px 8px 0 0',
            }}
            className={closeClassName}
            title="Close"
            ariaLabel="Close"
            checked={true}
            styles={{
                icon: { color: 'white', fontSize: 12 },
                root: {
                    width: 14,
                    height: 14,
                    background: '#0078d4',
                    selectors: {
                        ':hover .ms-Button-icon': {
                            color: 'white',
                            background: '#0078d4',
                        },
                        ':active .ms-Button-icon': {
                            color: 'white',
                            background: '#0078d4',
                        },
                    },
                },
            }}
        />
    );
}

interface GuideContentProps {
    onCloseHandler(): void;
    onNextHandler(): void;
    heading: string;
    body: string;
    pageNo: number;
    nextButtonName?: string;
}
function GuideContent(props: GuideContentProps) {
    const nextButtonName = props.nextButtonName ? props.nextButtonName : 'Next';
    return (
        <div tabIndex={0} className="flexRow guide-callout-wrapper">
            <div className="flexColumn guide-callout-content-wrapper">
                <div className="guide-callout-heading">{props.heading}</div>
                <div className="guide-callout-text">{props.body}</div>
                <div className="flexRow guide-callout-footer">
                    <DefaultButton
                        text={nextButtonName}
                        onClick={props.onNextHandler}
                        className="guide-callout-next"
                    />
                    <span>{props.pageNo} of 3</span>
                </div>
            </div>
            <div className="flexRow">
                <CloseIcon onClickHandler={props.onCloseHandler} />
            </div>
        </div>
    );
}

interface CalloutState {
    target: HTMLElement;
}

interface CalloutProps {
    isCalloutVisible: boolean;
    parentCallback(currentCallout: boolean, nextCallout?: boolean): void;
}

export class LoadSampleGuide extends React.Component<
    CalloutProps,
    CalloutState
> {
    constructor(props) {
        super(props);
        this.state = {
            target: null,
        };
    }

    componentDidMount() {
        this.setState({
            target: document.getElementById('leftSideDesignerTools'),
        });
    }

    private hideCallout = () => {
        this.sendData(false, false);
    };

    private nextCallout = () => {
        this.sendData(false, true);
    };

    sendData = (
        currentCalloutVisible: boolean,
        nextCalloutVisible: boolean,
    ) => {
        this.props.parentCallback(currentCalloutVisible, nextCalloutVisible);
    };

    public render() {
        return (
            <Callout
                directionalHint={5}
                target={this.state.target}
                setInitialFocus={true}
                hidden={!this.props.isCalloutVisible}
                beakWidth={10}
                preventDismissOnLostFocus={true}
                styles={{ beak: { backgroundColor: '#0078d4' } }}
            >
                <GuideContent
                    onCloseHandler={this.hideCallout}
                    heading="Start with a sample card and customize it to make it
                            your own"
                    body="Choose from a list of well crafted card samples for
                            various scenarios and edit it as you need."
                    onNextHandler={this.nextCallout}
                    pageNo={1}
                />
            </Callout>
        );
    }
}

export class ToolsGuide extends React.Component<CalloutProps, CalloutState> {
    constructor(props) {
        super(props);
        this.state = {
            target: null,
        };
    }

    componentDidMount() {
        this.setState({
            target: document.getElementById('designerToolsWrapper'),
        });
    }

    private hideCallout = () => {
        this.sendData(false, false);
    };

    private nextCallout = () => {
        this.sendData(false, true);
    };

    sendData = (
        currentCalloutVisible: boolean,
        nextCalloutVisible: boolean,
    ) => {
        this.props.parentCallback(currentCalloutVisible, nextCalloutVisible);
    };

    public render() {
        return (
            <Callout
                directionalHint={5}
                target={this.state.target}
                setInitialFocus={true}
                hidden={!this.props.isCalloutVisible}
                beakWidth={10}
                preventDismissOnLostFocus={true}
                styles={{ beak: { backgroundColor: '#0078d4' } }}
            >
                <GuideContent
                    onCloseHandler={this.hideCallout}
                    heading="Drag and drop elements to your card"
                    body="Add elements to the card canvas below, from this
                            inventory. Simply drag and drop what you need into
                            the canvas and place it as you like."
                    onNextHandler={this.nextCallout}
                    pageNo={1}
                />
            </Callout>
        );
    }
}

export class JsonGuide extends React.Component<CalloutProps, CalloutState> {
    constructor(props) {
        super(props);
        this.state = {
            target: null,
        };
    }

    componentDidMount() {
        this.setState({ target: document.getElementById('jsonEditorHeader') });
    }

    private hideCallout = () => {
        this.sendData(false, false);
    };

    private nextCallout = () => {
        this.sendData(false, true);
    };

    sendData = (
        currentCalloutVisible: boolean,
        nextCalloutVisible: boolean,
    ) => {
        this.props.parentCallback(currentCalloutVisible, nextCalloutVisible);
    };

    public render() {
        return (
            <Callout
                directionalHint={1}
                target={this.state.target}
                setInitialFocus={true}
                hidden={!this.props.isCalloutVisible}
                beakWidth={10}
                preventDismissOnLostFocus={true}
                styles={{ beak: { backgroundColor: '#0078d4' } }}
            >
                <GuideContent
                    onCloseHandler={this.hideCallout}
                    heading="Edit the JSON payload of your card here"
                    body="Edit your card by tweaking the JSON payload for your
                            card here if needed."
                    onNextHandler={this.nextCallout}
                    pageNo={2}
                />
            </Callout>
        );
    }
}

export class PreviewGuide extends React.Component<CalloutProps, CalloutState> {
    constructor(props) {
        super(props);
        this.state = {
            target: null,
        };
    }

    componentDidMount() {
        this.setState({ target: document.getElementById('previewButton') });
    }

    private hideCallout = () => {
        this.sendData(false);
    };

    private nextCallout = () => {
        this.sendData(false);
    };

    sendData = (currentCalloutVisible: boolean) => {
        this.props.parentCallback(currentCalloutVisible);
    };

    public render() {
        return (
            <Callout
                directionalHint={5}
                target={this.state.target}
                setInitialFocus={true}
                hidden={!this.props.isCalloutVisible}
                beakWidth={10}
                preventDismissOnLostFocus={true}
                styles={{ beak: { backgroundColor: '#0078d4' } }}
            >
                <GuideContent
                    onCloseHandler={this.hideCallout}
                    heading="Preview and Test your card"
                    body=" Preview your card to see what it would look like on
                            Outlook mobile as well as the larger screen. Send
                            the card to yourself to test it out."
                    nextButtonName="Done"
                    onNextHandler={this.nextCallout}
                    pageNo={3}
                />
            </Callout>
        );
    }
}
