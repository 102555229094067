import {
    CommandBarButton,
    DirectionalHint,
    IContextualMenuItem,
} from '@fluentui/react';

export type CardDesignerEditorProps = {
    onJsonEditorKeyDown: (event: KeyboardEvent) => void;
    onJsonEditorMouseClick: () => void;
    showEditorPaneOnMobile: () => void;
    showPropertyEditorOnMobile: () => void;
    showVisualTreeEditorOnMobile: () => void;
};

export const CardDesignerEditor = (props: CardDesignerEditorProps) => {
    const {
        onJsonEditorKeyDown,
        onJsonEditorMouseClick,
        showEditorPaneOnMobile,
        showPropertyEditorOnMobile,
        showVisualTreeEditorOnMobile,
    } = props;
    const items: IContextualMenuItem[] = [
        {
            key: 'visualTreeEditor',
            text: 'Visual Tree Editor',
            onClick: showVisualTreeEditorOnMobile,
        },
        {
            key: 'propertyEditor',
            text: 'Property Editor',
            onClick: showPropertyEditorOnMobile,
        },
        {
            key: 'jsonPayloadEditor',
            text: 'Edit JSON Payload',
            onClick: () => {
                showEditorPaneOnMobile();
                onJsonEditorMouseClick();
            },
        },
    ];
    return (
        <>
            <div
                tabIndex={0}
                role="button"
                id="editJsonSoloOption"
                className="editJsonSoloOption"
                onKeyDown={(e) => onJsonEditorKeyDown(e.nativeEvent)}
                onClick={onJsonEditorMouseClick}
            >
                <span>Edit JSON Payload</span>
                <span className="acd-icon acd-icon-chevronUpMed jsonEditorHeaderChevron" />
            </div>
            <CommandBarButton
                role="menuitem"
                id="openEditorOptionsWrapper"
                className="openEditorOptionsWrapper"
                onRenderMenuIcon={() => null}
                menuProps={{
                    items,
                    directionalHint: DirectionalHint.topCenter,
                }}
                onKeyDown={(e) => {
                    if (!e.shiftKey && e.key === 'Tab') {
                        /**
                         * Better to just stop it here, rather than looping around 3
                         * elements. Should implement a proper fix later.
                         */
                        e.preventDefault();
                    }
                }}
            >
                Open Editor
                <span className="acd-icon acd-icon-chevronUpMed jsonEditorHeaderChevron" />
            </CommandBarButton>
        </>
    );
};
