import TypedCardElementPeer from './TypedCardElementPeer';
import { addChildCommand } from './DesignerPeersUtils';
import CardDesignerSurface from '../CardDesignerSurface';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import ContainerPeer from './ContainerPeer';
import DesignerPeer from './DesignerPeer';
import AdaptiveCardPeer from './AdaptiveCardPeer';
import { Column, ColumnSet } from 'adaptivecards';
import ColumnPeer from './ColumnPeer';
import LinkPropertyEditor from '../PropertyEditor/LinkPropertyEditor';
import CardElementPeer from './CardElementPeer';

export default class ColumnSetPeer extends TypedCardElementPeer<ColumnSet> {
    protected isContainer(): boolean {
        return true;
    }

    static readonly columnsProperty = new LinkPropertyEditor(
        '*',
        '_columns',
        'Columns',
        'All the columns inserted in this column set appear here. You can add more columns with the button below.'
    );

    protected addCommands(commands: Array<addChildCommand>) {
        let command: addChildCommand = {
            name: '',
            subCommands: [],
            execute() {}
        };
        command.name = 'Add a column';
        command.execute = () => {
            var column = new Column();
            column.width = 'stretch';

            this.cardElement.addColumn(column);

            this.insertChild(
                CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                    this.designerSurface,
                    this,
                    column
                )
            );
            this.changed(true);
        };
        commands.push(command);
    }

    protected internalGetTreeItemText(): string {
        let columnCount = this.cardElement.getCount();

        switch (columnCount) {
            case 0:
                return 'No column';
            case 1:
                return '1 column';
            default:
                return columnCount + ' columns';
        }
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            CardElementPeer.idProperty,
            CardElementPeer.isVisibleProperty,
            ContainerPeer.styleProperty,
            AdaptiveCardPeer.paddingProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty,
            CardElementPeer.horizontalAlignmentProperty,
            ColumnSetPeer.columnsProperty
        );
    }

    canDrop(peer: DesignerPeer): boolean {
        if (
            !super.canDrop(peer) ||
            (peer instanceof ColumnSetPeer && peer.getChildCount() !== 1)
        ) {
            return false;
        }
        return true;
    }

    getPeerToInsert(peer: DesignerPeer) {
        let insertPeer: ColumnPeer = null;
        if (peer instanceof ColumnSetPeer && peer.getChildCount() === 1) {
            const columnPeer = peer.getChildAt(0) as ColumnPeer;
            if (!columnPeer.remove(true, false)) {
                return insertPeer;
            }
            peer.removeChild(columnPeer);
            insertPeer = columnPeer;
        } else if (peer instanceof ColumnPeer) {
            insertPeer = peer;
        }
        return insertPeer;
    }

    insertItemAfter(peer: DesignerPeer, refPeer: DesignerPeer) {
        const column = peer.getCardObject();
        const refColumn = refPeer ? refPeer.getCardObject() : null;
        if (column instanceof Column) {
            this.cardElement['insertItemAfter'](column, refColumn);
        }
    }

    insertItemBefore(peer: DesignerPeer, refPeer: DesignerPeer) {
        const column = peer.getCardObject();
        const refColumn = refPeer ? refPeer.getCardObject() : null;
        if (column instanceof Column) {
            this.cardElement['insertItemBefore'](column, refColumn);
        }
    }
}
