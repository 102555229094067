import TypedCardElementPeer from './TypedCardElementPeer';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import { Image } from 'adaptivecards';
import ImageSetPeer from './ImageSetPeer';
import { Rect } from '../Miscellaneous';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import ContainerPeer from './ContainerPeer';
import CardElementPeer from './CardElementPeer';
import CardDesignerSurface from '../CardDesignerSurface';
import DesignerPeer from './DesignerPeer';
import SubPropertySheetEntry from '../PropertyEditor/SubPropertySheetEntry';
import { ImageSize } from './DesignerPeersUtils';
import ImageSizePropertyEditor from '../PropertyEditor/ImageSizePropertyEditor';
import BooleanNumberPropertyEditor from '../PropertyEditor/BooleanNumberPropertyEditor';

export default class ImagePeer extends TypedCardElementPeer<Image> {
    static readonly urlProperty = new StringPropertyEditor(
        Versions.v1_0,
        'url',
        'Image URL',
        'Insert the url for the image you want to use. '
    );
    static readonly altTextProperty = new StringPropertyEditor(
        Versions.v1_0,
        'altText',
        'Alternate text',
        'Assign a text description to this image for accesibility reasons. '
    );
    static readonly sizeProperty = new ImageSizePropertyEditor(
        Versions.v1_0,
        'size',
        'Image Size',
        ImageSize,
        'Choose the size for your image.'
    );
    static readonly avatarProperty = new BooleanNumberPropertyEditor(
        Versions.v1_0,
        'style',
        'Avatar',
        'Turning this property on which give the image rounded corners. '
    );

    private get isParentImageSet(): boolean {
        return this.parent && this.parent instanceof ImageSetPeer;
    }

    getBoundingRect(): Rect {
        if (this.isParentImageSet) {
            let designSurfaceOffset = this.designerSurface.getDesignerSurfaceOffset();
            let actionBoundingRect = this.cardElement.renderedElement.getBoundingClientRect();

            return new Rect(
                actionBoundingRect.top - designSurfaceOffset.y,
                actionBoundingRect.right - designSurfaceOffset.x,
                actionBoundingRect.bottom - designSurfaceOffset.y,
                actionBoundingRect.left - designSurfaceOffset.x
            );
        } else {
            return super.getBoundingRect();
        }
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        if (!this.isParentImageSet) {
            propertySheet.add(
                defaultCategory,
                CardElementPeer.idProperty,
                ImagePeer.urlProperty,
                ImagePeer.altTextProperty,
                ImagePeer.sizeProperty,
                ImagePeer.avatarProperty,
                CardElementPeer.spacingProperty,
                CardElementPeer.horizontalAlignmentProperty,
                ContainerPeer.selectActionProperty
            );

            if (this.cardElement.selectAction) {
                let selectActionPeer = CardDesignerSurface.actionPeerRegistry.createPeerInstance(
                    this.designerSurface,
                    null,
                    this.cardElement.selectAction
                );
                selectActionPeer.onChanged = (
                    sender: DesignerPeer,
                    updatePropertySheet: boolean
                ) => {
                    this.changed(updatePropertySheet);
                };

                let subPropertySheet = new PropertySheet();
                selectActionPeer.populatePropertySheet(
                    subPropertySheet,
                    PropertySheetCategory.SelectionAction
                );

                propertySheet.add(
                    PropertySheetCategory.SelectionAction,
                    new SubPropertySheetEntry(
                        Versions.v1_2,
                        this.cardElement.selectAction,
                        subPropertySheet
                    )
                );
            }
        } else {
            propertySheet.add(
                defaultCategory,
                ImagePeer.urlProperty,
                ImagePeer.altTextProperty
            );
        }
    }
}
