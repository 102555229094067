import './PrivacyFooter.css';

export function PrivacyFooter() {
    return (
        <footer className="privacyFooter">
            <p>
                <a
                    href="http://go.microsoft.com/fwlink/?LinkId=248681"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy and Cookies
                </a>
            </p>
            <p>
                <a
                    href="https://go.microsoft.com/fwlink/?linkid=2259814"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Consumer Health Privacy
                </a>
            </p>
            <p>© Microsoft 2024</p>
        </footer>
    );
}
