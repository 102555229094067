import {
    Action,
    CardElement,
    ITypeRegistration,
    AdaptiveCard
} from 'adaptivecards';
import placeholderImage from '../components/design/assets/image.png';

export const PlaceholderImageUrl: string = placeholderImage;

export const findActionTypeRegistration = (
    typeName: string
): ITypeRegistration<Action> => {
    const count = AdaptiveCard.actionTypeRegistry.getItemCount();
    for (let i = 0; i < count; i++) {
        const itemRegistry = AdaptiveCard.actionTypeRegistry.getItemAt(i);
        if (typeName === itemRegistry.typeName) {
            return itemRegistry;
        }
    }
    return null;
};

export const findElementTypeRegistration = (
    typeName: string
): ITypeRegistration<CardElement> => {
    const count = AdaptiveCard.elementTypeRegistry.getItemCount();
    for (let i = 0; i < count; i++) {
        const itemRegistry = AdaptiveCard.elementTypeRegistry.getItemAt(i);
        if (typeName === itemRegistry.typeName) {
            return itemRegistry;
        }
    }
    return null;
};
