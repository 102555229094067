import PropertySheetEntry from './PropertySheetEntry';
import PropertySheetContext from './PropertySheetContext';
import { TargetVersion } from '../Shared';

export default class CustomPropertySheetEntry extends PropertySheetEntry {
    render(context: PropertySheetContext): HTMLElement {
        if (this.onRender) {
            return this.onRender(context);
        }
        return undefined;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly onRender: (context: PropertySheetContext) => HTMLElement
    ) {
        super(targetVersion);
    }
}
