import React from 'react';
import { inject } from 'mobx-react';

import { ToolsMap, ToolCategory } from './DesignerToolsUtils';
import { AppStore } from '../../stores/AppStore';

import './DesignerTools.css';
import {
    ActionButton,
    CommandBarButton,
    DirectionalHint,
    Icon,
    IconButton,
    IContextualMenuItem,
    IContextualMenuListProps,
} from '@fluentui/react';
import { DesignerToolsDropdownItems } from './DesignerToolsDropdown';
import { AppStoreProps } from '../../stores/StoreProps';

type ToolsState = {
    selectedCategory: ToolCategory;
};

@inject('appStore')
export class DesignerToolsMobile extends React.Component<
    AppStoreProps,
    ToolsState
> {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: null,
        };
    }

    public render() {
        let items: IContextualMenuItem[] = [];
        for (const [key, value] of Object.entries(ToolsMap)) {
            const item: IContextualMenuItem = {
                key,
                text: value.name,
                iconProps: {
                    iconName: value.iconName,
                },
            };
            items.push(item);
        }
        return (
            <div className="designerToolsMobileContainer">
                <CommandBarButton
                    role="menuitem"
                    id="designerToolsWrapperMobile"
                    className="designerToolsWrapperMobile"
                    text="Add components"
                    disabled={this.props.appStore.isACv2DesignView}
                    onKeyDown={(e) => {
                        if (e.key === 'Tab' && !e.shiftKey) {
                            const userActionsOverflowMenu =
                                document.getElementById(
                                    'userActionsOverflowMenu',
                                );
                            if (userActionsOverflowMenu) {
                                userActionsOverflowMenu.focus();
                                e.preventDefault();
                            }
                        }
                    }}
                    menuProps={{
                        items,
                        directionalHint: DirectionalHint.bottomCenter,
                        onRenderMenuList: (props: IContextualMenuListProps) => {
                            return (
                                <div className="designerToolsContainerMobile">
                                    <DesginerToolsRenderer
                                        items={props.items}
                                        stores={{
                                            appStore: this.props.appStore,
                                        }}
                                        isACv2DesignView={
                                            this.props.appStore.isACv2DesignView
                                        }
                                    />
                                </div>
                            );
                        },
                    }}
                />
            </div>
        );
    }
}

type RendererProps = {
    items: IContextualMenuItem[];
    stores: AppStoreProps;
    isACv2DesignView: boolean;
};

type CategoryProps = {
    item: IContextualMenuItem;
    appStore: AppStore;
    tabIndex: number;
    onClickHandler: () => void;
};

const DesginerToolsRenderer = (props: RendererProps) => {
    const { items, stores, isACv2DesignView } = props;
    const [selectedCategory, setSelectedCategory] = React.useState<
        string | null
    >(null);

    return selectedCategory ? (
        <div>
            <IconButton
                iconProps={{ iconName: 'ChevronLeft' }}
                onClick={() => setSelectedCategory(null)}
            />
            <div className="selectedCategoryMenu">
                <DesignerToolsDropdownItems
                    selectedCategory={selectedCategory as ToolCategory}
                    stores={stores}
                />
            </div>
        </div>
    ) : (
        <div className="designerToolsCategoriesMobile">
            {items.map((item) => (
                <DesignerToolsCategory
                    key={item.key}
                    item={item}
                    tabIndex={isACv2DesignView ? -1 : 0}
                    onClickHandler={() => setSelectedCategory(item.key)}
                    appStore={props.stores.appStore}
                />
            ))}
        </div>
    );
};

const DesignerToolsCategory = (props: CategoryProps) => {
    const { item, onClickHandler, tabIndex } = props;
    const iconName = item.iconProps ? item.iconProps.iconName : '';
    return (
        <ActionButton
            role="menuitem"
            iconProps={item.iconProps}
            onClick={onClickHandler}
            tabIndex={tabIndex}
            onRenderIcon={() => (
                <span className={'acd-icon categoryIcon ' + iconName}></span>
            )}
            onRenderChildren={() => (
                <div className="toolsCategoryMobile">
                    <span className="categoryName">{item.text}</span>
                    <Icon
                        className="catagorySelectChevron"
                        iconName="ChevronRight"
                    />
                </div>
            )}
        ></ActionButton>
    );
};
