import PropertySheetContext from './PropertySheetContext';
import { TargetVersion } from '../Shared';
import PropertySheet from './PropertySheet';

export default class SubPropertySheetEntry {
    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');
        container.style.paddingLeft = '16px';
        this.propertySheet.render(
            container,
            new PropertySheetContext(
                context.targetVersion,
                context.peer,
                this.target
            )
        );
        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly target: object,
        readonly propertySheet: PropertySheet
    ) {}
}
