import DraggableElement from './DraggableElement';

export default abstract class BaseTreeItem extends DraggableElement {
    private static collapsedIconClass = 'acd-icon-chevronRight';
    private static expandedIconClass = 'acd-icon-chevronDown';

    private _isExpanded: boolean = true;
    private _isSelected: boolean = false;
    private _treeItemElement: HTMLElement;
    private _textElement: HTMLElement;
    private _expandCollapseElement: HTMLElement;
    private _childContainerElement: HTMLElement;

    private expandCollapse = (e, doExpand?: boolean) => {
        this._isExpanded =
            typeof doExpand !== 'undefined' ? doExpand : !this._isExpanded;

        this.updateLayout();

        e.cancelBubble = true;
        e.preventDefault();
    };

    protected abstract getLabelText(): string;

    protected pointerDown(e: PointerEvent) {
        super.pointerDown(e);

        this.selected();
    }

    protected keyDown = (e: KeyboardEvent) => {
        if (e.key === ' ' || e.key === 'Enter') {
            this.selected();
            e.preventDefault();
            e.stopPropagation();
        } else if (this.isSelected) {
            if (e.key === 'ArrowLeft') {
                this.expandCollapse(e, false);
            } else if (e.key === 'ArrowRight') {
                this.expandCollapse(e, true);
            } else if (e.key === 'ArrowUp') {
                this.selectPreviousItem(e);
            } else if (e.key === 'ArrowDown') {
                this.selectNextItem(e);
            }
        }
    };

    protected getIconClass(): string {
        return null;
    }

    protected getAdditionalText(): string {
        return null;
    }

    protected getAdditionalTextClass(): string {
        return 'acd-tree-item-additionalText';
    }

    protected getIndentationLevelIncrement(): number {
        return 8;
    }

    protected getDragSourceElement(): HTMLElement {
        return this._treeItemElement;
    }

    protected selected() {
        // Do nothing in base implementation
    }

    protected internalRender(): HTMLElement {
        let rootElement = document.createElement('div');

        this._treeItemElement = document.createElement('div');
        this._treeItemElement.classList.add('acd-tree-item');
        this._treeItemElement.style.display = 'flex';
        this._treeItemElement.style.alignItems = 'center';
        this._treeItemElement.tabIndex = 0;
        this._treeItemElement.style.paddingLeft =
            this.getIndentationLevelIncrement() * (this.level - 1) + 'px';

        this._expandCollapseElement = document.createElement('div');
        this._expandCollapseElement.classList.add(
            'acd-tree-item-expandCollapseButton',
        );
        this._expandCollapseElement.style.flex = '0 0 auto';
        this._expandCollapseElement.style.visibility =
            this.getChildCount() > 0 ? 'visible' : 'hidden';
        this._expandCollapseElement.onpointerdown = this.expandCollapse;

        this._treeItemElement.appendChild(this._expandCollapseElement);
        this._treeItemElement.onkeydown = this.keyDown;

        this._textElement = document.createElement('div');
        this._textElement.classList.add('acd-tree-item-text');
        this._textElement.style.flex = '1 1 auto';
        this._textElement.style.display = 'flex';
        this._textElement.style.alignItems = 'center';
        this._textElement.style.whiteSpace = 'nowrap';
        this._textElement.style.textOverflow = 'ellipsis';
        this._textElement.tabIndex = -1;

        if (this.getIconClass()) {
            let iconElement = document.createElement('div');
            iconElement.classList.add(
                'acd-icon',
                'acd-black-icon',
                this.getIconClass(),
            );

            this._textElement.appendChild(iconElement);
        }

        let labelSpan = document.createElement('span');
        labelSpan.classList.add('acd-tree-item-typeName');
        labelSpan.innerText = this.getLabelText();

        this._textElement.appendChild(labelSpan);

        let text = this.getAdditionalText();

        if (text && text !== '') {
            let additionalTextSpan = document.createElement('span');
            additionalTextSpan.classList.add(this.getAdditionalTextClass());
            additionalTextSpan.innerText = ' [' + text + ']';

            this._textElement.appendChild(additionalTextSpan);
        }

        this._treeItemElement.appendChild(this._textElement);

        rootElement.appendChild(this._treeItemElement);

        this._childContainerElement = document.createElement('div');

        for (let i = 0; i < this.getChildCount(); i++) {
            let renderedChildItem = this.getChildAt(i).render();

            this._childContainerElement.appendChild(renderedChildItem);
        }

        rootElement.appendChild(this._childContainerElement);

        this.updateLayout();

        return rootElement;
    }

    protected _level: number = 0;

    abstract getChildCount(): number;
    abstract getChildAt(index: number): BaseTreeItem;
    abstract selectNextItem(e: KeyboardEvent): void;
    abstract selectPreviousItem(e: KeyboardEvent): void;

    renderDragVisual() {
        const treeItemElement = document.createElement('div');
        treeItemElement.classList.add('acd-tree-item', 'dragged');
        treeItemElement.style.display = 'flex';
        treeItemElement.style.alignItems = 'center';
        treeItemElement.style.backgroundColor = '#fff';
        let textElement = document.createElement('div');
        textElement.classList.add('acd-tree-item-text');
        textElement.style.flex = '1 1 auto';
        textElement.style.display = 'flex';
        textElement.style.alignItems = 'center';
        textElement.style.whiteSpace = 'nowrap';
        textElement.style.textOverflow = 'ellipsis';

        if (this.getIconClass()) {
            let iconElement = document.createElement('div');
            iconElement.classList.add(
                'acd-icon',
                'acd-black-icon',
                this.getIconClass(),
            );

            textElement.appendChild(iconElement);
        }

        let labelSpan = document.createElement('span');
        labelSpan.classList.add('acd-tree-item-typeName');
        labelSpan.innerText = this.getLabelText();

        textElement.appendChild(labelSpan);

        treeItemElement.appendChild(textElement);
        return treeItemElement;
    }

    updateLayout() {
        if (this._isExpanded) {
            this._childContainerElement.classList.remove('acd-hidden');
            this._expandCollapseElement.classList.remove(
                BaseTreeItem.collapsedIconClass,
            );
            this._expandCollapseElement.classList.add(
                BaseTreeItem.expandedIconClass,
            );
        } else {
            this._childContainerElement.classList.add('acd-hidden');
            this._expandCollapseElement.classList.add(
                BaseTreeItem.collapsedIconClass,
            );
            this._expandCollapseElement.classList.remove(
                BaseTreeItem.expandedIconClass,
            );
        }
    }

    expand() {
        this._isExpanded = true;

        this.updateLayout();
    }

    isDraggable(): boolean {
        return true;
    }

    get level(): number {
        return this._level;
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(value: boolean) {
        this._isSelected = value;

        if (this._isSelected) {
            this._treeItemElement.classList.add('selected');
        } else {
            this._treeItemElement.classList.remove('selected');
        }
    }

    get isExpanded(): boolean {
        return this._isExpanded;
    }

    get treeItemElement(): HTMLElement {
        return this._treeItemElement;
    }

    get textElement(): HTMLElement {
        return this._textElement;
    }

    get indentationLevelIncrement(): number {
        return this.getIndentationLevelIncrement();
    }
}
