import InputPeer from './InputPeer';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CustomToggleInput from '../../components/ActionableMessages/CustomToggleInput';
import BooleanStringPropertyEditor from '../PropertyEditor/BooleanStringPropertyEditor';
import CardElementPeer from './CardElementPeer';

export default class ToggleInputPeer extends InputPeer<CustomToggleInput> {
    static readonly titleProperty = new StringPropertyEditor(
        Versions.v1_0,
        'title',
        'Text',
        'Insert your text here.'
    );
    static readonly wrapProperty = new BooleanPropertyEditor(
        Versions.v1_2,
        'wrap',
        'Wrap text',
        "Turning on this property will wrap the text to the next line when there isn't enough space. If turned off, your text might get truncated. "
    );

    static readonly defaultStateProperty = new BooleanStringPropertyEditor(
        Versions.v1_0,
        'defaultValue',
        'Checked by Default',
        'Choose the default state of this check box. '
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            InputPeer.idProperty,
            InputPeer.isVisibleProperty,
            ToggleInputPeer.titleProperty,
            ToggleInputPeer.wrapProperty,
            ToggleInputPeer.defaultStateProperty,
            InputPeer.isRequiredProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty
        );
    }

    initializeCardElement() {
        this.cardElement.title = 'Insert your text here';
    }
}
