import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';

interface DropdownState {
    selectedItem: string | number;
}

interface DropdownProps {
    options: [];
    selectCallback(item: string | number): void;
    defaultValue: string | number;
    ariaLabel: string;
}

export default class CustomDropdown extends React.Component<
    DropdownProps,
    DropdownState
> {
    constructor(props) {
        super(props);
        this.state = { selectedItem: this.props.defaultValue };
    }

    public render() {
        return (
            <Dropdown
                selectedKey={this.state.selectedItem.toString()}
                onChange={this.onChange}
                placeholder="Select an option"
                options={this.props.options}
                ariaLabel={this.props.ariaLabel}
            />
        );
    }

    private onChange = (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption,
    ): void => {
        this.setState({ selectedItem: item.key });
        this.props.selectCallback(item.key);
    };
}
