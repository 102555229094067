import { action, observable, computed } from 'mobx';

import {
    AuthenticationResult,
    InteractionRequiredAuthError,
    PopupRequest,
    PublicClientApplication,
    SilentRequest,
    SsoSilentRequest,
} from '@azure/msal-browser';
import type { User } from 'microsoft-graph';

import { debugConfig, prodConfig } from '../Config';
import { GRAPH_URL } from '../utilities/constants';
import { AWTLogManager, AWTEventPriority } from '@aria/webjs-sdk';
import { SIGNIN_ERROR } from '../utilities/LoggingEventNames';

const config = process.env.NODE_ENV === 'production' ? prodConfig : debugConfig;

const graphScopes = ['User.Read'];

export class AuthStore {
    private static storeInstance: AuthStore;

    @observable
    public isLoggedIn: boolean = false;

    @observable
    public loginInProgress: boolean = false;

    @observable
    public userProfile: User = null;

    @observable
    public userPhoto: string = null;

    @computed
    public get userName() {
        if (this.isLoggedIn && this.userProfile) {
            return this.userProfile.displayName;
        } else {
            return null;
        }
    }

    @computed
    public get userEmail() {
        if (this.isLoggedIn && this.userProfile) {
            return this.userProfile.mail || this.userProfile.userPrincipalName;
        } else {
            return null;
        }
    }

    private applicationConfig = {
        clientID: config.appId,
        graphScopes: graphScopes,
    };

    private msalInstance = new PublicClientApplication({
        auth: {
            clientId: this.applicationConfig.clientID,
        },
    });

    private msalInstanceInitializationPromise;

    public static getStoreInstance(): AuthStore {
        if (!AuthStore.storeInstance) {
            AuthStore.storeInstance = new AuthStore();
        }
        return AuthStore.storeInstance;
    }

    private async getUserProfile(): Promise<void> {
        try {
            const accessToken = await this.getAccessToken(graphScopes);
            const response = await fetch(`${GRAPH_URL}/me`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw response;
            }
            const profile: User = await response.json();
            this.userProfile = profile;
        } catch (error) {
            // Unable to fetch profile details
            this.userProfile = null;
        }
    }

    private async getUserPhoto(): Promise<void> {
        try {
            const accessToken = await this.getAccessToken(graphScopes);
            const response = await fetch(`${GRAPH_URL}/me/photo/$value`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw response;
            }
            const photoBlob: Blob = await response.blob();
            this.userPhoto = URL.createObjectURL(photoBlob);
        } catch (error) {
            // Unable to fetch photo
            this.userPhoto = null;
        }
    }

    private async getAccessTokenUtil(scopes: string[]): Promise<string> {
        let account = this.msalInstance.getActiveAccount();
        if(!account) {
            const userAccounts = this.msalInstance.getAllAccounts();
            if(userAccounts.length > 0) {
                account = userAccounts[0];
            }
        }
        
        await this.msalInstanceInitializationPromise;
        try {
            if(!account) {
                throw new InteractionRequiredAuthError('No account found');
            }
            const tokenRequest: SilentRequest = {
                scopes,
                account,
            };
            const authResponse =
                await this.msalInstance.acquireTokenSilent(tokenRequest);
            if(!this.msalInstance.getActiveAccount()) {
                this.msalInstance.setActiveAccount(authResponse.account);
            }
            return authResponse.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    const authResponse: AuthenticationResult =
                        await this.msalInstance.acquireTokenPopup({
                            scopes,
                        });
                    this.msalInstance.setActiveAccount(authResponse.account);
                    return authResponse.accessToken;
                } catch (error) {
                    throw error;
                }
            } else {
                throw error;
            }
        }
    }

    private constructor() {
        this.loginInProgress = false;
        this.msalInstanceInitializationPromise = this.msalInstance.initialize();
        this.msalInstanceInitializationPromise.then(() => {
            const userAccounts = this.msalInstance.getAllAccounts();
            let silentTokenFetchPromise;
            if (userAccounts.length === 0) {
                const ssoSilentRequest: SsoSilentRequest = {
                    scopes: this.applicationConfig.graphScopes,
                };
                silentTokenFetchPromise = this.msalInstance.ssoSilent(ssoSilentRequest);
            } else if (userAccounts.length === 1) {
                const silentRequest: SilentRequest = {
                    scopes: this.applicationConfig.graphScopes,
                    account: userAccounts[0],
                };
                silentTokenFetchPromise = this.msalInstance.acquireTokenSilent(silentRequest);
            }
            silentTokenFetchPromise
                ?.then(
                    action((authResponse: AuthenticationResult) => {
                        this.msalInstance.setActiveAccount(authResponse.account);
                        this.isLoggedIn = true;
                        this.getUserProfile();
                        this.getUserPhoto();
                    }),
                )
                .catch(action(() => (this.isLoggedIn = false)))
                .finally(action(() => (this.loginInProgress = false)));
        });
    }

    @action
    public async signIn() {
        try {
            const tokenRequest: PopupRequest = {
                scopes: this.applicationConfig.graphScopes,
                prompt: 'select_account',
            };
            this.loginInProgress = true;
            await this.msalInstanceInitializationPromise;
            const authResponse: AuthenticationResult = await this.msalInstance.loginPopup(tokenRequest);

            this.isLoggedIn = true;
            this.msalInstance.setActiveAccount(authResponse.account);
            await this.getUserProfile();
            this.getUserPhoto();
        } catch (error) {
            // Login Failed or Error while fetching the user data
            AWTLogManager.getLogger().logEvent({
                name: SIGNIN_ERROR,
                priority: AWTEventPriority.Normal,
                properties: {
                    error: error.toString(),
                },
            });
        } finally {
            this.loginInProgress = false;
        }
    }

    @action
    public async signOut() {
        for (let i = localStorage.length - 1; i >= 0; i--) {
            if (!localStorage.key(i).startsWith('AMD.')) {
                localStorage.removeItem(localStorage.key(i));
            }
        }
        this.isLoggedIn = false;
        this.userProfile = null;
        this.userPhoto = null;
        try {
            const activeAccount = this.msalInstance.getActiveAccount();
            const logoutHint = activeAccount.idTokenClaims.login_hint;
            await this.msalInstance.logoutPopup({ logoutHint });
        } catch (error) {
            // Logout failed
        } finally {
            this.msalInstance.setActiveAccount(null);
        }
    }

    public async getAccessToken(scopes: string[]): Promise<string> {
        if (this.isLoggedIn) {
            return await this.getAccessTokenUtil(scopes);
        } else {
            try {
                const tokenRequest: PopupRequest = {
                    scopes,
                    prompt: 'select_account',
                };
                await this.msalInstanceInitializationPromise;
                const authResponse =
                    await this.msalInstance.loginPopup(tokenRequest);
                return authResponse.accessToken;
            } catch (error) {
                throw error;
            }
        }
    }
}
