export const generateUUID = (): string => {
    let uuid: string = '';
    for (let j = 0; j < 32; j++) {
        if (j === 8 || j === 12 || j === 16 || j === 20) {
            uuid += '-';
        }
        uuid += Math.floor(Math.random() * 16).toString(16);
    }
    return uuid;
};

export type KeyBoardToClickEventHandler = (event) => void;

export const keyBoardToClickEventMapper = (
    clickHandler: KeyBoardToClickEventHandler
): KeyBoardToClickEventHandler => {
    return (event: KeyboardEvent) => {
        if (event.key === ' ' || event.key === 'Enter') {
            return clickHandler(event);
        }
    };
};
