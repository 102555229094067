import { createRoot } from 'react-dom/client';

import App from './App';

import './index.css';

import { Provider } from 'mobx-react';
import { AuthStore } from './stores/AuthStore';
import { AppStore } from './stores/AppStore';
import { UserStore } from './stores/UserStore';
import { NotificationStore } from './stores/NotificationStore';

const mobxStores = {
    authStore: AuthStore.getStoreInstance(),
    appStore: AppStore.getStoreInstance(),
    userStore: UserStore.getStoreInstance(),
    notificationStore: NotificationStore.getStoreInstance(),
};

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);
root.render(
    <Provider {...mobxStores}>
        <App appStore={mobxStores.appStore} />
    </Provider>,
);
