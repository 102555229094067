import React from 'react';
import { createRoot } from 'react-dom/client';
import {
    onChangeCallbackType,
    keyValuePair,
    addChildCommand,
} from '../DesignerPeers/DesignerPeersUtils';
import CustomTextField from '../../components/CustomFabricUIElements/CustomTextField';
import CustomCheckbox from '../../components/CustomFabricUIElements/CustomCheckbox';
import CustomDropdown from '../../components/CustomFabricUIElements/CustomDropdown';
import {
    CustomButton,
    ContextualMenuButton,
} from '../../components/CustomFabricUIElements/CustomButtons';
import { CustomDatePicker } from '../../components/CustomFabricUIElements/CustomDatePicker';
import CustomTooltip from '../../components/CustomFabricUIElements/CustomTooltip';

export default class GeneratePropertyFields {
    generateLabels(name: string): HTMLElement {
        let labelElement: HTMLElement = document.createElement('div');
        labelElement.innerHTML = name;
        labelElement.classList.add('labelProperty');
        return labelElement;
    }

    generateLinks(name: string): HTMLElement {
        let linkElement: HTMLElement = document.createElement('div');
        linkElement.innerHTML = name;
        linkElement.classList.add('linkProperty');
        linkElement.style.cursor = 'pointer';
        return linkElement;
    }

    generateTextFields(
        multiline: boolean,
        placeholder: string,
        defaultValue: string,
        onChangeCallback: onChangeCallbackType,
    ): HTMLElement {
        let textFieldInput = React.createElement(CustomTextField, {
            placeholder: placeholder,
            defaultValue: defaultValue,
            onChangeCallback: onChangeCallback,
            multiline: multiline,
        });
        let container: HTMLElement = document.createElement('div');
        const containerRoot = createRoot(container);
        containerRoot.render(textFieldInput);
        return container;
    }

    generateTooltips(content: string): HTMLElement {
        let tooltip = React.createElement(CustomTooltip, { content: content });
        let container: HTMLElement = document.createElement('div');
        const containerRoot = createRoot(container);
        containerRoot.render(tooltip);
        container.classList.add('tooltipPropertyEditor');
        return container;
    }

    generateToggleInput(
        defaultValue: boolean,
        onChangeCallback: onChangeCallbackType,
    ): HTMLElement {
        let toggleInput = React.createElement(CustomCheckbox, {
            isChecked: defaultValue,
            isChangedCallback: onChangeCallback,
        });
        let container: HTMLElement = document.createElement('div');
        const containerRoot = createRoot(container);
        containerRoot.render(toggleInput);
        return container;
    }

    generateDropDown(
        entries: keyValuePair,
        onSelectCallback: onChangeCallbackType,
        defaultValue: string,
        ariaLabel: string,
    ): HTMLElement {
        var options = [];
        for (var key in entries) {
            let keyValuePair = {};
            keyValuePair['key'] = entries[key];
            keyValuePair['text'] = key;
            options.push(keyValuePair);
        }
        let dropdownInput = React.createElement(CustomDropdown, {
            options: options,
            selectCallback: onSelectCallback,
            defaultValue: defaultValue,
            ariaLabel: ariaLabel,
        });
        let container: HTMLElement = document.createElement('div');
        const containerRoot = createRoot(container);
        containerRoot.render(dropdownInput);
        return container;
    }

    generateDatePicker(
        defaultValue: string,
        onChangeCallback: onChangeCallbackType,
    ): HTMLElement {
        let datePicker = React.createElement(CustomDatePicker, {
            defaultValue: defaultValue,
            onChangeCallback: onChangeCallback,
        });
        let container: HTMLElement = document.createElement('div');
        const containerRoot = createRoot(container);
        containerRoot.render(datePicker);
        return container;
    }

    generateButton(
        defaultValue: string,
        onClickCallback: () => void,
    ): HTMLElement {
        let buttonInput = React.createElement(CustomButton, {
            defaultValue: defaultValue,
            onClickCallback: onClickCallback,
        });
        let container: HTMLElement = document.createElement('div');
        const containerRoot = createRoot(container);
        containerRoot.render(buttonInput);
        return container;
    }

    generateActionButton(addChildcommand: addChildCommand): HTMLElement {
        let buttonInput = React.createElement(ContextualMenuButton, {
            addChildCommand: addChildcommand,
        });
        let container: HTMLElement = document.createElement('div');
        const containerRoot = createRoot(container);
        containerRoot.render(buttonInput);
        return container;
    }
}
