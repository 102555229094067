import SingleInputPropertyEditor from './SingleInputPropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import {
    keyValuePair,
    notActionable
} from '../DesignerPeers/DesignerPeersUtils';
import GeneratePropertyFields from './GeneratePropertyFields';
import { TargetVersion } from '../Shared';
import { Action, AdaptiveCard } from 'adaptivecards';
import ActionTitle from '../../ActionTitle';

export default class ActionPropertyEditor extends SingleInputPropertyEditor {
    private noneValue: string = 'none';
    protected getPropertyValue(context: PropertySheetContext): any {
        let action = context.target[this.propertyName] as Action;

        return action ? action.getJsonTypeName() : this.noneValue;
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = parseInt(value, 10);

        if (value === this.noneValue) {
            context.target[this.propertyName] = null;
        } else {
            context.target[
                this.propertyName
            ] = AdaptiveCard.actionTypeRegistry.createInstance(value);
        }
    }

    protected createInput(
        context: PropertySheetContext,
        eventCallback
    ): HTMLElement {
        let entries: keyValuePair = {};
        entries[notActionable] = this.noneValue;
        for (
            var i = 0;
            i < AdaptiveCard.actionTypeRegistry.getItemCount();
            i++
        ) {
            let actionType = AdaptiveCard.actionTypeRegistry.getItemAt(i)
                .typeName;
            let doAddActionType = this.forbiddenActionTypes
                ? this.forbiddenActionTypes.indexOf(actionType) < 0
                : true;
            if (doAddActionType) {
                entries[
                    actionType.slice(7) +
                        ' (' +
                        ActionTitle.get(actionType) +
                        ')'
                ] = actionType;
            }
        }
        let input: HTMLElement = new GeneratePropertyFields().generateDropDown(
            entries,
            eventCallback,
            this.getPropertyValue(context),
            this.label
        );
        return input;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly forbiddenActionTypes: string[] = [],
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(
            targetVersion,
            propertyName,
            label,
            tooltipContent,
            causesPropertySheetRefresh
        );
    }
}
