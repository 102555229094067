import { Input } from 'adaptivecards';
import TypedCardElementPeer from './TypedCardElementPeer';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';
import { Versions } from '../Shared';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import EnumPropertyEditor from '../PropertyEditor/EnumPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import { Spacing } from './DesignerPeersUtils';

export default abstract class InputPeer<
    TInput extends Input
> extends TypedCardElementPeer<TInput> {
    static readonly isRequiredProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'isRequired',
        'Mandatory',
        'Turning this property on will make this response mandatory. As long as the input field stays empty, the user will not be able to submit the responses. '
    );
    static readonly defaultValueProperty = new StringPropertyEditor(
        Versions.v1_0,
        'defaultValue',
        'Prepopulated Content',
        'Insert text to be displayed by default here. This will replace the hint text if any.'
    );

    static readonly spacingProperty = new EnumPropertyEditor(
        Versions.v1_0,
        'spacing',
        'Spacing',
        Spacing,
        'Defines the Spacing above this element.'
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {}
}
