import TypedCardElementPeer from './TypedCardElementPeer';
import ActionPropertyEditor from '../PropertyEditor/ActionPropertyEditor';
import { Versions } from '../Shared';
import EnumPropertyEditor from '../PropertyEditor/EnumPropertyEditor';
import ContainerStylePropertyEditor from '../PropertyEditor/ContainerStylePropertyEditor';
import CompoundPropertyEditor from '../PropertyEditor/CompoundPropertyEditor';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CardDesignerSurface from '../CardDesignerSurface';
import DesignerPeer from './DesignerPeer';
import SubPropertySheetEntry from '../PropertyEditor/SubPropertySheetEntry';
import ActionPeer from './ActionPeer';
import { findElementTypeRegistration } from '../../inventory/InventoryUtil';
import {
    ActionSet,
    ColumnSet,
    Container,
    ShowCardAction,
    VerticalAlignment,
    HorizontalAlignment,
    CardElement
} from 'adaptivecards';
import AdaptiveCardPeer from './AdaptiveCardPeer';
import ColumnPeer from './ColumnPeer';
import { Fit } from './DesignerPeersUtils';
import CardElementPeer from './CardElementPeer';

export default class ContainerPeer extends TypedCardElementPeer<Container> {
    static readonly selectActionProperty = new ActionPropertyEditor(
        Versions.v1_0,
        'selectAction',
        'Assign an Action',
        [ShowCardAction.JsonTypeName],
        'Assign an action to this container to make it interactive. Define what happens on clicking this element - by selecting the appropriate type of action here.',
        true
    );
    static readonly verticalContentAlignmentProperty = new EnumPropertyEditor(
        Versions.v1_1,
        'verticalContentAlignment',
        'Vertical content alignment',
        VerticalAlignment,
        'Defines the vertical positioning of this element within its parent container.'
    );
    static readonly styleProperty = new ContainerStylePropertyEditor(
        Versions.v1_0,
        'style',
        'Container Colour',
        'Choose the colour of the container. Use the emphasis mode to highlight containers with specific content.'
    );
    static readonly backgroundImageProperty = new CompoundPropertyEditor(
        Versions.v1_0,
        'backgroundImage',
        'Background Image',
        [
            new StringPropertyEditor(
                Versions.v1_0,
                'url',
                'Image URL',
                'Insert the url for the image that will be used as the background. '
            ),
            new EnumPropertyEditor(
                Versions.v1_2,
                'fillMode',
                'Fit',
                Fit,
                'Select the way this image will fit the container. '
            ),
            new EnumPropertyEditor(
                Versions.v1_2,
                'horizontalAlignment',
                'Horizontal alignment',
                HorizontalAlignment,
                'Defines the horizontal positioning of this image in the container.'
            ),
            new EnumPropertyEditor(
                Versions.v1_2,
                'verticalAlignment',
                'Vertical alignment',
                VerticalAlignment,
                'Defines the vertical positioning of this image in the container.'
            )
        ]
    );

    protected isContainer(): boolean {
        return true;
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            CardElementPeer.idProperty,
            CardElementPeer.isVisibleProperty,
            ContainerPeer.styleProperty,
            AdaptiveCardPeer.paddingProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty,
            CardElementPeer.horizontalAlignmentProperty,
            ContainerPeer.verticalContentAlignmentProperty,
            ContainerPeer.backgroundImageProperty,
            ContainerPeer.selectActionProperty
        );

        if (this.cardElement.selectAction) {
            let selectActionPeer = CardDesignerSurface.actionPeerRegistry.createPeerInstance(
                this.designerSurface,
                null,
                this.cardElement.selectAction
            );
            selectActionPeer.onChanged = (
                sender: DesignerPeer,
                updatePropertySheet: boolean
            ) => {
                this.changed(updatePropertySheet);
            };

            let subPropertySheet = new PropertySheet();
            selectActionPeer.populatePropertySheet(
                subPropertySheet,
                PropertySheetCategory.SelectionAction
            );

            propertySheet.add(
                PropertySheetCategory.SelectionAction,
                new SubPropertySheetEntry(
                    Versions.v1_2,
                    this.cardElement.selectAction,
                    subPropertySheet
                )
            );
        }
    }

    getPeerToInsert(peer: DesignerPeer): DesignerPeer {
        let insertPeer: DesignerPeer = peer;
        if (peer instanceof ActionPeer) {
            const typeRegistration = findElementTypeRegistration('ActionSet');
            const actionSet: ActionSet = typeRegistration.createInstance() as ActionSet;
            actionSet.addAction(peer.action);
            insertPeer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                actionSet
            );
        } else if (peer instanceof ColumnPeer) {
            const typeRegistration = findElementTypeRegistration('ColumnSet');
            const columnSet: ColumnSet = typeRegistration.createInstance() as ColumnSet;
            columnSet.addColumn(peer.cardElement);
            insertPeer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                columnSet
            );
        }
        return insertPeer;
    }

    insertItemAfter(peer: DesignerPeer, refPeer: DesignerPeer) {
        const item = peer.getCardObject() as CardElement;
        const refItem = refPeer
            ? (refPeer.getCardObject() as CardElement)
            : null;
        if (item instanceof CardElement) {
            this.cardElement.insertItemAfter(item, refItem);
        }
    }

    insertItemBefore(peer: DesignerPeer, refPeer: DesignerPeer) {
        const item = peer.getCardObject() as CardElement;
        const refItem = refPeer
            ? (refPeer.getCardObject() as CardElement)
            : null;
        if (item instanceof CardElement) {
            this.cardElement.insertItemBefore(item, refItem);
        }
    }
}
