import BaseInventoryItem from '../../ACDesigner/BaseInventoryItem';
import ElementInventoryItem from '../../inventory/ElementInventoryItem';
import ActionInventoryItem from '../../inventory/ActionInventoryItem';
import ColumnInventoryItem from '../../inventory/ColumnInventoryItem';
import ImageInventoryItem from '../../inventory/ImageInventoryItem';
import ImageSetInventoryItem from '../../inventory/ImageSetInventoryItem';

import container from './assets/container.svg';
import column from './assets/column.svg';
import textBlock from './assets/textBlock.svg';
import factSet from './assets/factSet.svg';
import image from './assets/image.png';
import imageSet from './assets/imageSet.png';
import inputText from './assets/inputText.svg';
import inputNumber from './assets/inputNumber.svg';
import inputDate from './assets/inputDate.svg';
import inputToggle from './assets/inputToggle.svg';
import inputChoiceSet from './assets/inputChoiceSet.svg';
import actionHttp from './assets/actionHttp.svg';
import actionShowCard from './assets/actionShowCard.svg';
import actionOpenUrl from './assets/actionOpenUrl.svg';
import actionToggleVisibility from './assets/actionToggleVisibility.svg';

export enum ToolCategory {
    CONTAINER = 'container',
    TEXT = 'text',
    IMAGE = 'image',
    INPUTS = 'inputs',
    ACTIONS = 'actions'
}

export type CategoryItem = {
    name: string;
    type: string;
    inventoryType: typeof BaseInventoryItem;
    url: string;
    width: number;
};

export const ToolsMap = {
    container: {
        name: 'Container',
        iconName: 'amd-icon-container',
        items: {
            container: {
                name: 'Container',
                type: 'Container',
                inventoryType: ElementInventoryItem,
                url: container,
                width: 120
            },
            column: {
                name: 'Column',
                type: 'Column',
                inventoryType: ColumnInventoryItem,
                url: column,
                width: 120
            }
        }
    },
    text: {
        name: 'Text',
        iconName: 'amd-icon-text',
        items: {
            textBlock: {
                name: 'Text',
                type: 'TextBlock',
                inventoryType: ElementInventoryItem,
                url: textBlock,
                width: 120
            },
            factSet: {
                name: 'Fact Value Pair',
                type: 'FactSet',
                inventoryType: ElementInventoryItem,
                url: factSet,
                width: 120
            }
        }
    },
    image: {
        name: 'Image',
        iconName: 'amd-icon-image',
        items: {
            image: {
                name: 'Image',
                type: 'Image',
                inventoryType: ImageInventoryItem,
                url: image,
                width: 75
            },
            imageSet: {
                name: 'Image Set',
                type: 'ImageSet',
                inventoryType: ImageSetInventoryItem,
                url: imageSet,
                width: 110
            }
        }
    },
    inputs: {
        name: 'Inputs',
        iconName: 'amd-icon-inputs',
        items: {
            inputText: {
                name: 'Input.Text',
                type: 'Input.Text',
                inventoryType: ElementInventoryItem,
                url: inputText,
                width: 120
            },
            inputNumber: {
                name: 'Input.Number',
                type: 'Input.Number',
                inventoryType: ElementInventoryItem,
                url: inputNumber,
                width: 120
            },
            inputDate: {
                name: 'Input.Date',
                type: 'Input.Date',
                inventoryType: ElementInventoryItem,
                url: inputDate,
                width: 120
            },
            inputToggle: {
                name: 'Input.Toggle',
                type: 'Input.Toggle',
                inventoryType: ElementInventoryItem,
                url: inputToggle,
                width: 120
            },
            inputChoiceSet: {
                name: 'Input.ChoiceSet',
                type: 'Input.ChoiceSet',
                inventoryType: ElementInventoryItem,
                url: inputChoiceSet,
                width: 120
            }
        }
    },
    actions: {
        name: 'Actions',
        iconName: 'amd-icon-actions',
        items: {
            actionHttp: {
                name: 'Submit button',
                subText: 'Action.Http',
                type: 'Action.Http',
                inventoryType: ActionInventoryItem,
                url: actionHttp,
                width: 70
            },
            actionShowCard: {
                name: 'Open a new section',
                subText: 'Action.ShowCard',
                type: 'Action.ShowCard',
                inventoryType: ActionInventoryItem,
                url: actionShowCard,
                width: 126
            },
            actionUrl: {
                name: 'Open a url',
                subText: 'Action.OpenUrl',
                type: 'Action.OpenUrl',
                inventoryType: ActionInventoryItem,
                url: actionOpenUrl,
                width: 112
            },
            actionToggleVisibility: {
                name: 'Toggle the visibility of an element',
                subText: 'Action.ToggleVisibility',
                type: 'Action.ToggleVisibility',
                inventoryType: ActionInventoryItem,
                url: actionToggleVisibility,
                width: 93
            }
        }
    }
};
