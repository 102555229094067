import TypedCardElementPeer from './TypedCardElementPeer';
import { ImageSet, Image } from 'adaptivecards';
import { addChildCommand } from './DesignerPeersUtils';
import CardDesignerSurface from '../CardDesignerSurface';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CardElementPeer from './CardElementPeer';
import DesignerPeer from './DesignerPeer';
import ListImagePropertyEditor from '../PropertyEditor/ListImagePropertyEditor';
import { PlaceholderImageUrl } from '../../inventory/InventoryUtil';

export default class ImageSetPeer extends TypedCardElementPeer<ImageSet> {
    static readonly imagesProperty = new ListImagePropertyEditor(
        '*',
        '_images',
        'url',
        'altText',
        'Image URL',
        'Alternate Text',
        'Insert the url for the image you want to insert. ',
        'Assign a text description to this image for accesibility reasons.'
    );

    protected addCommands(commands: Array<addChildCommand>) {
        let command: addChildCommand = {
            name: '',
            subCommands: [],
            execute() {}
        };
        command.name = 'Add an image';
        command.execute = () => {
            let newImage = new Image();
            newImage.url = PlaceholderImageUrl;
            this.cardElement.addImage(newImage);
            this.insertChild(
                CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                    this.designerSurface,
                    this,
                    newImage
                )
            );
            this.changed(true);
        };
        commands.push(command);
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            CardElementPeer.idProperty,
            CardElementPeer.isVisibleProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty,
            ImageSetPeer.imagesProperty
        );
    }

    insertItemAfter(peer: DesignerPeer, refPeer: DesignerPeer) {
        const image = peer.getCardObject();
        const refImage = refPeer ? refPeer.getCardObject() : null;
        if (image instanceof Image) {
            this.cardElement['insertItemAfter'](image, refImage);
        }
    }

    insertItemBefore(peer: DesignerPeer, refPeer: DesignerPeer) {
        const image = peer.getCardObject();
        const refImage = refPeer ? refPeer.getCardObject() : null;
        if (image instanceof Image) {
            this.cardElement['insertItemBefore'](image, refImage);
        }
    }
}
