import ElementInventoryItem from './ElementInventoryItem';
import CardDesignerSurface from '../ACDesigner/CardDesignerSurface';
import CardElementPeer from '../ACDesigner/DesignerPeers/CardElementPeer';
import { generateUUID } from '../utilities/Utils';
import { PlaceholderImageUrl } from './InventoryUtil';
import { Image, Size } from 'adaptivecards';

export default class ImageInventoryItem extends ElementInventoryItem {
    createPeer(designer: CardDesignerSurface): CardElementPeer {
        const image = this.typeRegistration.createInstance() as Image;
        image.id = generateUUID();
        image.url = PlaceholderImageUrl;
        image.size = Size.Auto;
        const peer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
            designer,
            null,
            image
        );
        peer.initializeCardElement();
        return peer;
    }
}
