import SingleInputPropertyEditor from './SingleInputPropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';
import { TargetVersion } from '../Shared';

export default class StringPropertyEditor extends SingleInputPropertyEditor {
    protected createInput(
        context: PropertySheetContext,
        eventCallback
    ): HTMLElement {
        let input: HTMLElement;
        let defaultValue: string = this.getPropertyValue(context);
        input = new GeneratePropertyFields().generateTextFields(
            this.isMultiline,
            this.label,
            defaultValue,
            eventCallback
        );
        return input;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly tooltipContent: string,
        readonly isMultiline: boolean = false,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(
            targetVersion,
            propertyName,
            label,
            tooltipContent,
            causesPropertySheetRefresh
        );
    }
}
