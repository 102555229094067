import {
    AdaptiveCard,
    IValidationError,
    setProperty,
    getBoolValue,
    getStringValue
} from 'adaptivecards';

import { ORIGINATOR } from '../../utilities/constants';

const toJSON = () => {
    const prototypeToJSON = AdaptiveCard.prototype.toJSON;
    AdaptiveCard.prototype.toJSON = function() {
        const result = prototypeToJSON.call(this);
        setProperty(result, 'rtl', this.rtl, false);
        if (!this.parent) {
            setProperty(result, 'originator', this['originator'], ORIGINATOR);
        }
        return result;
    };
};

const parse = () => {
    const prototypeParse = AdaptiveCard.prototype.parse;
    AdaptiveCard.prototype.parse = function(
        json: any,
        errors?: Array<IValidationError>
    ) {
        prototypeParse.call(this, json, errors);
        this.rtl = getBoolValue(json.rtl, this.rtl);
        if (!this.parent) {
            this['originator'] = getStringValue(
                json.originator,
                this['originator']
            );
        }
    };
};

const createEmptyCardPlaceHolder = () => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('emptyCardPlaceHolderWrapper');
    const placeHolderImage = document.createElement('div');
    placeHolderImage.classList.add('emptyCardPlaceHolder');
    const placeHolderText = document.createElement('div');
    placeHolderText.classList.add('emptyCardPlaceHolderText');
    const text1 = document.createElement('div');
    text1.innerText = 'Drag and Drop';
    const text2 = document.createElement('div');
    text2.innerText = 'elements from the top';
    placeHolderText.appendChild(text1);
    placeHolderText.appendChild(text2);
    wrapper.appendChild(placeHolderImage);
    wrapper.appendChild(placeHolderText);
    return wrapper;
};

const internalRender = () => {
    const prototypeInternalRender = AdaptiveCard.prototype['internalRender'];
    AdaptiveCard.prototype['internalRender'] = function() {
        const renderedElement = prototypeInternalRender.call(this);
        if (!this.parent && this.getItemCount() === 0 && this.designMode) {
            renderedElement.innerHTML = '';
            renderedElement.style.width = '100%';
            renderedElement.style.height = '480px';
            renderedElement.appendChild(createEmptyCardPlaceHolder());
        }
        return renderedElement;
    };
};

const defineProperties = () => {
    Object.defineProperty(AdaptiveCard.prototype, 'originator', {
        configurable: true,
        enumerable: true,
        value: ORIGINATOR,
        writable: true
    });
};

export const initialize = () => {
    defineProperties();
    internalRender();
    toJSON();
    parse();
};
