import React from 'react';
import { observer } from 'mobx-react';

import { TopBarButtons } from './TopBarButtons';

import logo from './assets/microsoft_logo.svg';

import './TopBar.css';
import { AppStoreProps, AuthStoreProps } from '../../stores/StoreProps';

class Logo extends React.Component {
    public render() {
        return (
            <header role="banner" className="logoProductWrapper">
                <span className="logoWrapper">
                    <span className="microsoftLogo" tabIndex={0}>
                        <img src={logo} className="logo" alt="logo" />
                    </span>
                    <span className="microsoftLabel">
                        <span
                            aria-label="logo header"
                            tabIndex={0}
                            id="microsoftLabelText"
                        >
                            Microsoft
                        </span>
                    </span>
                </span>
                <span className="showPipe"></span>
                <span className="productLabel">
                    <span
                        role="heading"
                        tabIndex={0}
                        aria-level={1}
                        id="productLabelText"
                        title="Actionable Message Designer"
                    >
                        Actionable Message Designer
                    </span>
                </span>
                <span className="productLabelMobile">
                    <span
                        role="heading"
                        tabIndex={0}
                        aria-level={1}
                        title="Actionable Message Designer"
                        id="productLabelTextMobile"
                    >
                        Actionable Message Designer
                    </span>
                </span>
            </header>
        );
    }
}

@observer
export class TopBar extends React.Component<AuthStoreProps & AppStoreProps> {
    public render() {
        return (
            <div className="topBar" role="banner">
                <Logo />
                <TopBarButtons
                    authStore={this.props.authStore}
                    appStore={this.props.appStore}
                />
            </div>
        );
    }
}
