import React from 'react';
import { DatePicker } from '@fluentui/react';

interface DatePickerProps {
    onChangeCallback(value: string): void;
    defaultValue: string;
}

export class CustomDatePicker extends React.Component<DatePickerProps, {}> {
    private value: Date | null;
    constructor(props) {
        super(props);
        if (this.props.defaultValue) {
            this.value = new Date();
            this.value.setFullYear(
                parseInt(this.props.defaultValue.substr(0, 4), 10)
            );
            this.value.setMonth(
                parseInt(this.props.defaultValue.substr(5, 2), 10) - 1
            );
            this.value.setDate(
                parseInt(this.props.defaultValue.substr(8, 2), 10)
            );
        }
    }

    public render() {
        return (
            <DatePicker
                placeholder="Select a date"
                onSelectDate={this.onSelectDate}
                formatDate={this.onFormatDate}
                value={this.value}
                showMonthPickerAsOverlay={true}
                showGoToToday={false}
            />
        );
    }

    private onSelectDate = (newDate: Date | null | undefined): void => {
        let day: string =
            newDate.getDate() < 10
                ? '0' + newDate.getDate().toString()
                : newDate.getDate().toString();
        let month: string =
            newDate.getMonth() + 1 < 10
                ? '0' + (newDate.getMonth() + 1).toString()
                : (newDate.getMonth() + 1).toString();
        let year: string = newDate.getFullYear().toString();
        let date: string = year + '-' + month + '-' + day;
        this.props.onChangeCallback(date);
    };

    private onFormatDate = (date: Date): string => {
        let day: string =
            date.getDate() < 10
                ? '0' + date.getDate().toString()
                : date.getDate().toString();
        let month: string =
            date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1).toString()
                : (date.getMonth() + 1).toString();
        let year: string = date.getFullYear().toString();
        return day + '-' + month + '-' + year;
    };
}
