import PropertySheetEntry from './PropertySheetEntry';
import PropertySheetContext from './PropertySheetContext';
import { onChangeCallbackType } from '../DesignerPeers/DesignerPeersUtils';
import GeneratePropertyFields from './GeneratePropertyFields';
import { TargetVersion } from '../Shared';

export default abstract class SingleInputPropertyEditor extends PropertySheetEntry {
    protected isBoolean: boolean = false;
    protected abstract createInput(
        context: PropertySheetContext,
        eventCallback: onChangeCallbackType
    ): HTMLElement;

    protected getPropertyValue(context: PropertySheetContext): any {
        return context.target[this.propertyName];
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = value;
    }

    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');

        let labelDiv: HTMLElement = document.createElement('div');
        labelDiv.style.display = 'flex';
        let label: HTMLElement = new GeneratePropertyFields().generateLabels(
            this.label
        );
        let tooltipElement: HTMLElement = new GeneratePropertyFields().generateTooltips(
            this.tooltipContent
        );
        tooltipElement.style.cssFloat = 'right';

        const eventCallback = (value: string) => {
            this.setPropertyValue(context, value);
            context.peer.changed(this.causesPropertySheetRefresh);
        };

        let input = this.createInput(context, eventCallback);
        if (this.isBoolean) {
            this.isBoolean = false;
            label.classList.add('labelBooleanPropertyEditor');
            label.classList.remove('labelProperty');
            let switchDiv: HTMLLabelElement = document.createElement('label');
            switchDiv.classList.add('switchPropertyEditor');
            let span: HTMLSpanElement = document.createElement('span');
            switchDiv.appendChild(input);
            switchDiv.appendChild(span);
            labelDiv.appendChild(label);
            labelDiv.appendChild(switchDiv);
            labelDiv.style.width = '100%';
            container.appendChild(labelDiv);
            container.appendChild(tooltipElement);
            container.classList.add('divBooleanPropertyEditor');
        } else {
            label.style.width = '100%';
            labelDiv.appendChild(label);
            labelDiv.appendChild(tooltipElement);
            container.classList.add('property');
            container.appendChild(labelDiv);
            container.appendChild(input);
        }

        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(targetVersion);
    }
}
