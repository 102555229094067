import DesignerPeerRegistration from './DesignerPeerRegistration';
import DesignerPeerRegistrationBase from './DesignerPeerRegistrationBase';
import DesignerPeerCategory from './DesignerPeerCategory';

export default abstract class DesignerPeerRegistry<TSource, TPeer> {
    protected _items: Array<DesignerPeerRegistration<TSource, TPeer>> = [];

    readonly defaultRegistration = new DesignerPeerRegistrationBase(
        DesignerPeerCategory.Unknown,
        'acd-icon-unknownPeer'
    );

    constructor() {
        this.reset();
    }

    abstract reset();

    clear() {
        this._items = [];
    }

    findTypeRegistration(
        sourceType: TSource
    ): DesignerPeerRegistration<TSource, TPeer> {
        for (var i = 0; i < this._items.length; i++) {
            if (this._items[i].sourceType === sourceType) {
                return this._items[i];
            }
        }

        return null;
    }

    registerPeer(
        sourceType: TSource,
        peerType: TPeer,
        category: string,
        iconClass: string = null
    ) {
        var registrationInfo = this.findTypeRegistration(sourceType);

        if (registrationInfo !== null) {
            registrationInfo.peerType = peerType;
        } else {
            registrationInfo = new DesignerPeerRegistration<TSource, TPeer>(
                sourceType,
                peerType,
                category,
                iconClass
            );

            this._items.push(registrationInfo);
        }
    }

    unregisterPeer(sourceType: TSource) {
        for (var i = 0; i < this._items.length; i++) {
            if (this._items[i].sourceType === sourceType) {
                this._items.splice(i, 1);

                return;
            }
        }
    }
}
