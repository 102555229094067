import { CardElement } from 'adaptivecards';
import CardElementPeer from './CardElementPeer';

export default abstract class TypedCardElementPeer<
    TCardElement extends CardElement
> extends CardElementPeer {
    get cardElement(): TCardElement {
        return this._cardElement as TCardElement;
    }
}
