import TypedCardElementPeer from './TypedCardElementPeer';
import { Action, AdaptiveCard } from 'adaptivecards';
import CardDesignerSurface from '../CardDesignerSurface';
import { addChildCommand, subCommands } from './DesignerPeersUtils';
import DesignerPeer from './DesignerPeer';
import ActionPeer from './ActionPeer';
import { MaxActions } from '../CardConstants';
import LinkPropertyEditor from '../PropertyEditor/LinkPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import ActionTitle from '../../ActionTitle';
import CardElementPeer from './CardElementPeer';

export default class ActionSetPeer extends TypedCardElementPeer<AdaptiveCard> {
    static readonly actionsProperty = new LinkPropertyEditor(
        '*',
        '_actionCollection',
        'Actions',
        'All the actions inserted in this action set appear here. You can add more actions with the button below.'
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            CardElementPeer.idProperty,
            CardElementPeer.isVisibleProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty,
            CardElementPeer.horizontalAlignmentProperty,
            ActionSetPeer.actionsProperty
        );
    }

    protected addAction(action: Action) {
        this.cardElement.addAction(action);

        this.insertChild(
            CardDesignerSurface.actionPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                action
            )
        );
    }

    protected addCommands(commands: Array<addChildCommand>) {
        if (this.getChildCount() < MaxActions) {
            let command: addChildCommand = {
                name: '',
                subCommands: [],
                execute() {}
            };
            command.name = 'Add an action';
            for (
                let i = 0;
                i < AdaptiveCard.actionTypeRegistry.getItemCount();
                i++
            ) {
                let subCommand: subCommands = {
                    key: '',
                    value: '',
                    execute() {}
                };
                subCommand.key = i.toString();
                let actionType = AdaptiveCard.actionTypeRegistry.getItemAt(i)
                    .typeName;
                const typeName =
                    actionType.slice(7) +
                    ' (' +
                    ActionTitle.get(actionType) +
                    ')';
                subCommand.value = typeName;
                subCommand.execute = () => {
                    let action = AdaptiveCard.actionTypeRegistry
                        .getItemAt(i)
                        .createInstance();
                    action.title = actionType;
                    this.addAction(action);
                    this.changed(true);
                };
                command.subCommands.push(subCommand);
            }
            commands.push(command);
        }
    }

    canDrop(peer: DesignerPeer): boolean {
        if (
            !super.canDrop(peer) ||
            (peer instanceof ActionSetPeer && peer.getChildCount() !== 1)
        ) {
            return false;
        }
        if (this.getChildCount() >= MaxActions) {
            return false;
        }
        return true;
    }

    getPeerToInsert(peer: DesignerPeer) {
        let insertPeer: ActionPeer = null;
        if (peer instanceof ActionSetPeer && peer.getChildCount() === 1) {
            const actionPeer = peer.getChildAt(0) as ActionPeer;
            if (!actionPeer.remove(true, false)) {
                return insertPeer;
            }
            peer.removeChild(actionPeer);
            insertPeer = actionPeer;
        } else if (peer instanceof ActionPeer) {
            insertPeer = peer;
        }
        return insertPeer;
    }

    insertItemAfter(peer: DesignerPeer, refPeer: DesignerPeer): void {
        const action = peer.getCardObject();
        if (action instanceof Action) {
            this.cardElement.addAction(action);
        }
    }

    insertItemBefore(peer: DesignerPeer, refPeer: DesignerPeer): void {
        this.insertItemAfter(peer, refPeer);
    }
}
