import PropertySheetEntry from './PropertySheetEntry';
import { TargetVersion } from '../Shared';
import GeneratePropertyFields from './GeneratePropertyFields';
import PropertySheetContext from './PropertySheetContext';
import { keyValuePair, ImageSize } from '../DesignerPeers/DesignerPeersUtils';

export default class ImageSizePropertyEditor extends PropertySheetEntry {
    private generateSubImageSize(
        context,
        widthImageSizeChange,
        heightImageSizeChange
    ): HTMLElement {
        let container: HTMLElement = document.createElement('div');

        let widthLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
            'Width'
        );
        let widthDefaultValue = '0';
        widthDefaultValue = context.target['pixelWidth']
            ? context.target['pixelWidth'].toString()
            : context.peer.cardElement.renderedElement.firstElementChild.width;
        let widthInput: HTMLElement = new GeneratePropertyFields().generateTextFields(
            false,
            'Custom Width',
            widthDefaultValue,
            widthImageSizeChange
        );
        container.appendChild(widthLabel);
        container.appendChild(widthInput);

        let heightLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
            'Height'
        );
        let heightDefaultValue = '0';
        heightDefaultValue = context.target['pixelHeight']
            ? context.target['pixelHeight'].toString()
            : context.peer.cardElement.renderedElement.firstElementChild.height;
        let heightInput: HTMLElement = new GeneratePropertyFields().generateTextFields(
            false,
            'Custom Height',
            heightDefaultValue,
            heightImageSizeChange
        );
        container.appendChild(heightLabel);
        container.appendChild(heightInput);

        return container;
    }

    private createInput(
        context: PropertySheetContext,
        onChangeCallback
    ): HTMLElement {
        let entries: keyValuePair = {};
        for (let key in this.enumType) {
            let v = parseInt(key, 10);

            if (!isNaN(v)) {
                entries[this.enumType[key]] = key;
            }
        }

        let defaultValue = '0';
        if (context.target['pixelWidth'] || context.target['pixelHeight']) {
            defaultValue = ImageSize.Custom.toString();
        } else {
            if (context.target[this.propertyName])
                defaultValue = context.target[this.propertyName].toString();
        }
        let input: HTMLElement = new GeneratePropertyFields().generateDropDown(
            entries,
            onChangeCallback,
            defaultValue,
            this.label
        );
        return input;
    }

    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');
        let subContainerImageSize: HTMLElement = document.createElement('div');
        subContainerImageSize.style.paddingLeft = '16px';

        const widthImageSizeChange = (value: string) => {
            context.target['pixelWidth'] = parseInt(value, 10);
            context.peer.changed(this.causesPropertySheetRefresh);
        };

        const heightImageSizeChange = (value: string) => {
            context.target['pixelHeight'] = parseInt(value, 10);
            context.peer.changed(this.causesPropertySheetRefresh);
        };

        const onChangeCallback = (value: string) => {
            if (value === ImageSize.Custom.toString()) {
                // for custom size of image
                subContainerImageSize.classList.remove('acd-hidden');
                subContainerImageSize.appendChild(
                    this.generateSubImageSize(
                        context,
                        widthImageSizeChange,
                        heightImageSizeChange
                    )
                );
                container.appendChild(subContainerImageSize);
            } else {
                subContainerImageSize.classList.add('acd-hidden');
                context.target['pixelHeight'] = 0;
                context.target['pixelWidth'] = 0;
                let imageSize = parseInt(value, 10);
                context.target[this.propertyName] = imageSize;
                context.peer.changed(true);
                container.appendChild(subContainerImageSize);
            }
        };

        let labelDiv: HTMLElement = document.createElement('div');
        labelDiv.style.display = 'flex';
        let label: HTMLElement = new GeneratePropertyFields().generateLabels(
            this.label
        );
        let tooltipElement: HTMLElement = new GeneratePropertyFields().generateTooltips(
            this.tooltipContent
        );
        tooltipElement.style.cssFloat = 'right';

        let input = this.createInput(context, onChangeCallback);
        label.style.width = '100%';
        labelDiv.appendChild(label);
        labelDiv.appendChild(tooltipElement);
        container.classList.add('property');
        container.appendChild(labelDiv);
        container.appendChild(input);

        let widthImageSize = context.target['pixelWidth'];
        let heightImageSize = context.target['pixelHeight'];
        if (
            (widthImageSize === 0 || widthImageSize === null) &&
            (heightImageSize === 0 || heightImageSize === null)
        ) {
            subContainerImageSize.classList.add('acd-hidden');
            container.appendChild(subContainerImageSize);
        } else {
            subContainerImageSize.appendChild(
                this.generateSubImageSize(
                    context,
                    widthImageSizeChange,
                    heightImageSizeChange
                )
            );
            subContainerImageSize.classList.remove('acd-hidden');
            container.appendChild(subContainerImageSize);
        }

        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly enumType: { [s: number]: string },
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(targetVersion);
    }
}
