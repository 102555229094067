import React from 'react';
import {
    CommandBarButton,
    IOverflowSetItemProps,
    initializeIcons,
    OverflowSet,
} from '@fluentui/react';
import { DirectionalHint, TooltipHost } from '@fluentui/react';
import { inject, observer } from 'mobx-react';

import { VIEW } from '../../stores/AppStore';
import { FeedbackForm } from '../FeedbackForm';
import { Guide } from '../Guide';

import { keyBoardToClickEventMapper } from '../../utilities/Utils';

import signInAvatar from './assets/signin_avatar.svg';

import './TopBarButtons.css';
import { AppStoreProps, AuthStoreProps } from '../../stores/StoreProps';

initializeIcons();

@inject('authStore')
@observer
class SignIn extends React.Component<AuthStoreProps> {
    private handleClick = () => {
        this.props.authStore.signIn();
    };

    public render() {
        return (
            <div
                className="topBarButton signInButton"
                onClick={this.handleClick}
                onKeyDown={keyBoardToClickEventMapper(this.handleClick)}
                role="button"
                tabIndex={0}
            >
                <span className="signInText">Sign In</span>
                <img src={signInAvatar} className="topBarIcon" alt=""></img>
            </div>
        );
    }
}

@observer
class SignedInDropDown extends React.Component<AuthStoreProps> {
    private handleSignOut = (event: React.PointerEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.authStore.signOut();
    };

    private handleClick = (event: React.PointerEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    public render() {
        let userPhoto = null;
        if (this.props.authStore.userPhoto) {
            userPhoto = (
                <img
                    src={this.props.authStore.userPhoto}
                    className="userAvatar"
                    alt=""
                ></img>
            );
        } else {
            if (this.props.authStore.userName) {
                userPhoto = (
                    <div className="userAvatarCircle" aria-hidden="true">
                        <span className="userInitials">
                            {this.props.authStore.userName[0].toUpperCase()}
                        </span>
                    </div>
                );
            }
        }

        let userDetails = null;
        if (this.props.authStore.userProfile) {
            userDetails = (
                <div className="userDetails">
                    <div
                        className="userName ellipsis"
                        title={this.props.authStore.userName}
                    >
                        {this.props.authStore.userName}
                    </div>
                    <div
                        className="userEmail ellipsis"
                        title={this.props.authStore.userEmail}
                    >
                        {this.props.authStore.userEmail}
                    </div>
                </div>
            );
        }
        const signOut = (
            <div className="signOutWrapper">
                <span
                    className="signOut"
                    onClick={this.handleSignOut}
                    onKeyDown={keyBoardToClickEventMapper(this.handleSignOut)}
                    role="button"
                    tabIndex={0}
                >
                    Sign Out
                </span>
            </div>
        );
        return (
            <div
                id="userDetailDropDown"
                className="userDetailDropDown"
                onClick={this.handleClick}
            >
                {userPhoto}
                {userDetails}
                {signOut}
            </div>
        );
    }
}

type SignedInState = {
    openDropDown: boolean;
};

@inject('authStore')
@observer
class SignedIn extends React.Component<AuthStoreProps, SignedInState> {
    private node: HTMLDivElement;

    private handleClick = () => {
        this.setState((prevState) => ({
            openDropDown: !prevState.openDropDown,
        }));
    };

    private handleClickOnWindow = (event: MouseEvent) => {
        if (this.node !== null && this.node.contains(event.target as Element)) {
            return;
        }
        this.setState({
            openDropDown: false,
        });
    };

    constructor(props) {
        super(props);
        this.state = {
            openDropDown: false,
        };
    }

    public componentDidMount() {
        window.addEventListener('click', this.handleClickOnWindow);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOnWindow);
    }

    public render() {
        let icon = null;

        if (this.props.authStore.userPhoto) {
            icon = (
                <img
                    src={this.props.authStore.userPhoto}
                    className="topBarIcon topBarAvatar"
                    alt=""
                ></img>
            );
        } else {
            if (this.props.authStore.userName) {
                icon = (
                    <div className="smallAvatarCircle" aria-hidden="true">
                        <span className="smallInitials">
                            {this.props.authStore.userName[0].toUpperCase()}
                        </span>
                    </div>
                );
            }
        }
        let buttonClass = 'topBarButton signedInButton';
        if (this.state.openDropDown) {
            buttonClass += ' selected';
        }
        return (
            <div
                ref={(node) => (this.node = node)}
                className={buttonClass}
                onClick={this.handleClick}
                title={this.props.authStore.userName}
                onKeyDown={keyBoardToClickEventMapper(this.handleClick)}
                role="button"
                aria-expanded={this.state.openDropDown ? 'true' : 'false'}
                tabIndex={0}
            >
                {icon}
                {this.state.openDropDown && (
                    <SignedInDropDown authStore={this.props.authStore} />
                )}
            </div>
        );
    }
}

function SigningIn() {
    return (
        <div className="topBarButton" tabIndex={0}>
            <span className="signingIn">Signing In...</span>
        </div>
    );
}

export interface FeedButtonStates {
    feedbackUIOpen: boolean;
}

@observer
class FeedbackButton extends React.Component<AuthStoreProps, FeedButtonStates> {
    constructor(props) {
        super(props);
        this.state = { feedbackUIOpen: false };
    }

    handleClick = () => {
        this.setState({
            feedbackUIOpen: true,
        });
    };

    closeFeedback = () => {
        this.setState({ feedbackUIOpen: false });
    };

    public render() {
        return (
            this.props.authStore.isLoggedIn && (
                <div>
                    <div
                        className="topBarButton feedbackButton"
                        onClick={this.handleClick}
                        onKeyDown={keyBoardToClickEventMapper(this.handleClick)}
                        role="button"
                        tabIndex={0}
                        aria-label="Provide Feedback"
                        title="Provide Feedback"
                    >
                        <span className="amd-icon feedback-emoji"></span>
                        <span className="feedbackText">Feedback</span>
                    </div>
                    {this.state.feedbackUIOpen && (
                        <FeedbackForm
                            closeFeedback={this.closeFeedback}
                            authStore={this.props.authStore}
                        />
                    )}
                </div>
            )
        );
    }
}

export interface GuideButtonStates {
    isGuideOpen: boolean;
}

class GuideButton extends React.Component<AppStoreProps, GuideButtonStates> {
    constructor(props) {
        super(props);
        this.state = { isGuideOpen: false };
    }

    componentDidMount() {
        this.checkFRE();
    }

    private checkFRE() {
        let cardType = this.props.appStore.currentCard;
        if (cardType === 'blankCard') {
            let item = localStorage.getItem('AMD.FreBlankCard');
            if (!item) {
                localStorage.setItem('AMD.FreBlankCard', 'Done');
                this.setState({
                    isGuideOpen: true,
                });
            }
        } else {
            let item = localStorage.getItem('AMD.FreSampleCard');
            if (!item) {
                localStorage.setItem('AMD.FreSampleCard', 'Done');
                this.setState({
                    isGuideOpen: true,
                });
            }
        }
    }

    private handleClick = () => {
        this.setState({
            isGuideOpen: true,
        });
    };

    private closeGuide = () => {
        this.setState({
            isGuideOpen: false,
        });
    };

    public render() {
        return (
            <div title="Open Help Guide">
                <div
                    className="topBarButton guideButton"
                    onClick={this.handleClick}
                    onKeyDown={keyBoardToClickEventMapper(this.handleClick)}
                    role="button"
                    tabIndex={0}
                    aria-label="Open Help Guide"
                >
                    <span className="amd-icon helpIcon"></span>
                    <span className="guideButtonText">Help Guide</span>
                </div>
                {this.state.isGuideOpen ? (
                    <Guide
                        stores={{ appStore: this.props.appStore }}
                        parentCallback={this.closeGuide}
                    />
                ) : null}
            </div>
        );
    }
}

export interface TopBarButtonStates
    extends FeedButtonStates,
        GuideButtonStates {}

@observer
class OverflowMenuItem extends React.Component<
    AppStoreProps & AuthStoreProps,
    TopBarButtonStates
> {
    menuItems =
        this.props.appStore.currentView === VIEW.DESIGN
            ? [
                  {
                      key: 'Feedback',
                      name: <FeedbackButton authStore={this.props.authStore} />,
                      onClick: () => {
                          this.setState({
                              feedbackUIOpen: true,
                          });
                      },
                  },
                  {
                      key: 'Help Guide',
                      name: <GuideButton appStore={this.props.appStore} />,
                      onClick: () => {
                          this.setState({
                              isGuideOpen: true,
                          });
                      },
                  },
              ]
            : [
                  {
                      key: 'Feedback',
                      name: <FeedbackButton authStore={this.props.authStore} />,
                      onClick: () => {
                          this.setState({
                              feedbackUIOpen: true,
                          });
                      },
                  },
              ];

    constructor(props) {
        super(props);
        this.state = { feedbackUIOpen: false, isGuideOpen: false };
    }

    onRenderItemStyles = {
        root: { padding: '10px' },
    };

    onRenderOverflowButtonStyles = {
        menuIcon: { fontSize: '16px' },
    };

    onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
        return (
            <TooltipHost
                content={item.title}
                directionalHint={DirectionalHint.rightCenter}
            >
                <CommandBarButton
                    role="menuItem"
                    aria-label={item.name}
                    styles={this.onRenderItemStyles}
                    iconProps={{ iconName: item.icon }}
                    onClick={item.onClick}
                />
            </TooltipHost>
        );
    };

    onRenderOverflowButton = (
        overflowItems: any[] | undefined,
    ): JSX.Element => {
        return (
            <TooltipHost
                content="More items"
                directionalHint={DirectionalHint.rightCenter}
            >
                <CommandBarButton
                    role="menuItem"
                    aria-label="menu items"
                    className="mobileBarOverflowMenu"
                    styles={this.onRenderOverflowButtonStyles}
                    menuIconProps={{ iconName: 'More' }}
                    menuProps={{ items: overflowItems! }}
                />
            </TooltipHost>
        );
    };

    public render() {
        return (
            <>
                {this.state.feedbackUIOpen && (
                    <FeedbackForm
                        authStore={this.props.authStore}
                        closeFeedback={() => {
                            this.setState({
                                feedbackUIOpen: false,
                            });
                        }}
                    />
                )}

                {this.state.isGuideOpen ? (
                    <Guide
                        stores={{ appStore: this.props.appStore }}
                        parentCallback={() => {
                            this.setState({
                                isGuideOpen: false,
                            });
                        }}
                    />
                ) : null}
                <OverflowSet
                    aria-label="Click to submit feedback"
                    role="menubar"
                    overflowItems={this.menuItems}
                    onRenderOverflowButton={this.onRenderOverflowButton}
                    onRenderItem={this.onRenderItem}
                />
            </>
        );
    }
}

@observer
class SignInSignOutButton extends React.Component<AuthStoreProps> {
    public render() {
        return this.props.authStore.loginInProgress ? (
            <SigningIn />
        ) : this.props.authStore.isLoggedIn ? (
            <SignedIn authStore={this.props.authStore} />
        ) : (
            <SignIn authStore={this.props.authStore} />
        );
    }
}

@inject('appStore', 'authStore')
@observer
export class TopBarButtons extends React.Component<
    AuthStoreProps & AppStoreProps
> {
    public render() {
        return (
            <div className="topBarButtons">
                <span className="mobileBarButtons">
                    {this.props.authStore.isLoggedIn && (
                        <OverflowMenuItem
                            authStore={this.props.authStore}
                            appStore={this.props.appStore}
                        />
                    )}
                </span>
                <span className="guideTopButton">
                    {this.props.appStore.currentView === VIEW.DESIGN ? (
                        <GuideButton appStore={this.props.appStore} />
                    ) : null}
                </span>
                <span className="feedbackTopButton">
                    <FeedbackButton authStore={this.props.authStore} />
                </span>
                <span>
                    <SignInSignOutButton authStore={this.props.authStore} />
                </span>
            </div>
        );
    }
}
