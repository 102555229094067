import NumberPropertyEditor from './NumberPropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import { SizeAndUnit, SizeUnit } from 'adaptivecards';
import { TargetVersion } from '../Shared';

export default class SizeAndUnitPropertyEditor extends NumberPropertyEditor {
    protected getPropertyValue(context: PropertySheetContext): any {
        return (context.target[
            this.propertyName
        ] as SizeAndUnit).physicalSize.toString();
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = new SizeAndUnit(
            parseInt(value),
            this.sizeUnit
        );
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly sizeUnit: SizeUnit,
        readonly tooltipContent: string,
        readonly defaultValue: number | undefined = undefined,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(
            targetVersion,
            propertyName,
            label,
            tooltipContent,
            defaultValue,
            causesPropertySheetRefresh
        );
    }
}
