import React from 'react';
import * as monaco from 'monaco-editor';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import markdownit from 'markdown-it';

import CardDesigner from '../../ACDesigner/CardDesigner';
import HostContainer from '../../ACDesigner/HostContainer';
import { CardSamples, getSampleJson } from '../../samples/Samples';

import './Designer.css';
import './HostContainer.css';
import './AdaptiveCardsDesigner.css';
import { AppStoreProps } from '../../stores/StoreProps';

@inject('appStore')
@observer
export default class Designer extends React.Component<AppStoreProps> {
    private designerDiv: HTMLElement | null;

    @action
    public componentDidMount() {
        CardDesigner.onProcessMarkdown = (text, result) => {
            result.outputHtml = markdownit().render(text);
            result.didProcess = true;
        };
        if (!this.props.appStore.designer) {
            const hostContainer = new HostContainer();
            this.props.appStore.designer = new CardDesigner(hostContainer);
        }
        this.props.appStore.designer.attachTo(this.designerDiv);
        this.props.appStore.designer.monacoModuleLoaded(monaco);
        if (this.props.appStore.loadCard) {
            const cardDetails = CardSamples[this.props.appStore.currentCard];
            getSampleJson(cardDetails.fileName).then((json) => {
                this.props.appStore.designer.setCard(json);
                this.props.appStore.loadCard = false;
                document.getElementById('editorCanvas').scrollTop = 70;
            });
        }

        // Disable the property editor and visual tree editor in case of ACv2DesignView
        if (this.props.appStore.isACv2DesignView) {
            const propertySheetWrapper = document.getElementById(
                'propertySheetWrapper',
            );
            propertySheetWrapper.className =
                'propertySheetWrapper disablePointerEventsACv2Design';

            const treeViewWrapper = document.getElementById('treeViewWrapper');
            treeViewWrapper.className =
                'treeViewWrapper disablePointerEventsACv2Design';

            const jsonEditorHeaderElement =
                document.getElementById('jsonEditorHeader');
            jsonEditorHeaderElement.style.pointerEvents = 'None';

            const editor = document.getElementById('editor');
            editor.style.grid = '37px 37px 100% / 1fr 177px 1fr';

            const editorCanvas = document.getElementById('editorCanvas');
            editorCanvas.style.height = '0px';
            editorCanvas.style.padding = '0px';
        }
    }

    public componentWillUnmount() {
        this.props.appStore.designer.detach();
    }

    public render() {
        return (
            <main
                className="designer"
                ref={(div) => (this.designerDiv = div)}
                role="main"
            />
        );
    }
}
