import React from 'react';
import { Callout } from '@fluentui/react';

import propertyHelp from './assets/Property_Editor_Help.png';
import visualTreeHelp from './assets/Visaul_Tree_Editor_Help.png';

import './EditorHeader.css';
import '../../../App.css';
import { keyBoardToClickEventMapper } from '../../../utilities/Utils';

type EditorHeaderState = {
    isCalloutVisible: boolean;
};

type EditorHeaderProps = {
    type: EditorHeaderType;
    onCloseIconClick: () => void;
};

export enum EditorHeaderType {
    VisualTreeEditor = 'visualTreeEditor',
    PropertyEditor = 'propertyEditor',
}

export default class EditorHeader extends React.Component<
    EditorHeaderProps,
    EditorHeaderState
> {
    private myRef = React.createRef<HTMLDivElement>();

    private editorHeaderData = {
        visualTreeEditor: {
            name: 'Visual Tree Editor',
            content: <VisualTreeEditorHelp />,
        },
        propertyEditor: {
            name: 'Property Editor',
            content: <PropertyEditorHelp />,
        },
    };

    private toggleCalloutVisibility = () => {
        this.setState({
            isCalloutVisible: !this.state.isCalloutVisible,
        });
    };

    private handleKeyDown = (event) => {
        if (event.key === ' ' || event.key === 'Enter') {
            this.setState({
                isCalloutVisible: !this.state.isCalloutVisible,
            });
        } else if (
            (event.target as Element).closest('#propertyEditorHeader') !==
                null &&
            event.shiftKey &&
            event.key === 'Tab'
        ) {
            const focusOnId = (id: string, e: KeyboardEvent) => {
                const element = document.getElementById(id);
                if (element) {
                    element.focus();
                    e.preventDefault();
                }
            };
            if (window.innerWidth <= 700) {
                focusOnId('openEditorOptionsWrapper', event);
            } else {
                focusOnId('editJsonSoloOption', event);
            }
        }
    };

    private hideCallout = () => {
        this.setState({
            isCalloutVisible: false,
        });
    };

    constructor(props: EditorHeaderProps) {
        super(props);
        this.state = {
            isCalloutVisible: false,
        };
    }

    public render() {
        return (
            <div ref={this.myRef}>
                <div className="editorHeader">
                    <span>{this.editorHeaderData[this.props.type].name}</span>
                    <span className="editorIcons">
                        <span
                            className="editorHelpIcon amd-icon amd-icon-help amd-blue-icon"
                            onClick={this.toggleCalloutVisibility}
                            onKeyDown={this.handleKeyDown}
                            tabIndex={0}
                            aria-label={`${
                                this.editorHeaderData[this.props.type].name
                            } Help`}
                            role="button"
                        />
                        <span
                            onClick={this.props.onCloseIconClick}
                            onKeyDown={(e) => {
                                const mapper = keyBoardToClickEventMapper(
                                    this.props.onCloseIconClick,
                                );
                                mapper(e.nativeEvent);
                            }}
                            className="editorCloseIcon amd-icon amd-icon-close amd-blue-icon"
                            tabIndex={0}
                            aria-label="Close"
                            role="button"
                        />
                    </span>
                </div>
                <Callout
                    directionalHint={12}
                    gapSpace={1}
                    target={this.myRef.current}
                    onDismiss={this.hideCallout}
                    setInitialFocus={false}
                    hidden={!this.state.isCalloutVisible}
                >
                    {this.editorHeaderData[this.props.type].content}
                </Callout>
            </div>
        );
    }
}

const VisualTreeEditorHelp = () => {
    return (
        <div className="helpCallout">
            <div className="helpCalloutHeader">Visual Tree Editor</div>
            <div className="helpCalloutBody">
                <div className="helpCalloutText">
                    <span>
                        <p>
                            With the Visual tree Editor you can edit the
                            structure of the card and the order/sequence of the
                            elements in the card. Parent containers can by
                            collapsed or expanded to show the child elements
                            within this list.
                        </p>
                    </span>
                    <span>
                        <p>
                            <span className="bold">Move elements up/down</span>
                            <br />
                            <span>
                                You can move the placement of an element in the
                                card by dragging it up or down in the Visual
                                Tree Editor.
                            </span>
                        </p>
                    </span>
                    <span>
                        <p>
                            <span className="bold">
                                Copy/Paste or Delete elements
                            </span>
                            <br />
                            <span>
                                You can now duplicate any element in your card
                                by simply selecting it in this editor and Copy
                                (Ctrl - C) Pasting (Ctrl - V) it where needed.
                                You can also delete an element with the delete
                                button.
                            </span>
                        </p>
                    </span>
                </div>
                <div className="helpCalloutImage">
                    <img src={visualTreeHelp} width="141px" alt="" />
                </div>
            </div>
        </div>
    );
};

const PropertyEditorHelp = () => {
    return (
        <div className="helpCallout">
            <div className="helpCalloutHeader">Property Editor</div>
            <div className="helpCalloutBody">
                <div className="helpCalloutText">
                    <span>
                        When an element in the card is selected, you can
                        customize a element in your card in the Property Editor.
                    </span>
                    <span>
                        <p>
                            Every element has a specific set of properties that
                            can be edited. These elements by default have
                            certain placeholder properties assigned to them.
                            Until edited, these elements retain the default
                            properties.
                        </p>
                    </span>
                </div>
                <div className="helpCalloutImage">
                    <img src={propertyHelp} width="141px" alt="" />
                </div>
            </div>
        </div>
    );
};
