import BaseInventoryItem from '../ACDesigner/BaseInventoryItem';
import { CardElement, ITypeRegistration } from 'adaptivecards';
import CardDesignerSurface from '../ACDesigner/CardDesignerSurface';
import CardElementPeer from '../ACDesigner/DesignerPeers/CardElementPeer';
import { findElementTypeRegistration } from './InventoryUtil';
import { generateUUID } from '../utilities/Utils';

export default class ElementInventoryItem extends BaseInventoryItem {
    readonly typeName: string;

    readonly typeRegistration: ITypeRegistration<CardElement>;

    constructor(element: HTMLElement, typeName: string) {
        super(element);
        this.typeName = typeName;
        this.typeRegistration = findElementTypeRegistration(this.typeName);
    }

    createPeer(designer: CardDesignerSurface): CardElementPeer {
        const element = this.typeRegistration.createInstance();
        element.id = generateUUID();
        const peer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
            designer,
            null,
            element
        );
        peer.initializeCardElement();

        return peer;
    }
}
