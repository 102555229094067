export function createErrorPaneIcon(): HTMLElement {
    let circleMinus: HTMLElement = document.createElement('span');
    circleMinus.classList.add('circleMinus');
    circleMinus.classList.add('acd-icon');
    return circleMinus;
}

export function createErrorPaneElement(message: string): HTMLElement {
    let errorElement = document.createElement('div');
    errorElement.className = 'acd-error-pane-message';
    errorElement.innerText = message;
    return errorElement;
}

export function renderErrorPane(render: boolean, message: string) {
    let errorPane = document.getElementById('errorPane');
    errorPane.innerHTML = '';
    if (render) {
        errorPane.style.display = 'block';
        errorPane.appendChild(createErrorPaneIcon());
        errorPane.appendChild(createErrorPaneElement('[Error]' + message));
    } else {
        errorPane.style.display = 'none';
    }
    if (errorPane.childElementCount > 0) {
        errorPane.classList.remove('acd-hidden');
    } else {
        errorPane.classList.add('acd-hidden');
    }
}
