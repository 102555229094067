import ChoicePropertyEditor from './ChoicePropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import { SizeAndUnit, SizeUnit } from 'adaptivecards';

export default class ColumnWidthPropertyEditor extends ChoicePropertyEditor {
    protected getPropertyValue(context: PropertySheetContext): any {
        if (context.target[this.propertyName] instanceof SizeAndUnit) {
            if (context.target[this.propertyName].unit === SizeUnit.Pixel) {
                return 'pixels';
            } else {
                return 'weighted';
            }
        } else {
            return context.target[this.propertyName].toString();
        }
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        switch (value) {
            case 'auto':
                context.target[this.propertyName] = 'auto';
                break;
            case 'pixels':
                context.target[this.propertyName] = new SizeAndUnit(
                    50,
                    SizeUnit.Pixel
                );
                break;
            case 'weighted':
                context.target[this.propertyName] = new SizeAndUnit(
                    50,
                    SizeUnit.Weight
                );
                break;
            case 'stretch':
            default:
                context.target[this.propertyName] = 'stretch';
                break;
        }
    }
}
