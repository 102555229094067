import TypedActionPeer from './TypedActionPeer';
import { HttpAction, HttpHeader } from 'adaptivecards';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import NameValuePairPropertyEditor from '../PropertyEditor/NameValuePairPropertyEditor';
import { generateNameValue } from './DesignerPeersUtils';
import DescriptionPropertyEditor from '../PropertyEditor/DescriptionPropertyEditor';

export default class HttpActionPeer extends TypedActionPeer<HttpAction> {
    static readonly description: string =
        'This Action button sends a response or data to the server. This is typically used for actions such as "Submit", "Approve", "Decline" etc. ';

    static readonly urlProperty = new StringPropertyEditor(
        Versions.v1_0,
        'url',
        'Url',
        'Insert the URL/server address where this request will be made.'
    );
    static readonly bodyProperty = new StringPropertyEditor(
        Versions.v1_0,
        'body',
        'Body',
        'Insert the data that needs to be sent to the server.',
        true
    );
    static readonly headersProperty = new NameValuePairPropertyEditor(
        Versions.v1_0,
        'headers',
        'name',
        'value',
        (name: string, value: string) => {
            return new HttpHeader(name, value);
        },
        (nameValuePair: Array<{ name: string; value: string }>) => {
            let name: string = 'Header';
            let value: string = 'Header';
            return generateNameValue(nameValuePair, name, value);
        },
        'Insert the name for the header which will be sent with the request.',
        'Insert the value for the header which will be sent with the request.',
        'Key',
        'Value',
        'Enter header key',
        'Enter header value',
        'Add a new header',
        'This action has no header.'
    );

    static readonly descriptionProperty = new DescriptionPropertyEditor(
        '*',
        HttpActionPeer.description
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            PropertySheetCategory.DefaultCategory,
            HttpActionPeer.descriptionProperty
        );
        super.populatePropertySheet(propertySheet, defaultCategory);

        propertySheet.add(defaultCategory, HttpActionPeer.urlProperty);
        this.action.method = 'POST';
        propertySheet.add(defaultCategory, HttpActionPeer.bodyProperty);

        propertySheet.add('HTTP headers', HttpActionPeer.headersProperty);
    }
}
