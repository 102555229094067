import {
    ColumnSet,
    Column,
    setProperty,
    IValidationError
} from 'adaptivecards';

import {
    getDefaultPadding,
    getPaddingValue as getPaddingValueUtil,
    parsedPadding
} from './CustomElementUtils';

const getEffectivePadding = function() {
    ColumnSet.prototype.getEffectivePadding = function() {
        const padding = this.padding;
        return padding ? padding : getDefaultPadding();
    };
};

const getPaddingValue = function(columnSet: ColumnSet): Object | string {
    return getPaddingValueUtil(columnSet.padding);
};

const toJSON = () => {
    const prototypeToJSON = ColumnSet.prototype.toJSON;
    ColumnSet.prototype.toJSON = function() {
        const result = prototypeToJSON.call(this);
        setProperty(result, 'padding', getPaddingValue(this));
        return result;
    };
};

const parse = () => {
    const prototypeParse = ColumnSet.prototype.parse;
    ColumnSet.prototype.parse = function(
        json: any,
        errors?: Array<IValidationError>
    ) {
        prototypeParse.call(this, json, errors);
        this.padding = parsedPadding(json);
    };
};

const indexOf = () => {
    ColumnSet.prototype['indexOf'] = function(cardElement: Column): number {
        return this['_columns'].indexOf(cardElement);
    };
};

const insertItemAt = function() {
    ColumnSet.prototype['insertItemAt'] = function(
        column: Column,
        index: number,
        forceInsert: boolean
    ) {
        if (!column.parent || forceInsert) {
            if (index < 0 || index >= this['_columns'].length) {
                this['_columns'].push(column);
            } else {
                this['_columns'].splice(index, 0, column);
            }
            column.setParent(this);
        } else {
            throw new Error(
                'The element already belongs to another container.'
            );
        }
    };
};

const insertItemBefore = () => {
    ColumnSet.prototype['insertItemBefore'] = function(
        column: Column,
        insertBefore: Column
    ) {
        this['insertItemAt'](
            column,
            this['_columns'].indexOf(insertBefore),
            false
        );
    };
};

const insertItemAfter = () => {
    ColumnSet.prototype['insertItemAfter'] = function(
        item: Column,
        insertAfter: Column
    ) {
        this['insertItemAt'](
            item,
            this['_columns'].indexOf(insertAfter) + 1,
            false
        );
    };
};

export const initialize = () => {
    getEffectivePadding();
    toJSON();
    parse();
    indexOf();
    insertItemAt();
    insertItemBefore();
    insertItemAfter();
};
