import DesignerPeer from './DesignerPeer';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';

export default abstract class CardObjectPeer extends DesignerPeer {
    static readonly idProperty = new StringPropertyEditor(
        Versions.v1_0,
        'id',
        'Element ID',
        'Assign an ID to this element if you need to refer to this specific element in the card (for toggling visibility or other interactions). '
    );
}
