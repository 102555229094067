import {
    TextBlock,
    setProperty,
    IValidationError,
    getStringValue
} from 'adaptivecards';

export default class CustomTextBlock extends TextBlock {
    private style: string;

    protected internalRender(): HTMLElement {
        let renderedElement: HTMLElement = super.internalRender();

        if (renderedElement && this.style && this.style === 'heading') {
            renderedElement.setAttribute('role', 'heading');
            renderedElement.setAttribute('aria-level', '1');
        }
        return renderedElement;
    }

    toJSON(): any {
        let result = super.toJSON();
        if (this.style) {
            setProperty(result, 'style', this.style);
        }
        return result;
    }

    parse(json: any, errors?: Array<IValidationError>) {
        super.parse(json, errors);
        this.style = getStringValue(json['style']);
    }
}
