import CardDesignerSurface from './CardDesignerSurface';
import DraggableElement from './DraggableElement';
import { IPoint } from './Miscellaneous';
import DesignerPeerTreeItem from './DesignerPeers/DesignerPeerTreeItem';
import { AllowedChildItems } from './CardConstants';
import DesignerPeer from './DesignerPeers/DesignerPeer';

enum PositionWRTBox {
    ABOVE = -1,
    INSIDE = 0,
    BELOW = 1,
}

interface DropTargetItem {
    treeItem: DesignerPeerTreeItem;
    newChildIndex: number;
}

export default class TreeViewSurface {
    private _treeViewElement: HTMLElement;
    private _designerSurface: CardDesignerSurface;
    private _draggedTreeItem: DesignerPeerTreeItem;
    private _draggedTreeItemVisual: HTMLElement;
    private _currentMousePosition: IPoint;
    private _subTreeExpansionTimer: number;

    private _dropTargetLine: HTMLElement;
    private _dropTargetItem: DropTargetItem;

    private _curSearchIndexInTree: number = -1;

    private addDropTargetLine() {
        if (this._dropTargetLine) {
            return;
        }
        this._dropTargetLine = document.createElement('div');
        this._dropTargetLine.id = 'treeNodeDropTargetLine';
        this._dropTargetLine.style.position = 'relative';
        this._dropTargetLine.style.width = 'auto';
        this._dropTargetLine.style.height = '0px';
        this._dropTargetLine.style.outline = '1px solid #000';
        this._dropTargetLine.style.pointerEvents = 'none';
        this._dropTargetLine.style.zIndex = '1';
        this._dropTargetLine.style.display = 'none';
        this._treeViewElement.prepend(this._dropTargetLine);
    }

    private getCursorPositionWRTTreeViewRange(curPos: IPoint): PositionWRTBox {
        const treeViewBox = this._treeViewElement.getBoundingClientRect();
        if (curPos.y >= treeViewBox.top && curPos.y <= treeViewBox.bottom) {
            return PositionWRTBox.INSIDE;
        } else if (curPos.y < treeViewBox.top) {
            return PositionWRTBox.ABOVE;
        } else {
            return PositionWRTBox.BELOW;
        }
    }

    private getPointOnElementIndex(
        items: Element[],
        start: number,
        end: number,
        point: IPoint,
    ): number {
        if (start <= end) {
            const mid = Math.floor(start + (end - start) / 2);
            const midBox = items[mid].getBoundingClientRect();
            if (point.y >= midBox.top && point.y <= midBox.bottom) {
                return mid;
            }
            if (midBox.top > point.y) {
                return this.getPointOnElementIndex(
                    items,
                    start,
                    mid - 1,
                    point,
                );
            }
            if (midBox.bottom < point.y) {
                return this.getPointOnElementIndex(items, mid + 1, end, point);
            }
        }
        return -1;
    }

    private findDropCandidateTreeItem(
        root: DesignerPeerTreeItem,
        cursorOverElementIndex: number,
    ): DesignerPeerTreeItem {
        this._curSearchIndexInTree++;
        if (cursorOverElementIndex === this._curSearchIndexInTree) {
            return root;
        }
        if (root.getChildCount() > 0 && root.isExpanded) {
            for (let i = 0; i < root.getChildCount(); i++) {
                const item = this.findDropCandidateTreeItem(
                    root.getChildAt(i) as DesignerPeerTreeItem,
                    cursorOverElementIndex,
                );
                if (item) {
                    return item;
                }
            }
        }
        return null;
    }

    private getDropTargetItem(curPos: IPoint): DropTargetItem {
        const treeItemsInDOM: Element[] = Array.from(
            this._treeViewElement.getElementsByClassName('acd-tree-item'),
        );
        const visibleTreeItemsInDOM: Element[] = treeItemsInDOM.filter(
            (item) => {
                return item.closest('.acd-hidden') === null;
            },
        );
        const cursorOverElementIndex: number = this.getPointOnElementIndex(
            visibleTreeItemsInDOM,
            0,
            visibleTreeItemsInDOM.length - 1,
            curPos,
        );
        if (cursorOverElementIndex === -1) {
            return null;
        }
        const candidateTreeItem: DesignerPeerTreeItem =
            this.findDropCandidateTreeItem(
                this._designerSurface.rootPeer.treeItem,
                cursorOverElementIndex,
            );
        if (candidateTreeItem === null) {
            return null;
        }
        return this.internalGetDropTargetItem(
            curPos,
            candidateTreeItem,
            visibleTreeItemsInDOM[cursorOverElementIndex] as HTMLElement,
        );
    }

    private internalGetDropTargetItem(
        curPos: IPoint,
        candidateTreeItem: DesignerPeerTreeItem,
        candidateTreeElement: HTMLElement,
    ): DropTargetItem {
        const candidateBBox = candidateTreeElement.getBoundingClientRect();
        const candidateCenter = candidateBBox.top + candidateBBox.height / 2;
        const isCursorOnUpperHalf = curPos.y < candidateCenter;
        let dropTargetItem: DropTargetItem = null;
        if (isCursorOnUpperHalf) {
            if (candidateTreeItem.owner.parent === null) {
                return null;
            }
            const candidateParentTreeItem: DesignerPeerTreeItem =
                candidateTreeItem.owner.parent.treeItem;
            dropTargetItem = {
                treeItem: candidateParentTreeItem,
                newChildIndex: -1,
            };
            for (let i = 0; i < candidateParentTreeItem.getChildCount(); i++) {
                if (
                    candidateParentTreeItem.getChildAt(i) === candidateTreeItem
                ) {
                    dropTargetItem.newChildIndex = i;
                    break;
                }
            }
        } else {
            if (candidateTreeItem.isExpanded) {
                dropTargetItem = {
                    treeItem: candidateTreeItem,
                    newChildIndex: 0,
                };
            } else {
                const candidateParentTreeItem: DesignerPeerTreeItem =
                    candidateTreeItem.owner.parent.treeItem;
                dropTargetItem = {
                    treeItem: candidateParentTreeItem,
                    newChildIndex: -1,
                };
                // Count - 1 is because if the current candidate is last child of its parent in such case we need to add the new element
                // after last element so we need to set the newChildIndex as -1 which the same by default so we are neglecting the last element.
                for (
                    let i = 0;
                    i < candidateParentTreeItem.getChildCount() - 1;
                    i++
                ) {
                    if (
                        candidateParentTreeItem.getChildAt(i) ===
                        candidateTreeItem
                    ) {
                        dropTargetItem.newChildIndex = i + 1;
                        break;
                    }
                }
            }
        }
        return dropTargetItem;
    }

    private isValidDropTarget(dropTargetItem: DropTargetItem): boolean {
        if (dropTargetItem === null) {
            return false;
        }
        const draggedSourceTreeElement: HTMLElement =
            this._treeViewElement.querySelector('.acd-tree-item.selected');
        if (draggedSourceTreeElement === null) {
            return false;
        }

        // parentElement of draggedSourceTreeElement is taken into consideration is because in case if the element is dragged to its immediate child positions, in such case the parent (which is the draggedSourceTreeElement) will be the dropTargetItem.
        const draggedSubTreeElements: Element[] = Array.from(
            draggedSourceTreeElement.parentElement.querySelectorAll(
                '.acd-tree-item',
            ),
        );
        if (
            draggedSubTreeElements.includes(
                dropTargetItem.treeItem.treeItemElement,
            )
        ) {
            return false;
        }

        const targetType = dropTargetItem.treeItem.owner
            .getCardObject()
            .getJsonTypeName();
        const draggedItemType = this._draggedTreeItem.owner
            .getCardObject()
            .getJsonTypeName();
        if (!AllowedChildItems.get(targetType).includes(draggedItemType)) {
            return false;
        }

        if (
            targetType === 'ColumnSet' ||
            targetType === 'ImageSet' ||
            targetType === 'ActionSet'
        ) {
            dropTargetItem.newChildIndex = -1;
        }

        return true;
    }

    private handleOnDrag(curPos: IPoint) {
        this._curSearchIndexInTree = -1;
        const position: PositionWRTBox =
            this.getCursorPositionWRTTreeViewRange(curPos);
        if (position !== PositionWRTBox.INSIDE) {
            const scrollDelta = 5;
            this._treeViewElement.scrollTop =
                position === PositionWRTBox.ABOVE
                    ? this._treeViewElement.scrollTop - scrollDelta
                    : this._treeViewElement.scrollTop + scrollDelta;
        }

        const dropTargetItem = this.getDropTargetItem(curPos);

        if (
            dropTargetItem === null ||
            !this.isValidDropTarget(dropTargetItem)
        ) {
            return;
        }

        this._dropTargetItem = dropTargetItem;

        const expansionTimeout = 500;
        clearTimeout(this._subTreeExpansionTimer);
        const targetTreeItem = this.getTargetTreeItemFromDropTarget(
            this._dropTargetItem,
        );
        if (targetTreeItem && !targetTreeItem.isExpanded) {
            this._subTreeExpansionTimer = window.setTimeout(() => {
                targetTreeItem.expand();
                this.handleOnDrag(curPos);
            }, expansionTimeout);
        }

        // NOTE: Need to handle case where the drag lines will get hidden for top/bottom elements upper/lower half positions.

        this.addDropTargetLine();
        this._dropTargetLine.style.display = 'block';

        // Add as a last child if the new index is -1
        const treeViewBBox = this._treeViewElement.getBoundingClientRect();
        if (this._dropTargetItem.newChildIndex === 0) {
            const parent = this._dropTargetItem.treeItem;
            this._dropTargetLine.style.top =
                this._treeViewElement.scrollTop +
                parent.treeItemElement.getBoundingClientRect().bottom -
                treeViewBBox.top -
                2 +
                'px';
        } else if (this._dropTargetItem.newChildIndex === -1) {
            const lastChild = this._dropTargetItem.treeItem.getChildAt(
                this._dropTargetItem.treeItem.getChildCount() - 1,
            );
            this._dropTargetLine.style.top =
                this._treeViewElement.scrollTop +
                lastChild.treeItemElement.getBoundingClientRect().bottom -
                treeViewBBox.top -
                2 +
                'px';
        } else {
            const targetChild = this._dropTargetItem.treeItem.getChildAt(
                this._dropTargetItem.newChildIndex,
            );
            this._dropTargetLine.style.top =
                this._treeViewElement.scrollTop +
                targetChild.treeItemElement.getBoundingClientRect().top -
                treeViewBBox.top +
                'px';
        }
        const widthOfChevron = 18;
        this._dropTargetLine.style.marginLeft =
            this._dropTargetItem.treeItem.indentationLevelIncrement *
                this._dropTargetItem.treeItem.level +
            1 +
            widthOfChevron +
            'px';
    }

    private handlePointerMove(e: PointerEvent) {
        this._currentMousePosition = { x: e.x, y: e.y };

        if (this._draggedTreeItemVisual) {
            this._draggedTreeItemVisual.style.left =
                this._currentMousePosition.x - 10 + 'px';
            this._draggedTreeItemVisual.style.top =
                this._currentMousePosition.y - 10 + 'px';
            const isPointerOver = this.isPointerOver(
                this._currentMousePosition.x,
                this._currentMousePosition.y,
            );
            this._draggedTreeItemVisual.style.cursor = isPointerOver
                ? 'grabbing'
                : 'not-allowed';
            this.handleOnDrag(this._currentMousePosition);
        }
    }

    private handlerPointerUp(e: PointerEvent) {
        this.handleOnDrop(e);
        this.endDrag();
    }

    private isPointerOver(x: number, y: number) {
        let clientRect = this._treeViewElement.getBoundingClientRect();

        return (
            x >= clientRect.left &&
            x <= clientRect.right &&
            y >= clientRect.top &&
            y <= clientRect.bottom
        );
    }

    private getTargetTreeItemFromDropTarget(
        dropTargetItem: DropTargetItem,
    ): DesignerPeerTreeItem {
        let childIndex = -1;
        if (dropTargetItem.newChildIndex === -1) {
            childIndex = dropTargetItem.treeItem.getChildCount() - 1;
        } else {
            childIndex = dropTargetItem.newChildIndex;
        }
        let targetTreeItem: DesignerPeerTreeItem = null;
        const child = dropTargetItem.treeItem.owner.getChildAt(childIndex);
        if (child) {
            targetTreeItem = child.treeItem;
        }
        return targetTreeItem;
    }

    private handleOnDrop(e: PointerEvent) {
        if (!this._draggedTreeItem || !this._dropTargetItem) {
            return;
        }

        const isPointerOver = this.isPointerOver(
            this._currentMousePosition.x,
            this._currentMousePosition.y,
        );

        if (!isPointerOver) {
            return;
        }

        const targetTreeItem = this.getTargetTreeItemFromDropTarget(
            this._dropTargetItem,
        );

        if (targetTreeItem === this._draggedTreeItem) {
            return;
        }
        if (this._draggedTreeItem.owner.parent) {
            this._draggedTreeItem.owner.remove(true, false);
            this._draggedTreeItem.owner.parent.removeChild(
                this._draggedTreeItem.owner,
            );
        }

        let peerToBeInserted: DesignerPeer =
            this._dropTargetItem.treeItem.owner.getPeerToInsert(
                this._draggedTreeItem.owner,
            );

        // Inserting as a first child (to a parent having 0 ro more children)
        if (this._dropTargetItem.newChildIndex === 0) {
            this._dropTargetItem.treeItem.owner.insertItemAfter(
                peerToBeInserted,
                null,
            );
        } else if (this._dropTargetItem.newChildIndex === -1) {
            // Inserting as a last child
            this._dropTargetItem.treeItem.owner.insertItemBefore(
                peerToBeInserted,
                null,
            );
        } else {
            this._dropTargetItem.treeItem.owner.insertItemBefore(
                peerToBeInserted,
                targetTreeItem.owner,
            );
        }

        if (peerToBeInserted) {
            this._dropTargetItem.treeItem.owner.insertChild(
                peerToBeInserted,
                this._dropTargetItem.newChildIndex,
            );
        }
        this._dropTargetItem.treeItem.owner.changed(false);
    }

    private bindEvents() {
        window.addEventListener('pointermove', this.handlePointerMove);
        window.addEventListener('pointerup', this.handlerPointerUp);
        this._treeViewElement.addEventListener(
            'contextmenu',
            (ev) => {
                ev.preventDefault();
                if (this._designerSurface.selectedPeer) {
                    this._designerSurface.showCustomMenu(ev);
                }
                return false;
            },
            false,
        );

        // this.treeViewElement.addEventListener('keydown', (e: KeyboardEvent) => {
        //     if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        //         const selected =this.treeViewElement.querySelector('.acd-tree-item.selected');
        //         if()
        //     }
        // });
    }

    private bindOnStartDrag(root: DesignerPeerTreeItem) {
        if (root.owner.isDraggable()) {
            root.onStartDrag = (sender: DraggableElement) => {
                this._treeViewElement.classList.add('dragging');
                this._draggedTreeItem = sender as DesignerPeerTreeItem;

                this._draggedTreeItemVisual =
                    this._draggedTreeItem.renderDragVisual();
                this._draggedTreeItemVisual.style.position = 'absolute';
                this._draggedTreeItemVisual.style.left =
                    this._currentMousePosition.x + 'px';
                this._draggedTreeItemVisual.style.top =
                    this._currentMousePosition.y + 'px';
                this._draggedTreeItemVisual.style.zIndex = '1';

                document.body.appendChild(this._draggedTreeItemVisual);
            };
        }

        for (let i = 0; i < root.getChildCount(); i++) {
            this.bindOnStartDrag(root.getChildAt(i) as DesignerPeerTreeItem);
        }
    }

    private endDrag() {
        if (this._draggedTreeItem) {
            this._treeViewElement.classList.remove('dragging');
            this._draggedTreeItem.endDrag();
            this._draggedTreeItemVisual.remove();
            if (this._dropTargetLine) {
                this._dropTargetLine.remove();
            }
            clearTimeout(this._subTreeExpansionTimer);
            this._draggedTreeItem = null;
            this._draggedTreeItemVisual = null;
            this._dropTargetLine = null;
            this._dropTargetItem = null;
            this._subTreeExpansionTimer = null;
        }
    }

    constructor(treeViewElement: HTMLElement) {
        this._treeViewElement = treeViewElement;
        this.handlePointerMove = this.handlePointerMove.bind(this);
        this.handlerPointerUp = this.handlerPointerUp.bind(this);
        this.bindEvents();
    }

    public detach() {
        window.removeEventListener('pointermove', this.handlePointerMove);
        window.removeEventListener('pointerup', this.handlerPointerUp);
    }

    public handleEscape(): boolean {
        if (this._draggedTreeItem) {
            this.endDrag();
            return true;
        }
        return false;
    }

    public buildTreeView(designerSurface: CardDesignerSurface) {
        if (this._treeViewElement) {
            this._designerSurface = designerSurface;
            this._treeViewElement.innerHTML = '';
            this._treeViewElement.appendChild(
                this._designerSurface.rootPeer.treeItem.render(),
            );
            this.bindOnStartDrag(this._designerSurface.rootPeer.treeItem);
        }
    }

    get treeViewElement(): HTMLElement {
        return this._treeViewElement;
    }
}
