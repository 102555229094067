import React from 'react';
import { Checkbox } from '@fluentui/react';

interface CheckboxState {
    isChecked: boolean;
}

interface CheckboxProps {
    isChecked: boolean;
    isChangedCallback(isChecked: string): void;
}

export default class CustomCheckbox extends React.Component<
    CheckboxProps,
    CheckboxState
> {
    constructor(props) {
        super(props);
        this.state = { isChecked: this.props.isChecked };
    }

    public render(): JSX.Element {
        return (
            <div>
                <Checkbox
                    defaultChecked={this.state.isChecked}
                    onChange={this.onChange}
                />
            </div>
        );
    }

    private onChange = (
        ev: React.FormEvent<HTMLElement>,
        isChecked: boolean,
    ): void => {
        this.setState({ isChecked: isChecked });
        this.props.isChangedCallback(isChecked === true ? 'true' : 'false');
    };
}
