import PropertySheetEntry from './PropertySheetEntry';
import PropertySheetContext from './PropertySheetContext';
import { isVersionLessOrEqual, TargetVersion } from '../Shared';
import GeneratePropertyFields from './GeneratePropertyFields';

export default class CompoundPropertyEditor extends PropertySheetEntry {
    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');
        let separatorTop: HTMLElement = document.createElement('div');
        separatorTop.classList.add('separatorPropertyEditor');
        container.appendChild(separatorTop);
        let labelElement: HTMLElement = new GeneratePropertyFields().generateLabels(
            this.label
        );
        labelElement.classList.add('property');
        container.appendChild(labelElement);
        for (let entry of this.entries) {
            if (
                isVersionLessOrEqual(entry.targetVersion, context.targetVersion)
            ) {
                container.appendChild(
                    entry.render(
                        new PropertySheetContext(
                            context.targetVersion,
                            context.peer,
                            context.target[this.propertyName]
                        )
                    )
                );
            }
        }
        let separatorBottom: HTMLElement = document.createElement('div');
        separatorBottom.classList.add('separatorPropertyEditor');
        container.appendChild(separatorBottom);
        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly entries: PropertySheetEntry[] = []
    ) {
        super(targetVersion);
    }
}
