import InputPeer from './InputPeer';
import CustomDateInput from '../../components/ActionableMessages/CustomDateInput';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import { DatePropertyEditor } from '../PropertyEditor/DatePropertyEditor';
import { Versions } from '../Shared';
import CardElementPeer from './CardElementPeer';

export default class DateInputPeer extends InputPeer<CustomDateInput> {
    static readonly dateProperty = new DatePropertyEditor(
        Versions.v1_0,
        'defaultValue',
        'Default Value',
        'Choose a date that should be displayed by default here before the user chooses a date.'
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            InputPeer.idProperty,
            InputPeer.isVisibleProperty,
            DateInputPeer.dateProperty,
            InputPeer.isRequiredProperty,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty
        );
    }
}
