import BooleanPropertyEditor from './BooleanPropertyEditor';
import PropertySheetContext from './PropertySheetContext';

export default class BooleanStringPropertyEditor extends BooleanPropertyEditor {
    protected getPropertyValue(context: PropertySheetContext): any {
        let v = context.target[this.propertyName];
        return v;
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = value;
    }
}
