import SingleInputPropertyEditor from './SingleInputPropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import { keyValuePair } from '../DesignerPeers/DesignerPeersUtils';
import GeneratePropertyFields from './GeneratePropertyFields';
import { TargetVersion } from '../Shared';

export default class EnumPropertyEditor extends SingleInputPropertyEditor {
    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = parseInt(value, 10);
    }

    protected createInput(
        context: PropertySheetContext,
        eventCallback
    ): HTMLElement {
        let entries: keyValuePair = {};
        for (let key in this.enumType) {
            let v = parseInt(key, 10);

            if (!isNaN(v)) {
                entries[this.enumType[key]] = key;
            }
        }

        let defaultValue = '0';
        if (this.getPropertyValue(context))
            defaultValue = this.getPropertyValue(context).toString();
        let input: HTMLElement = new GeneratePropertyFields().generateDropDown(
            entries,
            eventCallback,
            defaultValue,
            this.label
        );
        return input;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly enumType: { [s: number]: string },
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(
            targetVersion,
            propertyName,
            label,
            tooltipContent,
            causesPropertySheetRefresh
        );
    }
}
