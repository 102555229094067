import TypedCardElementPeer from './TypedCardElementPeer';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import CompoundPropertyEditor from '../PropertyEditor/CompoundPropertyEditor';
import EnumPropertyEditor from '../PropertyEditor/EnumPropertyEditor';
import CardDesignerSurface from '../CardDesignerSurface';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CardElementPeer from './CardElementPeer';
import DesignerPeer from './DesignerPeer';
import { ActionSet, ColumnSet, AdaptiveCard } from 'adaptivecards';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';
import { Padding, Fit } from './DesignerPeersUtils';
import ActionPeer from './ActionPeer';
import { findElementTypeRegistration } from '../../inventory/InventoryUtil';
import ColumnPeer from './ColumnPeer';
import PaddingPropertyEditor from '../PropertyEditor/PaddingPropertyEditor';

export default class AdaptiveCardPeer extends TypedCardElementPeer<
    AdaptiveCard
> {
    static readonly originatorProperty = new StringPropertyEditor(
        Versions.v1_0,
        'originator',
        'Originator ID',
        'This ID defines the identity of the card sender. ',
        false
    );
    static readonly isRtlProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'rtl',
        'RTL',
        'Checking this property will show the card in Right to Left fashion.'
    );
    static readonly backgroundImageProperty = new CompoundPropertyEditor(
        Versions.v1_0,
        'backgroundImage',
        'Background Image',
        [
            new StringPropertyEditor(
                Versions.v1_0,
                'url',
                'Image URL',
                'Insert the url for the image that will be used as the background.'
            ),
            new EnumPropertyEditor(
                Versions.v1_2,
                'fillMode',
                'Fit',
                Fit,
                'Select the way this image will fit the container. '
            )
        ]
    );
    static readonly paddingProperty = new PaddingPropertyEditor(
        Versions.v1_0,
        'padding',
        'Padding',
        Padding,
        'Defines the internal margins within the container. '
    );

    protected internalRemove(): boolean {
        return true;
    }

    isDraggable(): boolean {
        return false;
    }

    canBeRemoved(): boolean {
        return false;
    }

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(defaultCategory, CardElementPeer.idProperty);

        if (!this.parent) {
            propertySheet.add(
                defaultCategory,
                AdaptiveCardPeer.originatorProperty
            );
        }

        propertySheet.add(
            defaultCategory,
            AdaptiveCardPeer.paddingProperty,
            AdaptiveCardPeer.backgroundImageProperty,
            AdaptiveCardPeer.isRtlProperty
        );
    }

    getPeerToInsert(peer: DesignerPeer): DesignerPeer {
        let insertPeer: DesignerPeer = peer;
        if (peer instanceof ActionPeer) {
            const typeRegistration = findElementTypeRegistration('ActionSet');
            const actionSet: ActionSet = typeRegistration.createInstance() as ActionSet;
            actionSet.addAction(peer.action);
            insertPeer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                actionSet
            );
        } else if (peer instanceof ColumnPeer) {
            const typeRegistration = findElementTypeRegistration('ColumnSet');
            const columnSet: ColumnSet = typeRegistration.createInstance() as ColumnSet;
            columnSet.addColumn(peer.cardElement);
            insertPeer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
                this.designerSurface,
                this,
                columnSet
            );
        }
        return insertPeer;
    }

    insertItemAfter(peer: DesignerPeer, refPeer: DesignerPeer) {
        const item = peer.getCardObject() as any;
        const refItem = refPeer ? (refPeer.getCardObject() as any) : null;
        this.cardElement.insertItemAfter(item, refItem);
    }

    insertItemBefore(peer: DesignerPeer, refPeer: DesignerPeer) {
        const item = peer.getCardObject() as any;
        const refItem = refPeer ? (refPeer.getCardObject() as any) : null;
        this.cardElement.insertItemBefore(item, refItem);
    }
}
