export interface subCommands {
    key: string;
    value: string;
    execute(): void;
}

export interface addChildCommand {
    name: string;
    subCommands: subCommands[];
    execute(): void;
}

export interface onChangeCallbackType {
    (value?: string): void;
}

export interface keyValuePair {
    [key: string]: string;
}

export enum Padding {
    None,
    Small,
    Default,
    Medium,
    Large,
    ExtraLarge,
    Custom = 7
}

export enum SubPadding {
    None,
    Small,
    Default,
    Medium,
    Large,
    ExtraLarge
}

export enum ImageSize {
    Auto,
    Stretch,
    Small,
    Medium,
    Large,
    Custom
}

export enum Spacing {
    None,
    Small,
    Default,
    Medium,
    Large,
    ExtraLarge
}

export enum Fit {
    'Cover (Stretch)',
    'Repeat Horizontally',
    'Repeat Vertically',
    'Repeat (Tile)'
}

export enum ToggleInputState {
    'Selected' = 'true',
    'Not Selected' = 'false'
}

export const notSet: string = 'Not Set';
export const notActionable: string = 'Not Actionable';

export function generateNameValue(
    nameValuePair: Array<{ name: string; value: string }>,
    name: string,
    value: string
): { name: string; value: string } {
    let nameValuePairsLength: number = nameValuePair.length;
    let listCounters: Array<number> = [];
    let nameRegExp = new RegExp('^' + name + '\\ (\\d+)$', 'i');
    let valueRegExp = new RegExp('^' + value + '\\ (\\d+)$', 'i');
    for (let i = 0; i < nameValuePairsLength; i++) {
        if (nameRegExp.test(nameValuePair[i].name)) {
            listCounters.push(
                parseInt(nameRegExp.exec(nameValuePair[i].name)[1])
            );
        }
        if (valueRegExp.test(nameValuePair[i].value)) {
            listCounters.push(
                parseInt(valueRegExp.exec(nameValuePair[i].value)[1])
            );
        }
    }
    let counter: number = nameValuePairsLength + 1;
    while (true) {
        if (listCounters.indexOf(counter) !== -1) counter++;
        else break;
    }
    name = name + ' ' + counter.toString();
    value = value + ' ' + counter.toString();
    return { name: name, value: value };
}
