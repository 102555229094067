import PropertySheetEntry from './PropertySheetEntry';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';
import { TargetVersion } from '../Shared';

interface INameValuePair {
    name: string;
    value: string;
}

export default class NameValuePairPropertyEditor extends PropertySheetEntry {
    private collectionChanged(
        context: PropertySheetContext,
        nameValuePairs: INameValuePair[],
        refreshPropertySheet: boolean
    ) {
        context.target[this.collectionPropertyName] = [];

        for (let nameValuePair of nameValuePairs) {
            let item = this.createCollectionItem(
                nameValuePair.name,
                nameValuePair.value
            );

            context.target[this.collectionPropertyName].push(item);
        }

        context.peer.changed(refreshPropertySheet);
    }

    render(context: PropertySheetContext): HTMLElement {
        let result: HTMLElement = document.createElement('div');

        let collection = context.target[this.collectionPropertyName];

        if (!Array.isArray(collection)) {
            throw new Error(
                'The ' +
                    this.collectionPropertyName +
                    ' property on ' +
                    context.peer.getCardObject().getJsonTypeName() +
                    " either doesn't exist or isn't an array."
            );
        }

        let nameValuePairs: INameValuePair[] = [];

        for (let pair of collection) {
            nameValuePairs.push({
                name: pair[this.namePropertyName],
                value: pair[this.valuePropertyName]
            });
        }

        if (nameValuePairs.length === 0) {
            let emptyMessage: HTMLElement = new GeneratePropertyFields().generateLabels(
                this.messageIfEmpty
            );
            emptyMessage.classList.add(
                'emptyMessageNameValuePairPropertyEditor'
            );
            result.appendChild(emptyMessage);
        } else {
            let separator: HTMLElement = document.createElement('div');
            separator.classList.add('separatorPropertyEditor');
            result.appendChild(separator);

            for (let i = 0; i < nameValuePairs.length; i++) {
                let containerTitle: HTMLElement = document.createElement('div');

                let titleDiv: HTMLElement = document.createElement('div');
                titleDiv.classList.add('titleDivNameValuePairPropertyEditor');

                let titleLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
                    this.namePropertyLabel
                );
                let tooltipTitle: HTMLElement = new GeneratePropertyFields().generateTooltips(
                    this.nameTooltipContent
                );
                let titleTooltipDiv: HTMLElement = document.createElement(
                    'div'
                );
                titleTooltipDiv.appendChild(titleLabel);
                titleTooltipDiv.appendChild(tooltipTitle);
                titleTooltipDiv.classList.add(
                    'labelNameValuePairPropertyEditor'
                );

                let removeLabel: HTMLElement = document.createElement('label');
                let removeButton: HTMLElement = document.createElement(
                    'button'
                );

                removeButton.setAttribute('aria-label', 'Remove');
                removeLabel.classList.add(
                    'iconRemoveNameValuePairPropertyEditor'
                );
                removeButton.onclick = sender => {
                    nameValuePairs.splice(i, 1);

                    this.collectionChanged(context, nameValuePairs, true);
                };
                let removeSpan: HTMLElement = document.createElement('span');
                removeSpan.classList.add('acd-icon');
                removeSpan.classList.add('garbageIconPropertyEditor');
                removeLabel.appendChild(removeButton);
                removeLabel.appendChild(removeSpan);

                titleDiv.appendChild(titleTooltipDiv);
                titleDiv.appendChild(removeLabel);

                const eventCallbackname = value => {
                    nameValuePairs[i].name = value;
                    this.collectionChanged(context, nameValuePairs, false);
                };
                let title: HTMLElement = new GeneratePropertyFields().generateTextFields(
                    false,
                    this.namePropertyPlaceHolder,
                    nameValuePairs[i].name,
                    eventCallbackname
                );

                containerTitle.classList.add('property');
                containerTitle.appendChild(titleDiv);
                containerTitle.appendChild(title);

                let containerValue: HTMLElement = document.createElement('div');

                let valueLabel: HTMLElement = new GeneratePropertyFields().generateLabels(
                    this.valuePropertyLabel
                );
                let tooltipValue: HTMLElement = new GeneratePropertyFields().generateTooltips(
                    this.valueTooltipContent
                );
                let valueTooltipDiv: HTMLElement = document.createElement(
                    'div'
                );
                valueTooltipDiv.appendChild(valueLabel);
                valueTooltipDiv.appendChild(tooltipValue);
                valueTooltipDiv.classList.add(
                    'labelNameValuePairPropertyEditor'
                );

                const eventCallbackvalue = value => {
                    nameValuePairs[i].value = value;
                    this.collectionChanged(context, nameValuePairs, false);
                };
                let value: HTMLElement = new GeneratePropertyFields().generateTextFields(
                    true,
                    this.valuePropertyPlaceHolder,
                    nameValuePairs[i].value,
                    eventCallbackvalue
                );

                containerValue.classList.add('property');
                containerValue.appendChild(valueTooltipDiv);
                containerValue.appendChild(value);

                let separator: HTMLElement = document.createElement('div');
                separator.classList.add('separatorPropertyEditor');

                let container: HTMLElement = document.createElement('div');
                container.appendChild(containerTitle);
                container.appendChild(containerValue);
                container.appendChild(separator);

                result.appendChild(container);
            }
        }
        const onAddNewpair = () => {
            let newPair = this.newPair(nameValuePairs);
            let item = this.createCollectionItem(newPair.name, newPair.value);
            nameValuePairs.push({ name: item.name, value: item.value });
            context.target[this.collectionPropertyName].push(item);
            context.peer.changed(true);
        };
        let addButton: HTMLElement = new GeneratePropertyFields().generateButton(
            this.addButtonTitle,
            onAddNewpair
        );

        addButton.setAttribute('aria-label', this.addButtonTitle);
        result.appendChild(addButton);
        return result;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly collectionPropertyName: string,
        readonly namePropertyName: string,
        readonly valuePropertyName: string,
        readonly createCollectionItem: (name: string, value: string) => any,
        readonly newPair: (
            nameValuePair: Array<{ name: string; value: string }>
        ) => { name: string; value: string },
        readonly nameTooltipContent: string,
        readonly valueTooltipContent: string,
        readonly namePropertyLabel: string = 'Title',
        readonly valuePropertyLabel: string = 'Value',
        readonly namePropertyPlaceHolder: string = 'Title',
        readonly valuePropertyPlaceHolder: string = 'Value',
        readonly addButtonTitle: string = 'Add',
        readonly messageIfEmpty: string = 'This collection is empty'
    ) {
        super(targetVersion);
    }
}
