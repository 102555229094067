import BaseTreeItem from '../BaseTreeItem';
import DesignerPeer from './DesignerPeer';

export default class DesignerPeerTreeItem extends BaseTreeItem {
    private computeLevel() {
        this._level = 0;

        let peer = this.owner;

        while (peer) {
            this._level++;

            peer = peer.parent;
        }

        for (let i = 0; i < this.getChildCount(); i++) {
            (this.getChildAt(i) as DesignerPeerTreeItem).computeLevel();
        }
    }

    private getLastDeepChild(
        parent: DesignerPeerTreeItem,
    ): DesignerPeerTreeItem {
        if (!parent.isExpanded || parent.getChildCount() === 0) {
            return parent;
        }

        return this.getLastDeepChild(
            parent.getChildAt(
                parent.getChildCount() - 1,
            ) as DesignerPeerTreeItem,
        );
    }

    private getFirstDeepParent(
        parent: DesignerPeerTreeItem,
        child: DesignerPeerTreeItem,
    ): DesignerPeerTreeItem | undefined {
        if (!parent || !child) {
            return undefined;
        }
        let index = parent.owner.getChildIndex(child.owner);
        if (index !== parent.getChildCount() - 1) {
            return parent.getChildAt(index + 1);
        } else {
            return this.getFirstDeepParent(
                parent.owner.parent?.treeItem,
                parent,
            );
        }
    }

    protected getIconClass(): string {
        return this.owner.registration.iconClass;
    }

    protected getLabelText(): string {
        return this.owner.getCardObject().getJsonTypeName();
    }

    protected getAdditionalText(): string {
        return this.owner.getTreeItemText();
    }
    protected selected() {
        this.owner.isSelected = true;
        this.textElement.focus();
    }

    readonly owner: DesignerPeer;

    constructor(owner: DesignerPeer) {
        super();

        this.owner = owner;
        this.owner.onParentChanged = (sender: DesignerPeer) => {
            this.computeLevel();
        };

        this.computeLevel();
    }

    isDraggable(): boolean {
        return true;
    }

    getChildCount(): number {
        return this.owner.getChildCount();
    }

    getChildAt(index: number): DesignerPeerTreeItem {
        return this.owner.getChildAt(index).treeItem;
    }

    selectPreviousItem = (e: KeyboardEvent) => {
        if (!this.isSelected) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        let parent = this.owner.parent;
        if (!parent) {
            return;
        }

        let index = parent.getChildIndex(this.owner);
        if (index === 0) {
            parent.treeItem.selected();
        } else {
            const previousPeer = parent.getChildAt(index - 1);
            const lastDeepChild = this.getLastDeepChild(previousPeer.treeItem);
            lastDeepChild.selected();
        }
    };

    selectNextItem = (e: KeyboardEvent) => {
        if (!this.isSelected) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (this.isExpanded && this.owner.getChildCount() !== 0) {
            this.getChildAt(0).selected();
        } else {
            let parent = this.owner.parent;
            if (!parent) {
                return;
            }

            const firstDeepParent = this.getFirstDeepParent(
                parent.treeItem,
                this,
            );
            if (firstDeepParent) {
                firstDeepParent.selected();
            }
        }
    };

    updateLayout() {
        super.updateLayout();

        this.isSelected = this.owner.isSelected;
    }

    handleKeyEvent(event: KeyboardEvent): void {}
}
