import InputPeer from './InputPeer';
import CustomTextInput from '../../components/ActionableMessages/CustomTextInput';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import BooleanPropertyEditor from '../PropertyEditor/BooleanPropertyEditor';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import CardDesignerSurface from '../CardDesignerSurface';
import DesignerPeer from './DesignerPeer';
import SubPropertySheetEntry from '../PropertyEditor/SubPropertySheetEntry';
import CardElementPeer from './CardElementPeer';

export default class TextInputPeer extends InputPeer<CustomTextInput> {
    static readonly placeholderProperty = new StringPropertyEditor(
        Versions.v1_0,
        'placeholder',
        'Hint Text',
        'Insert hint text that will be displayed as a nudge till the user enters any text. For example "Give a reason" or "enter your email address".'
    );
    static readonly isMultilineProperty = new BooleanPropertyEditor(
        Versions.v1_0,
        'isMultiline',
        'Allow Multiple Lines',
        'Turning this property on will allow the user to enter multiple lines of text in the text box. ',
        true
    );

    static readonly restrictTextInput = new BooleanPropertyEditor(
        '*',
        'restrictTextInput',
        'Restrict Text Input',
        'Turning this property on will restrict the number of character the user can enter in this input field.',
        true
    );

    static readonly maxLength = new StringPropertyEditor(
        Versions.v1_0,
        'maxLength',
        'Maximum length',
        'Defines the maximum number of characters the recipient can enter. For example 150.'
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            defaultCategory,
            InputPeer.idProperty,
            InputPeer.isVisibleProperty,
            TextInputPeer.placeholderProperty,
            InputPeer.defaultValueProperty,
            InputPeer.isRequiredProperty,
            TextInputPeer.isMultilineProperty,
            TextInputPeer.restrictTextInput
        );

        if (this.cardElement.restrictTextInput) {
            if (this.cardElement.maxLength === 0)
                this.cardElement.maxLength = 150;
            propertySheet.add(defaultCategory, TextInputPeer.maxLength);
        } else {
            this.cardElement.maxLength = 0;
        }

        if (this.cardElement.inlineAction) {
            let inlineActionPeer = CardDesignerSurface.actionPeerRegistry.createPeerInstance(
                this.designerSurface,
                null,
                this.cardElement.inlineAction
            );
            inlineActionPeer.onChanged = (
                sender: DesignerPeer,
                updatePropertySheet: boolean
            ) => {
                this.changed(updatePropertySheet);
            };

            let subPropertySheet = new PropertySheet();
            inlineActionPeer.populatePropertySheet(
                subPropertySheet,
                PropertySheetCategory.InlineAction
            );

            propertySheet.add(
                PropertySheetCategory.InlineAction,
                new SubPropertySheetEntry(
                    Versions.v1_2,
                    this.cardElement.inlineAction,
                    subPropertySheet
                )
            );
        }

        propertySheet.add(
            defaultCategory,
            CardElementPeer.spacingProperty,
            CardElementPeer.separatorProperty
        );
    }

    initializeCardElement() {
        super.initializeCardElement();

        this.cardElement.placeholder = 'Enter text here';
    }
}
