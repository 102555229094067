import PropertySheetEntry from './PropertySheetEntry';
import { TargetVersion } from '../Shared';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';

export class DatePropertyEditor extends PropertySheetEntry {
    private createInput(defaultValue: string, eventCallback): HTMLElement {
        let input: HTMLElement;
        input = new GeneratePropertyFields().generateDatePicker(
            defaultValue,
            eventCallback
        );

        input.setAttribute('aria-label', this.label);
        return input;
    }

    protected getPropertyValue(context: PropertySheetContext): any {
        return context.target[this.propertyName];
    }

    private setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = value;
    }

    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');
        let labelDiv: HTMLElement = document.createElement('div');
        labelDiv.style.display = 'flex';
        let label: HTMLElement = new GeneratePropertyFields().generateLabels(
            this.label
        );
        let tooltipElement: HTMLElement = new GeneratePropertyFields().generateTooltips(
            this.tooltipContent
        );
        tooltipElement.style.cssFloat = 'right';

        const eventCallback = (value: string) => {
            this.setPropertyValue(context, value);
            context.peer.changed(this.causesPropertySheetRefresh);
        };
        let defaultValue: string = context.target[this.propertyName];
        let input = this.createInput(defaultValue, eventCallback);
        label.style.width = '100%';
        labelDiv.appendChild(label);
        labelDiv.appendChild(tooltipElement);
        container.classList.add('property');
        container.appendChild(labelDiv);
        container.appendChild(input);
        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(targetVersion);
    }
}
