import { action, observable } from 'mobx';
import { MessageBarType } from '@fluentui/react';

export interface Message {
    message: string;
    messageType?: MessageBarType;
}

export class NotificationStore {
    private static storeInstance: NotificationStore;

    private timer: number;
    @observable public userMessage: Message;
    @observable public shouldShowMessageBar = false;

    private constructor() {}

    public static getStoreInstance(): NotificationStore {
        if (!NotificationStore.storeInstance) {
            NotificationStore.storeInstance = new NotificationStore();
        }
        return NotificationStore.storeInstance;
    }

    @action
    public showMessageBar(message: Message, autoHideTimeout: number = 3000) {
        this.userMessage = message;
        this.shouldShowMessageBar = true;
        clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            this.hideMessageBar();
        }, autoHideTimeout);
    }

    @action
    public hideMessageBar() {
        this.shouldShowMessageBar = false;
        this.userMessage = undefined;
    }
}
