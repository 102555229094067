export const AllowedChildItems: Map<string, string[]> = new Map([
    [
        'AdaptiveCard',
        [
            'ActionSet',
            'Action.OpenUrl',
            'Action.ShowCard',
            'Action.Submit',
            'Action.ToggleVisibility',
            'ColumnSet',
            'Column',
            'Container',
            'FactSet',
            'Image',
            'ImageSet',
            'Input.ChoiceSet',
            'Input.Date',
            'Input.Number',
            'Input.Text',
            'Input.Time',
            'Input.Toggle',
            'Media',
            'RichTextBlock',
            'TextBlock'
        ]
    ],
    ['TextBlock', []],
    ['Image', []],
    ['Media', []],
    ['RichTextBlock', ['TextRun']],
    ['TextRun', []],
    [
        'ActionSet',
        [
            'Action.OpenUrl',
            'Action.ShowCard',
            'Action.Submit',
            'Action.ToggleVisibility',
            'ActionSet'
        ]
    ],
    [
        'Container',
        [
            'ActionSet',
            'Action.OpenUrl',
            'Action.ShowCard',
            'Action.Submit',
            'Action.ToggleVisibility',
            'ColumnSet',
            'Column',
            'Container',
            'FactSet',
            'Image',
            'ImageSet',
            'Input.ChoiceSet',
            'Input.Date',
            'Input.Number',
            'Input.Text',
            'Input.Time',
            'Input.Toggle',
            'Media',
            'RichTextBlock',
            'TextBlock'
        ]
    ],
    ['ColumnSet', ['Column', 'ColumnSet']],
    [
        'Column',
        [
            'ActionSet',
            'Action.OpenUrl',
            'Action.ShowCard',
            'Action.Submit',
            'Action.ToggleVisibility',
            'ColumnSet',
            'Column',
            'Container',
            'FactSet',
            'Image',
            'ImageSet',
            'Input.ChoiceSet',
            'Input.Date',
            'Input.Number',
            'Input.Text',
            'Input.Time',
            'Input.Toggle',
            'Media',
            'RichTextBlock',
            'TextBlock'
        ]
    ],
    ['FactSet', []],
    ['ImageSet', ['Image']],
    ['Action.OpenUrl', []],
    ['Action.DisplayMessageForm', []],
    ['Action.DisplayAppointmentForm', []],
    ['Action.Transaction', []],
    ['Action.Http', []],
    ['Action.Submit', []],
    ['Action.ShowCard', ['AdaptiveCard']],
    ['Action.ToggleVisibility', []],
    ['Input.Text', []],
    ['Input.Number', []],
    ['Input.Date', []],
    ['Input.Time', []],
    ['Input.Toggle', []],
    ['Input.ChoiceSet', ['Input.Choice']]
]);

export const MaxActions = 5;
