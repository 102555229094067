import ChoicePropertyEditor from './ChoicePropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import { TargetVersion, Versions } from '../Shared';

export default class ContainerStylePropertyEditor extends ChoicePropertyEditor {
    protected getPropertyValue(context: PropertySheetContext): any {
        let currentStyle = context.target[this.propertyName];

        return currentStyle ? currentStyle.toString() : 'default';
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = value;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly label: string,
        readonly tooltipContent: string
    ) {
        super(
            targetVersion,
            propertyName,
            label,
            [
                {
                    targetVersion: Versions.v1_0,
                    name: 'Default',
                    value: 'default'
                },
                {
                    targetVersion: Versions.v1_0,
                    name: 'Emphasis',
                    value: 'emphasis'
                }
            ],
            tooltipContent
        );
    }
}
