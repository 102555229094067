import React from 'react';
import { DefaultButton, IContextualMenuProps } from '@fluentui/react';
import { addChildCommand } from '../../ACDesigner/DesignerPeers/DesignerPeersUtils';

interface ButtonProps {
    defaultValue: string;
    onClickCallback(): void;
}

export class CustomButton extends React.Component<ButtonProps, {}> {
    public render() {
        return (
            <DefaultButton
                text={this.props.defaultValue}
                onClick={this.onClick}
                styles={{ root: { marginLeft: 12, marginTop: 16 } }}
            />
        );
    }
    private onClick = (): void => {
        this.props.onClickCallback();
    };
}

interface ContextualMenuProps {
    addChildCommand: addChildCommand;
}

export class ContextualMenuButton extends React.Component<
    ContextualMenuProps,
    {}
> {
    private menuProps: IContextualMenuProps = {
        items: [],
    };

    private addMenuProps() {
        for (
            let i = 0;
            i < this.props.addChildCommand.subCommands.length;
            i++
        ) {
            this.menuProps.items.push({
                key: this.props.addChildCommand.subCommands[i].key,
                text: this.props.addChildCommand.subCommands[i].value,
                onClick: this.props.addChildCommand.subCommands[i].execute,
            });
        }
    }

    public render() {
        this.addMenuProps();
        return (
            <DefaultButton
                text={this.props.addChildCommand.name}
                menuProps={this.menuProps}
                styles={{ root: { marginTop: 16, marginLeft: 12 } }}
            />
        );
    }
}
