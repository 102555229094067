import {
    Action,
    setProperty,
    IValidationError,
    ActionStyle
} from 'adaptivecards';

const toJSON = () => {
    const prototypeToJSON = Action.prototype.toJSON;
    Action.prototype.toJSON = function() {
        const result = prototypeToJSON.call(this);
        if (this.isPrimary !== undefined) {
            setProperty(result, 'isPrimary', this.isPrimary, false);
            setProperty(
                result,
                'style',
                this.isPrimary ? ActionStyle.Positive : ActionStyle.Default,
                ActionStyle.Default
            );
        } else if (this.style !== undefined) {
            setProperty(
                result,
                'isPrimary',
                this.style === ActionStyle.Positive,
                false
            );
            setProperty(result, 'style', this.style, ActionStyle.Default);
        }
        return result;
    };
};

const parse = () => {
    const prototypeParse = Action.prototype.parse;
    Action.prototype.parse = function(
        json: any,
        errors?: Array<IValidationError>
    ) {
        prototypeParse.call(this, json, errors);
        if (json.isPrimary !== undefined) {
            this.isPrimary = json.isPrimary;
            this.style = json.isPrimary
                ? ActionStyle.Positive
                : ActionStyle.Default;
        } else {
            this.isPrimary = json.style === ActionStyle.Positive;
            this.style = json.style;
        }
    };
};

export const initialize = () => {
    toJSON();
    parse();
};
