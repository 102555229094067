import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import {
    Dialog,
    DialogFooter,
    DialogType,
    DefaultButton,
    PrimaryButton,
} from '@fluentui/react';

import { CardSamples } from '../../samples/Samples';
import { AppStore, VIEW } from '../../stores/AppStore';

import './LoadSample.css';
import { AWTLogManager, AWTEventPriority } from '@aria/webjs-sdk';
import { CARD_LOADED } from '../../utilities/LoggingEventNames';
import { keyBoardToClickEventMapper } from '../../utilities/Utils';
import { AppStoreProps } from '../../stores/StoreProps';

const LoadSample = () => {
    return (
        <div className="loadSample" role="main">
            <LoadSampleHeader appStore={AppStore.getStoreInstance()} />
            <LoadSampleBody appStore={AppStore.getStoreInstance()} />
        </div>
    );
};

export default LoadSample;

@observer
class LoadSampleHeader extends React.Component<AppStoreProps> {
    @action
    private loadDesignView = () => {
        this.props.appStore.currentView = VIEW.DESIGN;
    };

    render() {
        let backButton = null;
        let dummyButton = null;
        let backButtonXS = null;
        let dummyButtonXS = null;
        if (this.props.appStore.previousView === VIEW.DESIGN) {
            backButton = (
                <div
                    className="loadSampleButton backToCard"
                    onClick={this.loadDesignView}
                    onKeyDown={keyBoardToClickEventMapper(this.loadDesignView)}
                    role="button"
                    tabIndex={0}
                >
                    <span className="backIcon amd-icon amd-blue-icon amd-icon-chevronLeft"></span>
                    <span className="backText">Back to your card</span>
                </div>
            );

            backButtonXS = (
                <div
                    className="loadSampleButtonXS backToCardXS"
                    onClick={this.loadDesignView}
                    onKeyDown={keyBoardToClickEventMapper(this.loadDesignView)}
                    role="button"
                    tabIndex={0}
                >
                    <span className="backIcon_sm amd-icon amd-blue-icon amd-icon-chevronLeft"></span>
                    <span className="backText_sm">Back to your card</span>
                </div>
            );
        }
        return (
            <div className="loadSampleHeader">
                {backButton}
                {backButtonXS}
                <div className="loadSampleHeaderText">
                    Choose a sample card closest to your scenario to customize
                    it or Design a card from scratch.
                </div>
                {dummyButton}
                {dummyButtonXS}
            </div>
        );
    }
}

type LoadSampleBodyState = {
    selectedCard: string;
    blockLoadingDialogVisibility: boolean;
};

@observer
class LoadSampleBody extends React.Component<
    AppStoreProps,
    LoadSampleBodyState
> {
    private cardList = [
        'blankCard',
        'expenseApproval',
        'multipleRequestApproval',
        'vacationApproval',
        'workflowNotification',
        'poll',
        'notification',
        'subjectiveSurvey',
    ];

    private blockOrLoadCard = (event) => {
        const selectedCard = event.currentTarget.id;
        if (
            this.props.appStore.previousView === VIEW.DESIGN &&
            this.props.appStore.designer?.canUndo
        ) {
            this.setState({
                selectedCard,
                blockLoadingDialogVisibility: true,
            });
        } else {
            this.setState(
                {
                    selectedCard,
                },
                this.loadCard,
            );
        }
    };

    private dismissBlockLoadingDialog = () => {
        this.setState({
            blockLoadingDialogVisibility: false,
        });
    };

    @action
    private loadCard = () => {
        AWTLogManager.getLogger().logEvent({
            name: CARD_LOADED,
            priority: AWTEventPriority.Normal,
            properties: {
                card_name: this.state.selectedCard,
            },
        });
        this.props.appStore.currentCard = this.state.selectedCard;
        this.props.appStore.loadCard = true;
        this.props.appStore.currentView = VIEW.DESIGN;
    };

    @action
    private loadACv2DesignView = () => {
        this.props.appStore.currentCard = 'blankCardACv2';
        this.props.appStore.loadCard = true;
        this.props.appStore.currentView = VIEW.ACv2_DESIGN;
    };

    private getCardPreview(card: string) {
        const cardDetails = CardSamples[card];
        const isBlankCard = card === 'blankCard';
        return (
            <div
                id={card}
                key={card}
                className="cardPreview"
                onClick={this.blockOrLoadCard}
                onKeyDown={keyBoardToClickEventMapper(this.blockOrLoadCard)}
                role="button"
                tabIndex={0}
            >
                <div className="cardName">
                    <span>{cardDetails.displayName}</span>
                </div>
                {isBlankCard ? (
                    <div className="blankCardImage">
                        <div>Drag and Drop elements</div>
                        <div>to design your card</div>
                    </div>
                ) : (
                    <img
                        className="cardImage"
                        src={cardDetails.previewImagePath}
                        alt=""
                    />
                )}
            </div>
        );
    }

    private getCardsPreviewForRow(cards: string[]) {
        return (
            <div className="cardListRow">
                {cards.map((card) => this.getCardPreview(card))}
            </div>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedCard: null,
            blockLoadingDialogVisibility: false,
        };
    }

    public render() {
        return (
            <div className="cardListWrapper" id="cardListWrapper">
                <div className="cardSendLinkACv2">
                    <span>
                        <span
                            onClick={this.loadACv2DesignView}
                            onKeyDown={keyBoardToClickEventMapper(
                                this.loadACv2DesignView,
                            )}
                            role="button"
                            className="loadACv2DesignViewText"
                            tabIndex={0}
                        >
                            Click here
                        </span>
                        <span> to send an AC v1.4+ card to yourself</span>
                    </span>
                </div>
                <div className="cardListContent">
                    {this.getCardsPreviewForRow(this.cardList)}
                </div>
                <Dialog
                    hidden={!this.state.blockLoadingDialogVisibility}
                    onDismiss={this.dismissBlockLoadingDialog}
                    dialogContentProps={{
                        type: DialogType.close,
                        styles: {
                            subText: {
                                fontSize: '15px',
                                paddingTop: 0,
                            },
                        },
                        title: 'Discard progress',
                        subText:
                            'By loading the selected sample card you will lose all the progress you have made in the card you were working on. Are you sure you want to load this sample card?',
                    }}
                    modalProps={{
                        isBlocking: true,
                        isDarkOverlay: true,
                    }}
                    minWidth="416px"
                    maxWidth="416px"
                >
                    <DialogFooter>
                        <DefaultButton onClick={this.loadCard} text="OK" />
                        <PrimaryButton
                            onClick={this.dismissBlockLoadingDialog}
                            text="Cancel"
                        />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}
