import PropertySheetEntry from './PropertySheetEntry';
import { TargetVersion } from '../Shared';
import GeneratePropertyFields from './GeneratePropertyFields';

export default class DescriptionPropertyEditor extends PropertySheetEntry {
    render(): HTMLElement {
        let container: HTMLElement = document.createElement('div');

        let content: HTMLElement = new GeneratePropertyFields().generateLabels(
            this.text
        );
        content.classList.add('property');

        let separator: HTMLElement = document.createElement('div');
        separator.classList.add('separatorPropertyEditor');

        container.appendChild(content);
        container.appendChild(separator);
        return container;
    }

    constructor(readonly targetVersion: TargetVersion, readonly text: string) {
        super(targetVersion);
    }
}
