import TypedActionPeer from './TypedActionPeer';
import { OpenUrlAction } from 'adaptivecards';
import StringPropertyEditor from '../PropertyEditor/StringPropertyEditor';
import { Versions } from '../Shared';
import PropertySheet from '../PropertyEditor/PropertySheet';
import PropertySheetCategory from '../PropertyEditor/PropertySheetCategory';
import DescriptionPropertyEditor from '../PropertyEditor/DescriptionPropertyEditor';

export default class OpenUrlActionPeer extends TypedActionPeer<OpenUrlAction> {
    static readonly description: string =
        'This Action button  opens a webpage in a browser. This is typically used when users need more context/information for a given scenario and open the portal/webpage for the same. For example "View in portal"';
    static readonly urlProperty = new StringPropertyEditor(
        Versions.v1_0,
        'url',
        'Url',
        'Insert the URL of the webpage this button should open when clicked.'
    );

    static readonly descriptionProperty = new DescriptionPropertyEditor(
        '*',
        OpenUrlActionPeer.description
    );

    populatePropertySheet(
        propertySheet: PropertySheet,
        defaultCategory: string = PropertySheetCategory.DefaultCategory
    ) {
        propertySheet.add(
            PropertySheetCategory.DefaultCategory,
            OpenUrlActionPeer.descriptionProperty
        );
        super.populatePropertySheet(propertySheet, defaultCategory);

        propertySheet.add(defaultCategory, OpenUrlActionPeer.urlProperty);
    }
}
