import BaseInventoryItem from '../ACDesigner/BaseInventoryItem';
import {
    ITypeRegistration,
    CardElement,
    Column,
    ColumnSet
} from 'adaptivecards';
import CardDesignerSurface from '../ACDesigner/CardDesignerSurface';
import CardElementPeer from '../ACDesigner/DesignerPeers/CardElementPeer';

import { findElementTypeRegistration } from './InventoryUtil';
import { generateUUID } from '../utilities/Utils';

export default class ColumnInventoryItem extends BaseInventoryItem {
    private parentTypeName: string = 'ColumnSet';

    private parentTypeRegistration: ITypeRegistration<CardElement>;

    readonly typeName: string;

    readonly typeRegistration: ITypeRegistration<CardElement>;

    constructor(element: HTMLElement, typeName: string) {
        super(element);
        this.typeName = typeName;
        this.typeRegistration = findElementTypeRegistration(this.typeName);
        this.parentTypeRegistration = findElementTypeRegistration(
            this.parentTypeName
        );
    }

    createPeer(designer: CardDesignerSurface): CardElementPeer {
        const column: Column = this.typeRegistration.createInstance() as Column;
        column.id = generateUUID();

        const columnSet: ColumnSet = this.parentTypeRegistration.createInstance() as ColumnSet;
        columnSet.id = generateUUID();
        columnSet.addColumn(column);

        const peer = CardDesignerSurface.cardElementPeerRegistry.createPeerInstance(
            designer,
            null,
            columnSet
        );
        peer.initializeCardElement();

        return peer;
    }
}
