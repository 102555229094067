import PropertySheetContext from './PropertySheetContext';
import PropertySheetEntry from './PropertySheetEntry';
import { TargetVersion } from '../Shared';
import GeneratePropertyFields from './GeneratePropertyFields';
import { keyValuePair } from '../DesignerPeers/DesignerPeersUtils';
import { getBoolValue } from 'adaptivecards';

export default class ArrayPropertyEditor extends PropertySheetEntry {
    render(context: PropertySheetContext): HTMLElement {
        let entries: keyValuePair = {
            'Toggle Visibility': 'undefined',
            Show: 'true',
            Hide: 'false',
        };

        let result: HTMLElement = document.createElement('div');

        let collection: object = context.target[this.propertyName];

        let targetIds: Array<string> = [];
        let isVisible: Array<string> = [];

        if (Object.keys(collection).length === 0) {
            let emptyMessage: HTMLElement =
                new GeneratePropertyFields().generateLabels(
                    'The target elements set is empty.',
                );
            emptyMessage.classList.add(
                'emptyMessageNameValuePairPropertyEditor',
            );
            result.appendChild(emptyMessage);
        } else {
            let separator: HTMLElement = document.createElement('div');
            separator.classList.add('separatorPropertyEditor');
            result.appendChild(separator);

            targetIds = Object.keys(collection);
            isVisible = Object.values(collection);

            for (let i = 0; i < targetIds.length; i++) {
                let containerTitle: HTMLElement = document.createElement('div');
                let titleDiv: HTMLElement = document.createElement('div');
                titleDiv.classList.add('titleDivNameValuePairPropertyEditor');

                let titleLabel: HTMLElement =
                    new GeneratePropertyFields().generateLabels('Target Id');
                let tooltipTitle: HTMLElement =
                    new GeneratePropertyFields().generateTooltips(
                        this.tooltipContentTargetID,
                    );
                let titleTooltipDiv: HTMLElement =
                    document.createElement('div');
                titleTooltipDiv.appendChild(titleLabel);
                titleTooltipDiv.appendChild(tooltipTitle);
                titleTooltipDiv.classList.add(
                    'labelNameValuePairPropertyEditor',
                );

                let removeLabel: HTMLElement = document.createElement('label');
                let removeButton: HTMLElement =
                    document.createElement('button');

                removeButton.setAttribute('aria-label', 'Remove');
                removeLabel.classList.add(
                    'iconRemoveNameValuePairPropertyEditor',
                );

                let removeSpan: HTMLElement = document.createElement('span');
                removeSpan.classList.add('acd-icon');
                removeSpan.classList.add('garbageIconPropertyEditor');
                removeLabel.appendChild(removeButton);
                removeLabel.appendChild(removeSpan);
                // eslint-disable-next-line no-loop-func
                removeButton.onclick = (sender) => {
                    delete collection[targetIds[i]];
                    targetIds = targetIds.splice(i, 1);
                    isVisible = isVisible.splice(i, 1);
                    context.target[this.propertyName] = collection;
                    context.peer.changed(true);
                };
                titleDiv.appendChild(titleTooltipDiv);
                titleDiv.appendChild(removeLabel);
                // eslint-disable-next-line no-loop-func
                const onChangeCallback = (value) => {
                    targetIds[i] = value;
                    collection = {};
                    for (let j = 0; j < targetIds.length; j++) {
                        collection[targetIds[j]] = getBoolValue(
                            isVisible[j],
                            undefined,
                        );
                    }
                    context.target[this.propertyName] = collection;
                    context.peer.changed(false);
                };

                let title: HTMLElement =
                    new GeneratePropertyFields().generateTextFields(
                        false,
                        'Target Id',
                        targetIds[i],
                        onChangeCallback,
                    );

                containerTitle.classList.add('property');
                containerTitle.appendChild(titleDiv);
                containerTitle.appendChild(title);

                let containerValue: HTMLElement = document.createElement('div');
                let valueLabel: HTMLElement =
                    new GeneratePropertyFields().generateLabels(
                        'Behaviour on clicking',
                    );
                let tooltipValue: HTMLElement =
                    new GeneratePropertyFields().generateTooltips(
                        this.tooltipContentIsVisible,
                    );
                let valueTooltipDiv: HTMLElement =
                    document.createElement('div');
                valueTooltipDiv.appendChild(valueLabel);
                valueTooltipDiv.appendChild(tooltipValue);
                valueTooltipDiv.classList.add(
                    'labelNameValuePairPropertyEditor',
                );

                // eslint-disable-next-line no-loop-func
                const onSelectCallback = (value) => {
                    isVisible[i] = value;
                    collection = {};
                    for (let j = 0; j < targetIds.length; j++) {
                        collection[targetIds[j]] = getBoolValue(
                            isVisible[j],
                            undefined,
                        );
                    }
                    context.target[this.propertyName] = collection;
                    context.peer.changed(false);
                };

                let defaultValue: string = 'undefined';
                if (collection[targetIds[i]] !== undefined) {
                    defaultValue = collection[targetIds[i]].toString();
                }

                let value: HTMLElement =
                    new GeneratePropertyFields().generateDropDown(
                        entries,
                        onSelectCallback,
                        defaultValue,
                        'Behaviour on clicking',
                    );

                containerValue.classList.add('property');
                containerValue.appendChild(valueTooltipDiv);
                containerValue.appendChild(value);

                let separator: HTMLElement = document.createElement('div');
                separator.classList.add('separatorPropertyEditor');

                let container: HTMLElement = document.createElement('div');
                container.appendChild(containerTitle);
                container.appendChild(containerValue);
                container.appendChild(separator);

                result.appendChild(container);
            }
        }

        const onAddNewpair = () => {
            collection[''] = undefined;
            context.target[this.propertyName] = collection;
            context.peer.changed(true);
        };
        let addButton: HTMLElement =
            new GeneratePropertyFields().generateButton(
                'Add a Target',
                onAddNewpair,
            );

        addButton.setAttribute('aria-label', 'Add a target');
        result.appendChild(addButton);

        return result;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly tooltipContentTargetID: string,
        readonly tooltipContentIsVisible: string,
        readonly causesPropertySheetRefresh: boolean = false,
    ) {
        super(targetVersion);
    }
}
