import PropertySheetEntry from './PropertySheetEntry';
import { TargetVersion } from '../Shared';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';

export default class LinkPropertyEditor extends PropertySheetEntry {
    render(context: PropertySheetContext): HTMLElement {
        let container: HTMLElement = document.createElement('div');
        container.classList.add('property');

        let labelDiv: HTMLElement = document.createElement('div');
        labelDiv.style.display = 'flex';
        let label: HTMLElement = new GeneratePropertyFields().generateLabels(
            this.propertyLabel
        );
        let tooltipElement: HTMLElement = new GeneratePropertyFields().generateTooltips(
            this.tooltipContent
        );
        tooltipElement.style.cssFloat = 'right';

        label.style.width = '100%';
        labelDiv.appendChild(label);
        labelDiv.appendChild(tooltipElement);
        container.appendChild(labelDiv);

        switch (context.peer.getCardObject().getJsonTypeName()) {
            case 'ActionSet': {
                let collection = context.target[this.propertyName].items;
                if (collection) {
                    for (let i = 0; i < collection.length; i++) {
                        let action: HTMLElement = new GeneratePropertyFields().generateLinks(
                            collection[i].title
                        );
                        action.onclick = () => {
                            context.peer.designerSurface.setSelectedPeer(
                                context.peer.getChildAt(i)
                            );
                        };
                        container.appendChild(action);
                    }
                }
                break;
            }
            case 'ColumnSet': {
                let collection = context.target[this.propertyName];
                if (collection) {
                    for (let i = 0; i < collection.length; i++) {
                        let column: HTMLElement = new GeneratePropertyFields().generateLinks(
                            'Column ' + (i + 1)
                        );
                        column.onclick = () => {
                            context.peer.designerSurface.setSelectedPeer(
                                context.peer.getChildAt(i)
                            );
                        };
                        container.appendChild(column);
                    }
                }
                break;
            }
        }

        return container;
    }

    constructor(
        readonly targetVersion: TargetVersion,
        readonly propertyName: string,
        readonly propertyLabel: string,
        readonly tooltipContent: string,
        readonly causesPropertySheetRefresh: boolean = false
    ) {
        super(targetVersion);
    }
}
