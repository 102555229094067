import { TargetVersion } from '../Shared';
import CardObjectPeer from '../DesignerPeers/CardObjectPeer';

export default class PropertySheetContext {
    private _target: object = undefined;

    constructor(
        readonly targetVersion: TargetVersion,
        readonly peer: CardObjectPeer,
        target: object = undefined
    ) {
        this._target = target;
    }

    get target(): object {
        return this._target !== undefined
            ? this._target
            : this.peer.getCardObject();
    }
}
