import React from 'react';
import { ContextualMenu } from '@fluentui/react';

export enum ContextualMenuOperations {
    'Cut' = 'Cut',
    'Copy' = 'Copy',
    'Paste' = 'Paste',
    'Delete' = 'Delete',
}

export interface ContextualMenuState {
    showContextualMenu?: boolean;
}

export interface ContextualMenuProps {
    onClickCallback(value: string): void;
    mouseEvent: MouseEvent;
    pasteDisabled: boolean;
}

export class ContextMenu extends React.Component<
    ContextualMenuProps,
    ContextualMenuState
> {
    constructor(props) {
        super(props);
        this.state = { showContextualMenu: true };
    }

    private closeCallout = () => {
        this.setState({
            showContextualMenu: false,
        });
    };

    public render() {
        return (
            <ContextualMenu
                items={[
                    {
                        key: ContextualMenuOperations.Cut,
                        text: ContextualMenuOperations.Cut,
                        secondaryText: 'Ctrl+X',
                        iconProps: {
                            iconName: 'Cut',
                            styles: {
                                root: {
                                    color: '#212121',
                                },
                            },
                        },
                        onClick: () => {
                            this.props.onClickCallback('Cut');
                        },
                    },
                    {
                        key: ContextualMenuOperations.Copy,
                        text: ContextualMenuOperations.Copy,
                        secondaryText: 'Ctrl+C',
                        iconProps: {
                            iconName: 'Copy',
                            styles: {
                                root: {
                                    color: '#212121',
                                },
                            },
                        },
                        onClick: () => {
                            this.props.onClickCallback('Copy');
                        },
                    },
                    {
                        key: ContextualMenuOperations.Paste,
                        text: ContextualMenuOperations.Paste,
                        secondaryText: 'Ctrl+V',
                        iconProps: {
                            iconName: 'Paste',
                            styles: {
                                root: {
                                    color: '#212121',
                                },
                            },
                        },
                        disabled: this.props.pasteDisabled,
                        onClick: () => {
                            this.props.onClickCallback('Paste');
                        },
                    },
                    {
                        key: ContextualMenuOperations.Delete,
                        text: ContextualMenuOperations.Delete,
                        secondaryText: 'Delete',
                        iconProps: {
                            iconName: 'Delete',
                            styles: {
                                root: {
                                    color: '#212121',
                                },
                            },
                        },
                        onClick: () => {
                            this.props.onClickCallback('Delete');
                        },
                    },
                ]}
                hidden={!this.state.showContextualMenu}
                onItemClick={this.closeCallout}
                onDismiss={this.closeCallout}
                target={this.props.mouseEvent}
            />
        );
    }
}
