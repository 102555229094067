import BooleanPropertyEditor from './BooleanPropertyEditor';
import PropertySheetContext from './PropertySheetContext';

export default class BooleanNumberPropertyEditor extends BooleanPropertyEditor {
    protected getPropertyValue(context: PropertySheetContext): any {
        let v = context.target[this.propertyName];
        return v === 1 ? 'true' : 'false';
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = value === 'true' ? 1 : 0;
    }
}
