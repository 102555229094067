import CardElementPeerInplaceEditor from './CardElementPeerInplaceEditor';
import { TextBlock } from 'adaptivecards';

export default class TextBlockPeerInplaceEditor extends CardElementPeerInplaceEditor<
    TextBlock
> {
    private _renderedElement: HTMLTextAreaElement;

    private close(applyChanges: boolean) {
        if (this.onClose) {
            this.onClose(applyChanges);
        }
    }

    initialize() {
        this._renderedElement.select();
    }

    applyChanges() {
        this.cardElement.text = this._renderedElement.value;
    }

    render() {
        this._renderedElement = document.createElement('textarea');
        this._renderedElement.className = 'acd-textBlock-inplace-editor';
        this._renderedElement.value = this.cardElement.text;
        this._renderedElement.addEventListener(
            'keydown',
            (e: KeyboardEvent) => {
                let cancelPropagation: boolean = false;
                switch (e.key) {
                    case 'Escape':
                        this.close(false);
                        cancelPropagation = true;

                        break;
                    case 'Enter':
                        this.close(true);
                        cancelPropagation = true;

                        break;
                }
                if (cancelPropagation) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        );

        this.cardElement.applyStylesTo(this._renderedElement);

        return this._renderedElement;
    }
}
