import DesignerPeerRegistrationBase from './DesignerPeerRegistrationBase';

export default class DesignerPeerRegistration<
    TSource,
    TPeer
> extends DesignerPeerRegistrationBase {
    readonly sourceType: TSource;

    peerType: TPeer;

    constructor(
        sourceType: TSource,
        peerType: TPeer,
        category: string,
        iconClass: string = null
    ) {
        super(category, iconClass);

        this.sourceType = sourceType;
        this.peerType = peerType;
    }
}
