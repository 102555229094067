import React from 'react';
import { TooltipHost, Icon } from '@fluentui/react';
import { generateUUID } from '../../utilities/Utils';

interface TooltipProps {
    content: string;
}

export default class CustomTooltip extends React.Component<TooltipProps, {}> {
    private hostId: string = generateUUID();
    public render() {
        return (
            <TooltipHost content={this.props.content} id={this.hostId}>
                <Icon
                    tabIndex={0}
                    iconName="Info"
                    role="button"
                    aria-label={this.props.content}
                    aria-roledescription="Tooltip button"
                    styles={{
                        root: {
                            padding: '0px',
                            width: '16px',
                            height: '16px',
                            margin: '0px',
                            fontSize: '16px',
                        },
                    }}
                    className={'tooltipIcon'}
                />
            </TooltipHost>
        );
    }
}
