import React from 'react';
import { MessageBar, MessageBarType, IMessageBarStyles } from '@fluentui/react';
import { inject, observer } from 'mobx-react';
import { NotificationStoreProps } from '../stores/StoreProps';

@inject('notificationStore')
@observer
export default class Notification extends React.Component<
    NotificationStoreProps,
    {}
> {
    public render() {
        const { userMessage, shouldShowMessageBar, hideMessageBar } =
            this.props.notificationStore;

        if (!shouldShowMessageBar || !userMessage) {
            return null;
        }
        const styles: IMessageBarStyles = {
            root: {
                backgroundColor: '#fff',
                position: 'absolute',
                top: ' 116px',
                right: '16px',
                zIndex: 1001,
                boxShadow:
                    '0 0.6px 9.8px 0 rgba(0, 0, 0, 0.11), 0 3.2px 14.2px 0 rgba(0, 0, 0, 0.13)',
                width: '282px',
                height: '48px',
            },
            text: {
                margin: 'auto',
                justifyContent: 'center',
            },
            innerText: {
                lineHeight: '1.43',
                fontWeight: 600,
                fontSize: '14px',
            },
            iconContainer: {
                margin: '14px',
            },
            icon: {
                fontWeight: 600,
                fontSize: '20px',
            },
            dismissSingleLine: {
                margin: '17px 14px',
            },
            dismissal: {
                margin: 0,
                padding: 0,
                width: '14px',
                height: '14px',
            },
        };
        return (
            <MessageBar
                messageBarType={userMessage.messageType || MessageBarType.info}
                isMultiline={false}
                onDismiss={hideMessageBar.bind(this.props.notificationStore)}
                dismissButtonAriaLabel="Close"
                styles={styles}
            >
                {userMessage.message}
            </MessageBar>
        );
    }
}
