import React from 'react';
import { IOverflowSetItemProps, OverflowSet } from '@fluentui/react';
import { DesignerTools } from './DesignerTools';

import './DesignerToolBar.css';
import { LoadSample, OverflowMenu, RenderItem } from './DesignerToolBarButtons';
import { DesignerToolsMobile } from './DesignerToolsMobile';
import { AppStoreProps } from '../../stores/StoreProps';

export class DesignerToolBar extends React.Component<AppStoreProps> {
    public render() {
        const items = [{ key: 'Preview' }, { key: 'Send' }];
        return (
            <div className="designerToolBar" role="main">
                <div
                    className="flexRow justifyAlignDesignerTools leftSideDesignerTools"
                    id="leftSideDesignerTools"
                >
                    <LoadSample appStore={this.props.appStore} />
                </div>
                <DesignerTools appStore={this.props.appStore} />
                <DesignerToolsMobile appStore={this.props.appStore} />
                <div className="flexRow justifyAlignDesignerActions rightSideDesignerTools">
                    <OverflowSet
                        role="menubar"
                        items={items}
                        overflowItems={items}
                        onRenderItem={(item: IOverflowSetItemProps) => (
                            <span className="userActions">
                                <RenderItem itemKey={item.key} />
                            </span>
                        )}
                        onRenderOverflowButton={(items: any[] | undefined) => (
                            <OverflowMenu items={items} />
                        )}
                    />
                </div>
            </div>
        );
    }
}
