import PropertySheetCategory from './PropertySheetCategory';
import PropertySheetEntry from './PropertySheetEntry';
import PropertySheetContext from './PropertySheetContext';

export default class PropertySheet {
    private _categories: { [key: string]: PropertySheetCategory } = {};

    constructor() {
        this._categories[
            PropertySheetCategory.DefaultCategory
        ] = new PropertySheetCategory(PropertySheetCategory.DefaultCategory);
        this._categories[
            PropertySheetCategory.LayoutCategory
        ] = new PropertySheetCategory(PropertySheetCategory.LayoutCategory);
        this._categories[
            PropertySheetCategory.StyleCategory
        ] = new PropertySheetCategory(PropertySheetCategory.StyleCategory);
    }

    add(categoryName: string, ...entries: PropertySheetEntry[]) {
        let category = this._categories[categoryName];

        if (!category) {
            category = new PropertySheetCategory(categoryName);

            this._categories[categoryName] = category;
        }

        category.add(...entries);
    }

    remove(...entries: PropertySheetEntry[]) {
        for (let categoryName of Object.keys(this._categories)) {
            this._categories[categoryName].remove(...entries);
        }
    }

    render(container: HTMLElement, context: PropertySheetContext) {
        for (let categoryName of Object.keys(this._categories)) {
            this._categories[categoryName].render(container, context);
        }
    }
}
