import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
    ActionButton,
    CommandBarButton,
    IButtonStyles,
    IContextualMenuItem,
    IContextualMenuListProps,
    IIconStyles,
} from '@fluentui/react';

import { AppStore, VIEW } from '../../stores/AppStore';
import {
    AppStoreProps,
    AuthStoreProps,
    UserStoreProps,
} from '../../stores/StoreProps';
import { UserStore } from '../../stores/UserStore';
import { AuthStore } from '../../stores/AuthStore';

const buttonStyles: IButtonStyles = {
    root: {
        padding: '14px 8px',
        height: '48px',
    },
};

const iconStyles: IIconStyles = {
    root: {
        fontSize: '21px',
    },
};

@observer
export class Send extends React.Component<UserStoreProps & AuthStoreProps> {
    private handleSendEmail = () => {
        if (this.props.authStore.isLoggedIn) {
            this.props.userStore.sendEmail();
        } else {
            this.props.authStore
                .signIn()
                .then(() => {
                    this.props.userStore.sendEmail();
                })
                .catch(() => {
                    // Do nothing
                });
        }
    };

    public render() {
        return (
            <ActionButton
                role="menuitem"
                iconProps={{
                    iconName: 'Send',
                    styles: iconStyles,
                }}
                styles={buttonStyles}
                disabled={
                    this.props.authStore.loginInProgress ||
                    this.props.userStore.isSendingEmail
                }
                onClick={this.handleSendEmail}
                id="sendButton"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Tab' && e.shiftKey) {
                        const previewButton =
                            document.getElementById('previewButton');
                        if (previewButton) {
                            previewButton.focus();
                            e.preventDefault();
                        }
                    }
                }}
            >
                Send
            </ActionButton>
        );
    }
}

@inject('appStore')
@observer
export class Preview extends React.Component<AppStoreProps> {
    public render() {
        return (
            <ActionButton
                role="menuitem"
                iconProps={{
                    iconName: 'View',
                    styles: iconStyles,
                }}
                styles={buttonStyles}
                onClick={() => {
                    this.props.appStore.currentView = VIEW.PREVIEW;
                }}
                id="previewButton"
                disabled={this.props.appStore.isACv2DesignView}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Tab' && !e.shiftKey) {
                        const sendButton =
                            document.getElementById('sendButton');
                        if (sendButton) {
                            sendButton.focus();
                            e.preventDefault();
                        }
                    }
                }}
            >
                Preview
            </ActionButton>
        );
    }
}

@inject('appStore')
@observer
export class LoadSample extends React.Component<AppStoreProps> {
    private iconStyles: IIconStyles = {
        root: {
            fontSize: '18px',
            height: '18px',
        },
    };

    public render() {
        return (
            <ActionButton
                role="menuitem"
                iconProps={{
                    iconName: 'PageEdit',
                    styles: this.iconStyles,
                }}
                onClick={action(() => {
                    this.props.appStore.currentView = VIEW.LOAD_SAMPLE;
                })}
            >
                <span className="designerLoadSampleText">
                    {this.props.appStore.isACv2DesignView
                        ? 'Back to AC v1.0'
                        : 'Load a Card Sample'}
                </span>
            </ActionButton>
        );
    }
}

export function OverflowMenu(menuProps: {
    items: IContextualMenuItem[] | undefined;
}): JSX.Element {
    const { items } = menuProps;
    return items ? (
        <CommandBarButton
            role="menuitem"
            id="userActionsOverflowMenu"
            className="userActionsOverflowMenu"
            aria-label="Action menu for Preview and Send"
            styles={buttonStyles}
            tabIndex={0}
            menuIconProps={{ iconName: 'More', styles: iconStyles }}
            menuProps={{
                items,
                onRenderMenuList: (props: IContextualMenuListProps) => {
                    return (
                        <>
                            {props.items.map((item) => (
                                <div key={item.key}>
                                    <RenderItem
                                        key={`${item.key}OverflowButton`}
                                        itemKey={item.key}
                                    />
                                </div>
                            ))}
                        </>
                    );
                },
            }}
        />
    ) : null;
}

export function RenderItem(props: { itemKey: string }) {
    switch (props.itemKey) {
        case 'Preview':
            return <Preview appStore={AppStore.getStoreInstance()} />;
        case 'Send':
            return (
                <Send
                    userStore={UserStore.getStoreInstance()}
                    authStore={AuthStore.getStoreInstance()}
                />
            );
        default:
            return null;
    }
}
