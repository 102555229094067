import SingleInputPropertyEditor from './SingleInputPropertyEditor';
import PropertySheetContext from './PropertySheetContext';
import GeneratePropertyFields from './GeneratePropertyFields';

export default class BooleanPropertyEditor extends SingleInputPropertyEditor {
    protected getPropertyValue(context: PropertySheetContext): any {
        let v = context.target[this.propertyName];
        return typeof v === 'boolean' ? v.toString() : 'false';
    }

    protected setPropertyValue(context: PropertySheetContext, value: string) {
        context.target[this.propertyName] = value === 'true' ? true : false;
    }

    protected createInput(
        context: PropertySheetContext,
        eventCallback
    ): HTMLElement {
        this.isBoolean = true;
        let input: HTMLElement;
        let defaultValue: boolean;
        defaultValue = this.getPropertyValue(context) === 'true' ? true : false;
        input = new GeneratePropertyFields().generateToggleInput(
            defaultValue,
            eventCallback
        );

        input.setAttribute('aria-label', this.label);
        return input;
    }
}
